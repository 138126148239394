import React, { useState, useEffect } from "react";
const Autocomplete = (props) => {
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [input, setInput] = useState("");

    const onChange = e => {
        const { suggestions } = props;

        const input = e.currentTarget.value;

        let newFilteredSuggestions = [];
        if (input != "") {

            newFilteredSuggestions = suggestions.filter((suggested) => { //questo è il filtro
                return Object.keys(suggested).some((prop) => { //uso le key

                    if (props.fields.indexOf(prop) != -1) { //Controllo soltanto le proprietà che voglio filtrare

                        return String(suggested[prop]).toLowerCase().indexOf(input.toLowerCase()) != -1; //ora trovo il dato con quella key
                    } else {
                        return false
                    }
                })
            })
        } else {
            newFilteredSuggestions = suggestions;
        }
        setFiltered(newFilteredSuggestions);
        setActive(0);
        setIsShow(true);
        setInput(e.currentTarget.value)
    };


    useEffect(() => {
        const fetchData = async () =>{
    
            if (props.value) {

                let el = props.suggestions.filter(el => el[props.keyField] === props.value)[0];
                if(el){
                let currentVal = props.fields.map(f => el[f])
                console.log(currentVal)
                setInput(currentVal.join(" "));
                }
            } else {
                setInput("")
            }
           await setFiltered(props.suggestions);
    } 
   
        fetchData();
    }, [props.suggestions]); //li aggiorna quando cambiano valore



    const onClick = async (e) => {
        let value = e.currentTarget.value;
        setActive(0);
        setFiltered([]);
        setIsShow(false);
        setInput(e.currentTarget.innerText)
        await props.onChange(value)
        if(props.loadData){
            props.loadData(value)
         
        }
       
    };
    const onKeyDown = e => {
        if (e.keyCode === 13) { // enter key
            setActive(0);
            setIsShow(false);
            setInput(filtered[active])
        }
        else if (e.keyCode === 38) { // up arrow
            return (active === 0) ? null : setActive(active - 1);
        }
        else if (e.keyCode === 40) { // down arrow
            return (active - 1 === filtered.length) ? null : setActive(active + 1);
        }
    };
    const renderAutocomplete = () => {

        if (isShow) {
            if (filtered.length) {
                return (
                    <ul className="autocomplete bg-white rounded-lg border border-gray-200 text-gray-900 text-sm font-medium w-full overflow-auto max-h-[300px] absolute  z-10" >
                        {filtered.map((suggestion, index) => {
                            let className = "block px-4 py-2 border-b border-gray-200 w-full hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 cursor-pointer";
                            if (index === active) {
                                className += " active";
                            }
                            return (
                                <li className={className} key={suggestion[props.keyField]} onClick={onClick} value={suggestion[props.keyField]}>
                                    {
                                        props.fields.map(f => suggestion[f].charAt(0).toUpperCase() + suggestion[f].toLowerCase().slice(1) +" ")
                                    }
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div className="no-autocomplete bg-white rounded-lg border border-gray-200 text-gray-900 text-sm font-medium w-full overflow-auto max-h-[300px] absolute  z-50">
                        <em>Nessun risultato</em>
                    </div>
                );
            }
        }
        return <></>;
    }


    window.addEventListener('click', function(e){   
        if (document.getElementById(props.id)?.contains(e.target)){
            setActive(0);
            setIsShow(true);
        } else{
            setActive(0);
            setIsShow(false);
        }
    
      });








    return (
      

            <div
           
                className="relative " // l'absolute figlio si riferisce il relative padre
             >
                {props.label}
                <label className="relative block">


                    <input
                    autoComplete="false"
                        className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md"
                        type="text"
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={input}
                        id={props.id}
                    />
                    <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                    <i class="bi bi-chevron-down"></i>
                    </span>
                </label>
                {renderAutocomplete()}
            </div>
    
    );
}
export default Autocomplete;



/*





const newFilteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
    );



*/