import React,{useEffect,useState} from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";


const selStyle="flex items-center p-2  text-base font-normal text-white bg-emerald-700 rounded-lg dark:text-white hover:bg-emerald-600 dark:hover:bg-gray-700";
const nonSelStyle="flex items-center p-2 text-base font-normal text-emerald-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"

const subSelStyle="flex items-center p-2 pl-11 text-base font-normal text-white bg-emerald-700 rounded-lg dark:text-white hover:bg-emerald-600 dark:hover:bg-gray-700";
const subNonSelStyle="flex items-center p-2 pl-11 text-base font-normal text-emerald-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"

const MainLayout = () => {
   const [cookies, setCookie] = useCookies(["user"]);
    const navigate = useNavigate();
   const [selected,setSelected] = useState("");
    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
      console.log(cookies);
      },[]);

const navigationClick = (e,page) => {
    e.preventDefault();
    navigate("/"+page);
    setSelected(page);
}


    return (<div  className="flex" >
    <div className=" pt-16 h-screen sticky top-0">
 <section className="flex items-center justify-center">
            <img src ={require("../assets/TecnogardenService.png")} className="h-2/4 w-2/4 justify-center"/>
        </section>

        <aside className="w-64 overflow-y-auto " aria-label="Sidebar" >
            <div className="py-4 px-3  rounded dark:bg-gray-800 " >
        <ul className="space-y-2">
         <li>
            <a href="#"  onClick={(e)=>navigationClick(e,"personale")} className={selected==="personale"?selStyle:nonSelStyle}>
            <i className="bi bi-people-fill"></i>
               <span className="ml-3">Personale</span>
            </a>
         </li>
         <li>
            <a href="#" onClick={(e)=>navigationClick(e,"controlloDiGestione")} className={selected==="controlloDiGestione"?selStyle:nonSelStyle}>
            <i className="bi bi-currency-exchange"></i>
               <span className="ml-3">Controllo di gestione</span>
            </a>
         </li>
         <li>
            <button type="button" onClick={() => document.getElementById("dropdown-example").classList.contains('hidden')?document.getElementById("dropdown-example").classList.remove("hidden"):document.getElementById("dropdown-example").classList.add("hidden")} className="flex items-center p-2 w-full text-base font-normal text-emerald-700 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
            <i className="bi bi-clipboard2-check-fill"></i>
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Gestione lavoro</span>
                  <svg sidebar-toggle-item className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            <ul id="dropdown-example" className="hidden py-2 space-y-2">
            <li>
            <a href="#" onClick={(e)=>navigationClick(e,"panoramica")} className={selected==="panoramica"?subSelStyle:subNonSelStyle}>
            <i className="bi bi-calendar-week"></i>
               <span className="ml-3">Panoramica</span>
            </a>
         </li>
         <a href="#" onClick={(e)=>navigationClick(e,"Commessa")} className={selected==="ODL"?subSelStyle:subNonSelStyle}>
         <i className="bi bi-ui-checks"></i>
               <span className="ml-3">Commessa</span>
            </a>
            <li>
            <a href="#" onClick={(e)=>navigationClick(e,"MacchinariGL")} className={selected==="MacchinariGL"?subSelStyle:subNonSelStyle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-car-front" viewBox="0 0 16 16">
               <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
               <path fill-rule="evenodd" d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
            </svg>
               <span className="ml-3">Macchinari</span>
            </a>
         </li>
            </ul>
         </li>
         <li>
            <a href="#" onClick={(e)=>navigationClick(e,"commerciale")} className={selected==="commerciale"?selStyle:nonSelStyle}>
            <i className="bi bi-credit-card-2-front-fill"></i>
               <span className="ml-3">Commerciale</span>
            </a>
         </li>
         <li>
            <a href="#" onClick={(e)=>navigationClick(e,"tecnico")} className={selected==="tecnico"?selStyle:nonSelStyle}>
            <i className="bi bi-wrench-adjustable"></i>
               <span className="ml-3">Tecnico</span>
            </a>
         </li>
         <li>
            <a href="#" onClick={(e)=>navigationClick(e,"anagrafiche")} className={selected==="anagrafiche"?selStyle:nonSelStyle}>
            <i className="bi bi-person-lines-fill"></i>
               <span className="ml-3">Anagrafiche</span>
            </a>
         </li>
         
           </ul> 
        </div>  
        </aside>
   
  </div>
  <div className="w-full bg-white-600 col-span-3 pt-16 px-10 h-screen overflow-y-auto "><Outlet/></div>
  <div className="flex absolute top-0 left-0 w-full bg-emerald-600  text-white fixed h-12  items-center  p-2"> bentornato/a, {cookies.user.username}.</div>
  
  </div>);

}

export default MainLayout;