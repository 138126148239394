import React, { useState, useEffect } from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";

const Trasportatori = () => {
  const [trasportatori, setTrasportatori] = useState([]);
  const [selectedTra, setSelectedTra] = useState(0);

  //chiedo a Fede cosa serve o cerco
  const [cookies, setCookie] = useCookies(["user"]);

  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "Ditta",
      headerName: "Ditta",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
    {
      field: "SedeLegale",
      headerName: "Sede Legale",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
    {
      field: "Piva",
      headerName: "P.IVA",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
    {
      field: "Cf",
      headerName: "Cf",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
    {
      field: "Trasporto",
      headerName: "AutTrasporto",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{color:"#047857"}}/>
        <GridToolbarFilterButton sx={{color:"#047857"}}/>
        <GridToolbarDensitySelector sx={{color:"#047857"}}/>
        <GridToolbarExport sx={{color:"#047857"}}/>
      </GridToolbarContainer  >
    );
  }




  const handleDeleteButtonClick = async () => {
    if (selectedTra === 0) {
      alert("Seleziona almeno un Trasportatore");
    } else {
      if (
        window.confirm(
          "Sei sicuro di voler eliminare il Trasportatore selezionato?"
        )
      ) {
        try {
          let tra = selectedTra;
          tra.Deleted = 1;
          await api.put(
            "trasportatori",
            { trasportatore: tra },
            { headers: { Authorization: `Bearer ${cookies.user.jwt}` } }
          );

          alert("Trasportatore eliminato con successo");
          window.location.reload(); //refresho
        } catch {
          alert("ERRORE DURANTE L'ELIMINAZIONE");
        }
      }
    }
  };
  const loadTrasportatori = async () => {
    await api
      .get("trasportatori", {
        headers: { Authorization: `Bearer ${cookies.user.jwt}` },
      })
      .then((res) => {
        setTrasportatori(res.data.data);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      loadTrasportatori();
    };
    fetchData();
  }, []);

  return (
    <>
      {/* Button Container */}
      {/* <div className="flex flex-wrap m-0 items-center justify-end w-full space-y-2 mb-4">
                <div className="flex flex-row w-full justify-end">
                    <button className="w-52 p-2 bg-emerald-600 text-white text-md rounded-md hover:scale-95">
                        Nuovo
                    </button>
                </div>
                <div className="flex flex-row w-52 justify-end space-x-2">
                    <button className="w-1/2 p-2 bg-emerald-600 text-white text-md rounded-md hover:scale-95">
                        Modifica
                    </button>
                    <button className="w-1/2 bg-red-600 text-white text-md rounded-md hover:scale-95">
                        Elimina
                    </button>
                </div> */}
      <div className="flex m-0 justify-end items-center w-full space-x-2 mb-4">
        <WatchTrasportatore
          myFunction={loadTrasportatori}
          cookies={cookies}
          buttonText="Nuovo"
          id="newButtonTrasportatore"
          title="Nuovo trasportatore"
          operation="new"
          classNameButton="new-btn"
        />

        <WatchTrasportatore
          myFunction={loadTrasportatori}
          cookies={cookies}
          buttonText="Modifica"
          selectedTra={selectedTra}
          id="editTrasportatore"
          title="Modifica trasportatore"
          operation="edit"
          classNameButton="new-btn"
        />

        <button className="delete-btn" onClick={handleDeleteButtonClick}>
          Elimina
        </button>
      </div>
      
      {/* Table Container */}
      <div className=" h-4/5">
        <DataGrid
        components={{
          Toolbar: CustomToolbar,
        }}
          sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
          rows={trasportatori}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row) => row.ID}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = trasportatori.filter((row) =>
              selectedIDs.has(row.ID)
            );

            setSelectedTra(selectedRowData[0]);
          }}
          onRowDoubleClick={async (params) => {
            await setSelectedTra(params.row);
            document.getElementById("editTrasportatore").click();
          }}
        />
      </div>
    </>
  );
};

export default Trasportatori;

const WatchTrasportatore = (props) => {
  const [showTra, setShowTra] = useState(false);
  const [trasportatore, setTrasportatore] = useState({
    Ditta: "",
    SedeLegale: "",
    Piva: "",
    Cf: "",
    AutTrasporto: "",
    SedeCitta: "",
    SedeProvincia: "",
    SedeCAP: "",
  });

  const handleEdit = (e) => {
    let tra = { ...trasportatore };
    tra[e.target.name] = e.target.value;
    setTrasportatore(tra);
  };

  const handleSave = async () => {
    if (props.operation === "new") {
      try {
        let responseTrasportatore = await api.post(
          "trasportatori",
          { trasportatore: trasportatore },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Trasportatore inserito con successo");
        props.myFunction();
        setShowTra(false);
      } catch {
        alert("Errore durante la creazione del nuovo trasportatore");
      }
    } else {
      try {
        let responseTrasportatore = await api.put(
          "trasportatori",
          { trasportatore: trasportatore },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Trasportatore modificato con successo");
        props.myFunction();
      } catch {
        alert("Errore durante la modifica del nuovo trasportatore");
      }
    }
  };

  const openTra = async () => {
    if (props.operation === "new") {
      setTrasportatore({
        Ditta: "",
        SedeLegale: "",
        Piva: "",
        Cf: "",
        AutTrasporto: "",
        SedeCitta: "",
        SedeProvincia: "",
        SedeCAP: "",
      });
    } else {
      setTrasportatore(props.selectedTra);
    }
    setShowTra(true);
  };

  return (
    <>
      <button
        id={props.id}
        className="new-btn"
        onClick={async () => {
          openTra();
        }}
      >
        {props.buttonText}
      </button>
      {showTra ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowTra(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="relative ">
                        <span className="text-gray-700">Azienda</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.Ditta}
                          name="Ditta"
                          onChange={handleEdit}
                        />
                      </label>

                      <label className="relative ">
                        <span className="text-gray-700">P. IVA</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.Piva}
                          name="Piva"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Codice Fiscale</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.Cf}
                          name="Cf"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Autorizzazione</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.AutTrasporto}
                          name="AutTrasporto"
                          onChange={handleEdit}
                        />
                      </label>
                    </div>
                    <div>
                      <label className="relative ">
                        <span className="text-gray-700">Via</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.SedeLegale}
                          name="SedeLegale"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Città</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.SedeCitta}
                          name="SedeCitta"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Provincia</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.SedeProvincia}
                          name="SedeProvincia"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">CAP</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={trasportatore.SedeCAP}
                          name="SedeCAP"
                          onChange={handleEdit}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* FOOTER */}
                <div className="btn-footer-container">
                  <button
                    className="save-btn"
                    type="button"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Salva
                  </button>
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => {
                      setShowTra(false);
                    }}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
