import React, { useState, useEffect } from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Macchinari = () => {
  const [macchinari, setMacchinari] = useState([]);
  const [societa, setSocieta] = useState([]);
  const [tipoMacchine, setTipoMacchine] = useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  const [selectedC, setSelectedC] = useState(0);
  const navigate = useNavigate();

  const columns = [
    {
      field: "Tipologia",
      headerName: "Tipologia",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Marca",
      headerName: "Marca",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Modello",
      headerName: "Modello",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "NomeMacchina",
      headerName: "Nome",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Targa",
      headerName: "Targa",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "RagioneSociale",
      headerName: "Ragione Sociale",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Note",
      headerName: "Note",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{color:"#047857"}}/>
        <GridToolbarFilterButton sx={{color:"#047857"}}/>
        <GridToolbarDensitySelector sx={{color:"#047857"}}/>
        <GridToolbarExport sx={{color:"#047857"}}/>
      </GridToolbarContainer  >
    );
  }




  const loadMacchinari = async () => {
    await api
      .get("anagraficaMacchine", {
        headers: { Authorization: `Bearer ${cookies.user.jwt}` },
      })
      .then((res) => {
        setMacchinari(res.data.data);
      });
  };
  const handleDeleteButtonClick = async () => {
    if (selectedC === 0) {
      alert("Seleziona almeno un Macchinario");
    } else {
      if (
        window.confirm(
          "Sei sicuro di voler eliminare il Macchinario selezionato?"
        )
      ) {
        try {
          let mac = selectedC;
          mac.Deleted = 1;
          await api.put(
            "anagraficaMacchine",
            { macchina: mac },
            { headers: { Authorization: `Bearer ${cookies.user.jwt}` } }
          );

          alert("Macchinario eliminato con successo");
          window.location.reload(); //refresho
        } catch {
          alert("ERRORE DURANTE L'ELIMINAZIONE");
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      loadMacchinari();

      await api
        .get("societa", {
          params: { anagrafiche: true },
          headers: { Authorization: `Bearer ${cookies.user.jwt}` },
        })
        .then((res) => {
          setSocieta(res.data.data);
        });
      await api
        .get("tipoMacchine", {
          params: { anagrafiche: true },
          headers: { Authorization: `Bearer ${cookies.user.jwt}` },
        })
        .then((res) => {
          setTipoMacchine(res.data.data);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      <div class="flex m-0 justify-end items-center w-full space-x-2 mb-4 ">
        {/* <ModalMacchinario myFunction={loadMacchinari} cookies={cookies} buttonText="Nuovo" id="newButtonCliente" title="Crea Macchinario" operation="new" classNameButton="new-btn" macchinari={macchinari}/> */}
        <button
          id="editButtonCliente"
          className="new-btn"
          type="button"
          onClick={() => {
            if (selectedC === 0) {
              alert("Seleziona un macchinario da modificare");
            } else {
              navigate("/Macchinario", {
                state: {
                  operation: "edit",
                  macchinario: selectedC,
                  cookies: cookies,
                  title: "Modifica Macchinario",
                },
              });
            }
          }}
        >
          Modifica
        </button>
        <button
          id="newButtonCliente"
          className="new-btn"
          type="button"
          onClick={() =>
            navigate("/Macchinario", {
              state: {
                operation: "new",
                cookies: cookies,
                title: "Nuovo Macchinario",
              },
            })
          }
        >
          Nuovo
        </button>
        {/* <button myFunction={loadMacchinari} cookies={cookies} buttonText="Modifica" selectedCli={selectedC} id="editButtonCliente" title="Modifica Macchinario" operation="edit" classNameButton="new-btn" macchinari={macchinari}> Edit</button> */}
        <button className="delete-btn" onClick={handleDeleteButtonClick}>
          Elimina
        </button>
      </div>
      <div className=" h-4/5">
        <DataGrid
        components={{
          Toolbar: CustomToolbar,
        }}
          sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
          rows={macchinari}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row) => row.ID}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = macchinari.filter((row) =>
              selectedIDs.has(row.ID)
            );

            setSelectedC(selectedRowData[0]);
          }}
          onRowDoubleClick={async (params) => {
            await setSelectedC(params.row);
            document.getElementById("editButtonCliente").click();
          }}
        />
      </div>
    </>
  );
};

export default Macchinari;
