import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import { useCookies } from "react-cookie";
import DatePicker from "@mui/lab/DatePicker";
import Table from "./Table";
import { TextField, Box } from "@mui/material";
import moment from "moment";
import momentTimezone from "moment-timezone";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "moment/locale/it";
import { DataGrid, itIT, zhCN,GridToolbarContainer,GridToolbarFilterButton} from "@mui/x-data-grid";


const DettaglioODL = (props) => {
  const columns = [
    { field: "Nominativo", headerName: "Lavoratore", width: 90, flex: 1 },
  ];
  const columnsEsterni = [
    { field: "Nome", headerName: "Nome", width: 90, flex: 1 },
    { field: "Cognome", headerName: "Cognome", width: 90, flex: 1 },
    { field: "Ditta", headerName: "Ditta", width: 90, flex: 1 },
  ];

  const location = useLocation();
  const [operation, setOperation] = useState(location.state.operation);
  const [attivita, setAttivita] = useState([]);
  const [clienti, setClienti] = useState([]);
  const [cantieri, setCantieri] = useState([]);
  const [societa, setSocieta] = useState([]);
  const [lavoratori, setLavoratori] = useState([]);
  const [esterni, setEsterni] = useState([]);
  const [commessa, setCommessa] = useState({ Periodo_Da: moment(new Date()).format("YYYY-MM-DD"), Periodo_A: moment(new Date()).format("YYYY-MM-DD"), IDSocieta: "", Societa: "", Deleted: 0, NomeCommessa: "", IDCantiere: "", Cliente: "", NomeCliente: "", Note: "", TipoAttivita: "" });
  const [localita, setLocalita] = useState({ Citta: "", Indirizzo: "", Cap: "", Provincia: "", IDCantiere: "0", URL_Maps: "", Presso: "" });
  const [cookies, setCookie] = useCookies(["user"]);
  const [autistiSel, setAutistiSel] = React.useState([]);
  const [operatoriSel, setOperatoriSel] = React.useState([]);
  const [esternoSel, setEsternoSel] = React.useState();
  const [autistiSelBackup, setAutistiSelBackup] = React.useState([]);
  const [operatoriSelBackup, setOperatoriSelBackup] = React.useState([]);
  const [dataMacchine, setDataMacchine] = useState({ combo: [], table: [] })
  const [okLoad, setOkLoad] = useState(false);
  const [fisso, setFisso] = useState(0);
  const [backupCommessa, setBackupCommessa] = useState([]);


  useEffect(() => {

    const fetchData = async () => {
      await api.get('attivita', {
        headers: {
          'Authorization': `Bearer ${cookies.user.jwt}` //attività
        }
      })
        .then(res => {
          setAttivita(res.data.data);

        })

      await api.get('clienti', {
        headers: {
          'Authorization': `Bearer ${cookies.user.jwt}` //Clienti
        }
      })
        .then(res => {
          setClienti(res.data.data);

        })
      await api.get('societa', {
        headers: {
          'Authorization': `Bearer ${cookies.user.jwt}` //Società
        }
      })
        .then(res => {
          setSocieta(res.data.data);

        })

      await api.get('lavoratori', {
        headers: {
          'Authorization': `Bearer ${cookies.user.jwt}` //Società
        }
      })
        .then(res => {
          setLavoratori(res.data.data);

        })

      if (operation === "edit") {



        let responseCommessaLoad = await api.get('commesse', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, params: { id: location.state.idCommessa } });

        let cl = responseCommessaLoad.data.data[0];
        setBackupCommessa(cl.IDCantiere)
        loadCantieri(cl.Cliente);
        setCommessa({ ID: cl.ID, Periodo_Da: moment(cl.Periodo_Da).format("YYYY-MM-DD"), Periodo_A: moment(cl.Periodo_A).format("YYYY-MM-DD"), IDSocieta: cl.IDSocieta, Societa: cl.Societa, Deleted: cl.Deleted, NomeCommessa: cl.NomeCommessa, IDCantiere: cl.IDCantiere, Cliente: cl.Cliente, NomeCliente: cl.NomeCliente, Note: cl.Note, TipoAttivita: cl.TipoAttivita })
        //ESTERNI
        await api.get('cantieri', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, params: { idCantiere: cl.IDCantiere } })
          .then(res => {
            let l = res.data.data[0];
            console.log(res.data.data[0])
            setFisso(l.Fisso)
            let choiche = l.IndirizzoCompleto.split("-");

            setLocalita({ Citta: choiche[0], Indirizzo: choiche[1], Provincia: choiche[2], Cap: choiche[3], Presso: choiche[4], IDCantiere: cl.IDCantiere, URL_Maps: l.URL_Maps });
          });

        await api.get('lavoratoriAbilitatiCommessa', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, params: { id: location.state.idCommessa } })
          .then(res => {

            let autS = res.data.data.filter(x => x.Autorizzazione === 5) //seleziono solo gli autisti
            let opS = res.data.data.filter(x => x.Autorizzazione === 4) //seleziono solo gli operatori
            let resultAut = autS.map(a => parseInt(a.Lavoratore));
            let resultOp = opS.map(a => parseInt(a.Lavoratore));


            //let result = res.data.data.map(a =>parseInt(a.Lavoratore));

            setOperatoriSel(resultOp);
            setAutistiSel(resultAut);
            setAutistiSelBackup(resultAut);
            setOperatoriSelBackup(resultOp); //PER LA DIFFERENZA
            setOkLoad(true);
          });
        await api.get('esterni', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, params: { id: location.state.idCommessa } })
          .then(res => {
            setEsterni(res.data.data);

          });




      }



    }
    fetchData();
  }, []);


  function CustomToolbar() {
    return (
      <GridToolbarContainer >
        <GridToolbarFilterButton sx={{ color: "green" }} />
      </GridToolbarContainer>
    );
  }









  const manageEsterniOperatori = async (aggiunto, tolto) => {

    try {
      if (aggiunto.length > 0) {

        let responseLavoratori = await api.post('lavoratoriAbilitatiCommessa', { commessa: commessa.ID, lavoratori: aggiunto }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
        for (let i = 0; i < aggiunto.length; i++) {
          setOperatoriSelBackup(current => [...current, aggiunto[i]]);
        }

      }

      if (tolto.length > 0) {

        await api.delete('lavoratoriAbilitatiCommessa', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, data: { commessa: commessa.ID, lavoratori: tolto } });


        let difference = operatoriSelBackup.filter(x => !tolto.includes(x));

        setOperatoriSelBackup(difference);

      }
    } catch {
      alert("errore durante l'inserimento");
    }

  }


  const manageEsterniAutisti = async (aggiunto, tolto) => {
    try {
      if (aggiunto.length > 0) {

        let responseLavoratori = await api.post('lavoratoriAbilitatiCommessa', { commessa: commessa.ID, lavoratori: aggiunto }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
        for (let i = 0; i < aggiunto.length; i++) {
          setAutistiSelBackup(current => [...current, aggiunto[i]]);
        }
      }

      if (tolto.length > 0) {

        await api.delete('lavoratoriAbilitatiCommessa', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, data: { commessa: commessa.ID, lavoratori: tolto } });


        let difference = autistiSelBackup.filter(x => !tolto.includes(x));

        setAutistiSelBackup(difference);

      }

    } catch {
      alert("errore durante l'inserimento");
    }
  }


  const loadCantieri = async (selId) => {

    await api.get('cantieri', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, params: { id: selId } })
      .then(res => {

        setCantieri(res.data.data);
      })

  }
  const addEsterno = async (esterno) => {

    setEsterni(currentEsterni => [...currentEsterni, esterno]);

    if (operation === "edit") {
      try {
        let responseEsterni = await api.post('esterni', { commessa: commessa.ID, esterni: [esterno], periodoA: commessa.Periodo_A, periodoDa: commessa.Periodo_Da }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })

      } catch {
        alert("errore durante l'inserimento dell'esterno");
      }

    }
  }

  const deleteEsterno = async () => {


    let difference = esterni.filter(x => x.Nome !== esternoSel[0].Nome && x.Cognome !== esternoSel[0].Cognome && x.Ditta !== esternoSel[0].Ditta);
    setEsterni(difference);


    if (operation === "edit") {
      try {
        await api.delete('esterni', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, data: { idEsterno: esternoSel[0].ID } });
        alert("esterno rimosso con successo");
      } catch {
        alert("errore durante la cancellazione");
      }
    }


  }


  const handleCantieriChange = (e) => {
    let c = commessa;
    c.IDCantiere = e.target.value;
    setCommessa(c);


    if (e.target.value === "0") {
      setLocalita({ Citta: "", Indirizzo: "", Provincia: "", Cap: "", Presso: "", IDCantiere: 0, URL_Maps: "" })

    } else {
      let index = e.target.selectedIndex;
      let choiche = e.target[index].text.split("-"); //prendo il testo e lo splitto
      let c = cantieri.filter(c => c.ID === parseInt(e.target.value));
      setLocalita({ Citta: choiche[0], Indirizzo: choiche[1], Provincia: choiche[2], Cap: choiche[3], Presso: choiche[4], IDCantiere: e.target.value, URL_Maps: c[0].URL_Maps ? c[0].URL_Maps : "" })


    }

  }

  const handleLocalitaChange = (e) => {
    let loc = { ...localita };
    loc[e.target.name] = e.target.value;
    loc.IDCantiere = "0";
    setLocalita(loc)

  }

  const handleSave = async () => {


    if (!(new Date(moment(commessa.Periodo_A).format("YYYY-MM-DD")) > new Date(commessa.Periodo_Da))) {
     
      alert("ATTENZIONE, la data di fine non può essere antecedente alla data di inizio");
      return
    }




    if (operation === "new") { //se creo nuovo
      let c = { ...commessa }
      let l = { ...localita }
      if (l.IDCantiere === "0") {
        try {
          l.Fisso = 0;
          l.IDCliente = c.Cliente;
          l.NomeCantiere = l.Citta;

          let responseCantiere = await api.post('cantieri', { cantiere: l }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
          l.IDCantiere = responseCantiere.data.message.insertId;
          setFisso(0);
        } catch {
          alert("errore durante la creazione del nuovo cantiere");
        }
      } else {
        setFisso(1);
      }
      c.IDCantiere = l.IDCantiere;
      c.NomeCommessa = c.NomeCliente + " - " + l.Citta;
      console.log(c);
      try {
        let responseCommessa = await api.post('commesse', { commessa: c }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
        c.ID = responseCommessa.data.message.insertId;

        let responseLavoratori = await api.post('lavoratoriAbilitatiCommessa', { commessa: responseCommessa.data.message.insertId, lavoratori: autistiSel.concat(operatoriSel) }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
        let responseEsterni = await api.post('esterni', { commessa: responseCommessa.data.message.insertId, esterni: esterni, periodoA: commessa.Periodo_A, periodoDa: commessa.Periodo_Da }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
        setOperation("edit");
        setCommessa(c);
        setLocalita(l);
        alert("Commessa creata con successo")
      }
      catch {
        alert("errore durante la creazione della nuova commessa");
      }


    } else { // se modifico
      try {
        let l = { ...localita }
        let c = { ...commessa }
        if (backupCommessa !== commessa.IDCantiere && (commessa.IDCantiere === 0 || commessa.IDCantiere === "0")) {
          
          try {
            l.Fisso = 0;
            l.IDCliente = commessa.Cliente;
            l.NomeCantiere = l.Citta;

            let responseCantiere = await api.post('cantieri', { cantiere: l }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
            l.IDCantiere = responseCantiere.data.message.insertId;
            setFisso(0);
          } catch {
            alert("errore durante la creazione del nuovo cantiere");
          }

        }
        c.IDCantiere = l.IDCantiere;
        let responseCommessa = await api.put('commesse', { commessa: c }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })


        alert("Commessa aggiornata con successo");
      } catch {
        alert("Errore durante il salvataggio")
      }
    }



  }



  return (
    <>
      <div className="flex h-full">
        {/* COLONNA SINISTRA */}
        <div className="flex flex-row w-full px-3 mt-6">
          <div className="flex flex-col w-full">

            <label className="flex flex-row items-center mb-1 mb-3">
              <span className="text-gray-700 w-2/5" >Società</span>
              <select value={commessa.IDSocieta} onChange={(e) => {
                let c = { ...commessa };
                c.IDSocieta = e.target.value;
                let index = e.target.selectedIndex;
                c.Societa = e.target[index].text;
                setCommessa(c);
              }}
                className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md">
                <option value="0" >Seleziona...</option>
                {societa?.map((soc) =>
                  <option key={soc.ID} value={soc.ID} >{soc.RagioneSociale}</option>
                )}
              </select>
            </label>

            <label className="flex flex-row items-center mb-3">
              <span className="text-gray-700 w-2/5">Cliente</span>
              <select disabled={commessa.ID > 0} value={commessa.Cliente} onChange={(e) => {
                loadCantieri(e.target.value);
                let c = { ...commessa };
                c.Cliente = e.target.value;
                let index = e.target.selectedIndex;
                c.NomeCliente = e.target[index].text;
                setCommessa(c);
                setLocalita({ Citta: "", Indirizzo: "", Provincia: "", Cap: "", Presso: "", IDCantiere: 0, URL_Maps: "" })
              }}
                className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md">
                <option value="0" >Seleziona...</option>
                {clienti?.map((cli) =>

                  <option key={cli.ID} value={cli.ID} >{cli.RagSociale}</option>

                )}
              </select>
            </label>

            <label className="flex flex-row items-center mb-3">
              <span className="text-gray-700 w-2/5">Cantieri</span>
              <select onChange={handleCantieriChange} className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md" value={commessa.IDCantiere} disabled={commessa.ID > 0 && cookies.user.Autorizzazione !== 1}>
                <option value={0} >Seleziona...</option>
                {cantieri?.map((can) => {
                  if (can.IndirizzoCompleto !== "" && can.IndirizzoCompleto !== null) {
                    return <option key={can.ID} value={can.ID} >{can.IndirizzoCompleto}</option>
                  }
                })}
              </select>
            </label>

            <label className="flex flex-row items-center mb-3">
              <span className="text-gray-700 w-2/5">Attività</span>
              <select value={commessa.TipoAttivita} onChange={(e) => {
                let c = { ...commessa };
                c.TipoAttivita = e.target.value;
                setCommessa(c);
              }}
                className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md">
                {attivita?.map((att) =>

                  <option key={att.ID} value={att.ID} >{att.Descrizione}</option>

                )}
              </select>
            </label>


            <LocalizationProvider dateAdapter={DateAdapter} >
              <DatePicker
                views={['year', 'month', 'day']}
                value={commessa.Periodo_Da}
                onChange={(newValue) => {
                  let c = { ...commessa };
                  c.Periodo_Da = moment(newValue).format("YYYY-MM-DD");
                  setCommessa(c);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="inline-flex items-center justify-between w-full pr-3 mb-3">
                    <label className="flex items-center w-full">
                      <span className="text-gray-700 w-2/5">Periodo Da</span>
                      <input className="mt-0 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 text-start pb-1 ml-8" style={{}} ref={inputRef} {...inputProps} />
                    </label>
                    {InputProps?.endAdornment}
                  </div>
                )} />
            </LocalizationProvider>


            <LocalizationProvider dateAdapter={DateAdapter} >
              <DatePicker
                views={['year', 'month', 'day']}
                value={commessa.Periodo_A}
                onChange={(newValue) => {
                 // if (new Date(moment(newValue).format("YYYY-MM-DD")) > new Date(commessa.Periodo_Da)) {
                    let c = { ...commessa };
                    c.Periodo_A = moment(newValue).format("YYYY-MM-DD");
                    setCommessa(c);
                  //} else {
                  //  alert("ATTENZIONE, la data di fine non può essere antecedente alla data di inizio");
                 // }

                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="inline-flex items-center justify-between w-full pr-3 mb-3">
                    <label className="flex items-center w-full">
                      <span className="text-gray-700 w-2/5" >Periodo A</span>
                      <input className=" mt-0 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 text-start pb-1 ml-8" ref={inputRef} {...inputProps} />
                    </label>
                    {InputProps?.endAdornment}
                  </div>

                )} />
            </LocalizationProvider>
            <label className="flex flex-row items-end mb-3">
              <span className="text-gray-700 w-2/5">{"Ditta (Presso)"}</span>
              <input
                type="text"
                className=" mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 rounded-md"
                onChange={handleLocalitaChange}
                name="Presso"
                value={localita.Presso}
                readOnly={localita.IDCantiere !== "0" && localita.IDCantiere !== 0 && (operation === "edit" ? cookies.user.Autorizzazione !== 1 || fisso === 1 : true)}
              />
            </label>
            <label className="flex flex-row items-end mb-3">
              <span className="text-gray-700 w-2/5">Indirizzo</span>
              <input
                type="text"
                className="mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 rounded-md"
                readOnly={localita.IDCantiere !== "0" && localita.IDCantiere !== 0 && (operation === "edit" ? cookies.user.Autorizzazione !== 1 || fisso === 1 : true)}
                name="Indirizzo"
                value={localita.Indirizzo}
                onChange={handleLocalitaChange}
              />
            </label>
            <label className="flex flex-row items-end mb-3">
              <span className="text-gray-700 w-2/5">Città</span>
              <input
                type="text"
                className="w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 rounded-md"
                name="Citta"
                value={localita.Citta}
                readOnly={localita.IDCantiere !== "0" && localita.IDCantiere !== 0 && (operation === "edit" ? cookies.user.Autorizzazione !== 1 || fisso === 1 : true)}
                onChange={handleLocalitaChange}
              />
            </label>

            <label className="flex flex-row items-end mb-3">
              <span className="text-gray-700 w-2/5">Cap</span>
              <input
                type="text"
                className="mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 rounded-md"
                onChange={handleLocalitaChange}
                name="Cap"
                value={localita.Cap}
                readOnly={localita.IDCantiere !== "0" && localita.IDCantiere !== 0 && (operation === "edit" ? cookies.user.Autorizzazione !== 1 || fisso === 1 : true)}
              />
            </label>

            <label className="flex flex-row items-end mb-3">
              <span className="text-gray-700 w-2/5">Provincia</span>
              <input
                type="text"
                className=" mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 rounded-md"
                onChange={handleLocalitaChange}
                name="Provincia"
                value={localita.Provincia}
                readOnly={localita.IDCantiere !== "0" && localita.IDCantiere !== 0 && (operation === "edit" ? cookies.user.Autorizzazione !== 1 || fisso === 1 : true)}
              />
            </label>

            <label className="flex flex-row items-end mb-3">
              <span className="text-gray-700 w-2/5">URL maps</span>
              <input
                type="text"
                className="mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 rounded-md"
                name="URL_Maps"
                value={localita.URL_Maps}
                readOnly={localita.IDCantiere !== "0" && localita.IDCantiere !== 0 && (operation === "edit" ? cookies.user.Autorizzazione !== 1 || fisso === 1 : true)}
                onChange={handleLocalitaChange}
              />
            </label>

            <label className="flex flex-row items-end mb-3">
              <span className="text-gray-700 w-2/5">Descrizione ODL</span>
              <textarea
                type="text"
                className="mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5"
                value={commessa.Note}
                onChange={(e) => {
                  let c = { ...commessa };
                  c.Note = e.target.value;
                  setCommessa(c);
                }}
                placeholder=""
              />
            </label>
            <div className="w-full mt-4">{/* w-full h-10 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md */}
              <button onClick={handleSave} className="px-6 py-2 flex border-emerald-700 border-2 bg-emerald-700 hover:border-emerald-600 hover:bg-emerald-600 font-bold text-white uppercase p-1 rounded-md disabled:opacity-75 disabled:hover:bg-emerald-700">SALVA</button>
            </div>
          </div>
        </div>
        {/* COLONNA DESTRA */}
        <div className="flex flex-row w-full flex-wrap h-full">
          {/* SOPRA */}
          <div className="flex flex-row w-full mb-4">
            <div className="flex w-1/2 pr-2 flex-wrap">
              <p className="w-full text-center">Operatori</p>
              <DataGrid
                sx={{ '.MuiDataGrid-columnSeparator': { display: 'none', } }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                rows={lavoratori.filter(lav => lav.Autorizzazione === 4)}
                getRowId={row => row.ID}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[10]}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(newSelection) => {
                  setOperatoriSel(newSelection);

                  if (operation === "edit" && okLoad) {
                    console.log(newSelection);
                    let aggiunto = newSelection.filter(x => !operatoriSelBackup.includes(x)); //se c'è un valore ho aggiunto
                    let tolto = operatoriSelBackup.filter(x => !newSelection.includes(x)); //se c'è un valore ho tolto
                    manageEsterniOperatori(aggiunto, tolto);
                  }

                }}
                selectionModel={operatoriSel}
              />

            </div>
            <div className="flex w-1/2 pl-2 flex-wrap">
              <p className="w-full text-center">Autisti</p>
              <DataGrid
                sx={{ '.MuiDataGrid-columnSeparator': { display: 'none', } }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                rows={lavoratori.filter(lav => lav.Autorizzazione === 5)}
                getRowId={row => row.ID}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[10]}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(newSelection) => {
                  setAutistiSel(newSelection);

                  if (operation === "edit" && okLoad) {

                    let aggiunto = newSelection.filter(x => !autistiSelBackup.includes(x)); //se c'è un valore ho aggiunto
                    let tolto = autistiSelBackup.filter(x => !newSelection.includes(x)); //se c'è un valore ho tolto
                    console.log(aggiunto, tolto)
                    manageEsterniAutisti(aggiunto, tolto);
                  }

                }}
                selectionModel={autistiSel}
              />
            </div>
          </div>
          {/* SOTTO */}

          <div className="flex flex-row w-full flex-wrap mt-4">
            Esterni
            <div className="w-1/4 w-full self-center pr-3">
              <Modal buttonText="Aggiungi Esterno" title="Aggiungi Esterno" myFunction={addEsterno} ></Modal>
              <button className="w-full bg-red-600 hover:bg-red-500 text-white rounded-md mt-2 p-1" onClick={deleteEsterno}> Rimuovi Esterno</button>
              <Macchine buttonText="Macchine" title="Macchine" myFunction={addEsterno} operation={operation} commessa={commessa.ID} ></Macchine>
            </div>

            <div className="flex flex-row w-3/4">

              <DataGrid
                sx={{ '.MuiDataGrid-columnSeparator': { display: 'none', } }}
                rows={esterni}
                getRowId={row => row.Nome + row.Cognome + row.Ditta}
                columns={columnsEsterni}
                pageSize={10}
                rowsPerPageOptions={[10]}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = esterni.filter((row) =>
                    selectedIDs.has(row.Nome + row.Cognome + row.Ditta),
                  );
                  setEsternoSel(selectedRowData);
                }}
              />
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default DettaglioODL;

const Modal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [esterno, setEsterno] = useState({ Nome: "", Cognome: "", Ditta: "" });

  const handleEdit = (e) => {
    let es = { ...esterno };
    es[e.target.name] = e.target.value;
    setEsterno(es);
  }

  return (
    <>
      <button
        className="w-full bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md"
        type="button"
        onClick={() => { setEsterno({ Nome: "", Cognome: "", Ditta: "" }); setShowModal(true) }}
      >
        {props.buttonText}
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props.title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <label className="relative ">
                    <span className="text-gray-700">Nome</span>
                    <input
                      type="text"
                      className="
                             mt-0
                             block
                             border-0 border-b-2 border-gray-200
                             focus:ring-0 focus:border-emerald-700"
                      placeholder=""
                      value={esterno.Nome}
                      name="Nome"
                      onChange={handleEdit}
                    />
                  </label>
                  <label className="relative ">
                    <span className="text-gray-700">Cognome</span>
                    <input
                      type="text"
                      className="
                             mt-0
                             block
                             border-0 border-b-2 border-gray-200
                             focus:ring-0 focus:border-emerald-700"
                      value={esterno.Cognome}
                      name="Cognome"
                      onChange={handleEdit}
                    />
                  </label>
                  <label className="relative ">
                    <span className="text-gray-700">Ditta</span>
                    <input
                      type="text"
                      className="
                             mt-0
                             block
                             border-0 border-b-2 border-gray-200
                             focus:ring-0 focus:border-emerald-700"
                      value={esterno.Ditta}
                      name="Ditta"
                      onChange={handleEdit}
                    />
                  </label>
                </div>
                {/*footer*/}
                <div className="btn-footer-container">
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Chiudi
                  </button>
                  <button
                    className="save-btn"
                    type="button"
                    onClick={() => { props.myFunction(esterno); setShowModal(false) }}
                  >
                    Salva
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}


const Macchine = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [esterno, setEsterno] = useState({ Nome: "", Cognome: "", Ditta: "" });
  const [dataMacchine, setDataMacchine] = useState(props.dataMacchine);
  const [comboMacchinaSel, setComboMacchinaSel] = useState(0);
  const [tableMacchinaSel, setTableMacchinaSel] = useState(0);
  const [cookies, setCookie] = useCookies(["user"]);
  const columns = [
    { field: 'NomeMacchina', headerName: 'Macchina', width: 90, flex: 1 },]
  const handleEdit = (e) => {
    let es = { ...esterno };
    es[e.target.name] = e.target.value;
    setEsterno(es);
  }

  const loadCar = async () => {
    await api.get('macchine', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, params: { id: props.commessa } })
      .then(res => {
        setDataMacchine(res.data.data); //popolo il dato che mi serve
        setComboMacchinaSel("0");
        setTableMacchinaSel("0");
      });



  }

  const deleteCar = async () => {
    try {
      console.log(tableMacchinaSel[0].ID)
      let deleteCar = await api.delete('macchine', { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` }, data: { macchina: tableMacchinaSel[0].ID } });
      loadCar();
      alert("macchinario rimosso con successo")
    } catch {
      alert("errore durante la rimozione del macchinario")
    }


  }

  const addCarr = async () => {
    console.log(comboMacchinaSel)
    let car = dataMacchine.combo.filter(c => c.Id === parseInt(comboMacchinaSel))
    console.log(car[0]);
    try {
      let responseLavoratori = await api.post('macchine', { macchina: comboMacchinaSel, commessa: props.commessa }, { headers: { 'Authorization': `Bearer ${cookies.user.jwt}` } })
      loadCar();
      alert("macchina inserita con successo")

    } catch {
      alert("errore durante l'inserimento macchina")
    }


  }


  return (
    <>
      <button
        style={{ display: props.operation === "edit" ? "" : "none" }}
        className="w-full bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md mt-2"
        type="button"
        onClick={async () => { await loadCar(); setShowModal(true) }}
      >
        {props.buttonText}
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props.title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">

                  <label className="relative">
                    <span className="text-gray-700">Macchina</span>
                    <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full"
                      onChange={(e) => {

                        setComboMacchinaSel(e.target.value);
                      }}
                    >
                      <option value={0} >Seleziona...</option>
                      {dataMacchine.combo?.map((mac) =>

                        <option key={mac.Id} value={mac.Id} >{mac.NomeMacchina}</option>

                      )}
                    </select>
                  </label>

                  <button
                    onClick={addCarr}
                    disabled={comboMacchinaSel === "0"}
                    className="mt-10 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md self-center  w-full disabled:opacity-75 disabled:hover:bg-emerald-700"
                    type="button"
                  >
                    Aggiungi Macchina
                  </button>
                  <div className="mt-10" style={{ height: "400px" }}>
                    <DataGrid
                      sx={{ '.MuiDataGrid-columnSeparator': { display: 'none', } }}

                      rows={dataMacchine.table}
                      getRowId={row => row.ID}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = dataMacchine.table.filter((row) =>
                          selectedIDs.has(row.ID),
                        );
                        setTableMacchinaSel(selectedRowData);
                      }}

                    />
                  </div>
                  <button
                    onClick={deleteCar}
                    disabled={tableMacchinaSel === "0"}
                    className="mt-10 bg-red-600 hover:bg-red-500 text-white p-1 rounded-md self-center  w-full disabled:opacity-75 disabled:hover:bg-red-600"
                    type="button"
                  >
                    Elimina Macchina
                  </button>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}





