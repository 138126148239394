import React,{useState,useEffect} from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";


const Cantieri = () => {
const [cantieri,setCantieri] = useState([])
const [clienti,setClienti] = useState([])
const [cookies, setCookie] = useCookies(["user"]);
const [selectedC,setSelectedC] = useState(0);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{color:"#047857"}}/>
      <GridToolbarFilterButton sx={{color:"#047857"}}/>
      <GridToolbarDensitySelector sx={{color:"#047857"}}/>
      <GridToolbarExport sx={{color:"#047857"}}/>
    </GridToolbarContainer  >
  );
}


const columns = [
    { field: 'id', headerName: 'ID', width: 90,hide:true },
    {
      field: 'NomeCantiere',
      headerName: 'Nome Cantiere',
      headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
      flex: 1,
    },
    {
      field: 'RagSociale',
      headerName: 'Cliente',
      headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
      flex: 1,
    },
    {
        field: 'Provincia',
        headerName: 'Provincia',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
      {
        field: 'Citta',
        headerName: 'Citta',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
      {
        field: 'Indirizzo',
        headerName: 'Indirizzo',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
      {
        field: 'CAP',
        headerName: 'CAP',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
      {
        field: 'URL_Maps',
        headerName: 'URL Maps',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
      
   
  ];
  
const loadCantieri = async() => {
    await api.get('cantieri', {params:{anagrafiche:true},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
          setCantieri(res.data.data);
        })
    
}

const handleDeleteButtonClick = async() => {
    if(selectedC === 0){
      alert("Seleziona almeno un cantiere");
    }else{
       
      if (window.confirm('Sei sicuro di voler eliminare il cantiere selezionato?')) {
        try{
            let can = selectedC;
            can.Deleted = 1;
          await api.put('cantieri',{cantiere:can},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  
      alert("Cantiere eliminato con successo");
      window.location.reload();//refresho
        }catch{
          alert("ERRORE DURANTE L'ELIMINAZIONE")
        }  
    }
    }
  }

useEffect(() => {
   
        const fetchData = async () =>{
          loadCantieri();

          await api.get('clienti', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
            setClienti(res.data.data);
        })
    }
    fetchData();
    },[]);


return(
  <>
    <div class="flex m-0 justify-end items-center w-full space-x-2 mb-4">
      
        <ModalCantiere myFunction={loadCantieri} cookies={cookies} buttonText="Nuovo" id="newButtonCliente" title="Crea Cantiere" operation="new" classNameButton="new-btn" clienti={clienti}/>
      
        <ModalCantiere myFunction={loadCantieri} cookies={cookies} buttonText="Modifica" selectedCli={selectedC} id="editButtonCliente" title="Modifica Cantiere" operation="edit" classNameButton="new-btn" clienti={clienti}/>
          <button className="delete-btn" onClick={handleDeleteButtonClick}> 
            Elimina
          </button>
    </div>
    <div className=" h-4/5">
      <DataGrid
       components={{
        Toolbar: CustomToolbar,
      }}
        sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
        rows={cantieri}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        getRowId={row=> row.ID}
        onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = cantieri.filter((row) =>
            selectedIDs.has(row.ID),
            );
            
            setSelectedC(selectedRowData[0]);
        }}
        onRowDoubleClick={async (params) => {
            await setSelectedC(params.row);
            document.getElementById("editButtonCliente").click();
        }}
      /> 
   
      
    </div> 
</>
)

}

export default Cantieri;




const ModalCantiere = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [cantiere, setCantiere] = useState({IDCliente:0,NomeCantiere:"",Provincia:"",Citta:"",Indirizzo:"",CAP:"",URL_Maps:"",Fisso:1,Presso:""});

    const handleEdit = (e) => {
        let can = {...cantiere};
        can[e.target.name] = e.target.value;
        setCantiere(can);
    }
    const handleSave = async () => {
        if(props.operation ==="new"){
                try{
                  let c = {...cantiere};
                  c.Fisso=1;
                    let responseCantiere= await api.post('cantieri',{cantiere:c},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
                    alert("Cantiere inserito con successo");
                    props.myFunction();
                    setShowModal(false)
                }catch{
                    alert("Errore durante la creazione del nuovo cantiere");
                }


        }else{
            try{
              let c = {...cantiere};
              c.Fisso=1;
                let responseCantiere= await api.put('cantieri',{cantiere:c},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
                alert("Cantiere modificato con successo");
                props.myFunction();
               
            }catch{
                alert("Errore durante la modifica del nuovo cantiere");
            }

        }   
    }
    const openModal = async() => {
     if(props.operation ==="new"){
      setCantiere({IDCliente:0,NomeCantiere:"",Provincia:"",Citta:"",Indirizzo:"",CAP:"",URL_Maps:"",Fisso:1,Presso:""});
     }else{
      setCantiere(props.selectedCli);
     }
        setShowModal(true);
    }


    return (
      <>
        <button
          id={props.id}
          className={props.classNameButton}
          type="button"
          onClick={async () => {openModal()}}
        >
          {props.buttonText}
        </button>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <label className="relative ">
                             <span className="text-gray-700">Cliente</span>
                             <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md" value={cantiere.IDCliente} onChange={(e) =>{ let c = {...cantiere}; c.IDCliente = e.target.value; setCantiere(c) }} > 
          <option value="0" >Seleziona...</option>
          {props.clienti?.map((cli) => 

            <option key={cli.ID} value={cli.ID} >{cli.RagSociale}</option>

            )}
          </select>
                            </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="relative ">
                             <span className="text-gray-700">Nome Cantiere</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cantiere.NomeCantiere}
                                         name="NomeCantiere"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            
                            
                            <label className="relative ">
                             <span className="text-gray-700">Provincia</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cantiere.Provincia}
                                         name="Provincia"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            <label className="relative ">
                             <span className="text-gray-700">Citta</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cantiere.Citta}
                                         name="Citta"
                                        onChange={handleEdit}
                                                                />
                            </label>

                    </div>
                    <div>
                    <label className="relative ">
                         <span className="text-gray-700">Indirizzo</span>
                        <input
                           type="text"
                           className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                           placeholder=""
                           value={cantiere.Indirizzo}
                           name="Indirizzo"
                          onChange={handleEdit}
                         />
                    </label>

                    <label className="relative ">
                             <span className="text-gray-700">CAP</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cantiere.CAP}
                                         name="CAP"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            <label className="relative ">
                             <span className="text-gray-700">URL_Maps</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cantiere.URL_Maps}
                                         name="URL_Maps"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            <label className="relative ">
                             <span className="text-gray-700">Presso</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cantiere.Presso}
                                         name="Presso"
                                        onChange={handleEdit}
                                                                />
                            </label>
                        
                    </div>
                </div>
                  </div>
                  {/*footer*/}
                  <div className="btn-footer-container">
                    <button
                      className="save-btn"
                      type="button"
                      onClick={handleSave}
                    >
                      Salva
                    </button>
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }  

