import React,{useState,useEffect} from "react";
import api from "../../api/api";
import { useCookies } from "react-cookie";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip,IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

 const OrdinePanoramica = () => {
    const [cookies, setCookie] = useCookies(["user"]);
    const [idUtente, setIDUtente] = useCookies(["ID"]);
    const [ordineLavoratori, setOrdineLavoratori] = useState([]);
    const [ordineLavoratoriBackup, setOrdineLavoratoriBackup] = useState([]);
    const [zone, setZone] = useState([]);
    const [zonaSel,setZonaSel] = useState(1);
    const [rigaSel,setRigaSel] = useState(false)



    const timer=null;
    const columns = [
        {
          field: 'Nominativo',
          headerName: 'Nominativo',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex:1,
        },
        {
          field: 'Autorizzazione',
          headerName: 'Autorizzazione',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex:1,
        },
        {
          field: 'NomeZona',
          headerName: 'Zona',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex:1,
        },
        
            {
              field: 'Ordine',
              headerName: 'Ordine',
              headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
              flex:1,
            }
        ,
        {
            field: 'Azione',
            headerName: 'Azione',
            headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
           flex:1,
            renderCell: (params) => (
              <IconButton aria-label="delete" onClick={async () => {await setRigaSel(params.row);document.getElementById("newButtonCliente").click()}}>
                <Edit  sx={{color:"green"}}/>
              </IconButton>
        
            ),
            
          }
        ];



    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton sx={{color:"#047857"}}/>
            <GridToolbarFilterButton sx={{color:"#047857"}}/>
            <GridToolbarDensitySelector sx={{color:"#047857"}}/>
            <GridToolbarExport sx={{color:"#047857"}}/>
          </GridToolbarContainer  >
        );
      }
      


      const loadOrdineLavoratori = async() => {
        await api.get('ordineLavoratoriUtente', {params:{user:cookies.user.ID},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
            }})
            .then( res=> {
              console.log(zonaSel)
                setOrdineLavoratori(res.data.data.filter(ol=>  ol.Zona === zonaSel ));
                setOrdineLavoratoriBackup(res.data.data);
    
            })
          
    }
    

    useEffect(() => {
   
        const fetchData = async () =>{
          await api.get('zone', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
            setZone(res.data.data);
    
        })
        await api.get('ordineLavoratoriUtente', {params:{user:cookies.user.ID},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
      }})
      .then( res=> {
          setOrdineLavoratori(res.data.data.filter(ol=>  ol.Zona === 1 ));
          setOrdineLavoratoriBackup(res.data.data);
      
      })
      
    }
    fetchData();
    },[]);


const filterLavoratori = (idZona) =>{
let ordini = [...ordineLavoratoriBackup];
console.log(ordini)
let lav = ordini.filter(ol=>  ol.Zona === parseInt(idZona) );
console.log(lav)
setOrdineLavoratori(lav);

}


return (<div className=" h-4/5">
<select className="rounded-md" onChange={(e)=> {setZonaSel(parseInt(e.target.value));filterLavoratori(e.target.value)}} value={zonaSel}>
  {zone?.map((zona) => 

    <option key={zona.ID} value={zona.ID}>{zona.Zona}</option>

    )}
</select>
<ModalCliente   buttonText="Modifica Ordine" id="newButtonCliente" title="Modifica" cookies={cookies} elemento={rigaSel} classNameButton="new-btn" loadOrdineLavoratori={loadOrdineLavoratori}/>
<DataGrid
components={{
  Toolbar: CustomToolbar,
}}
  sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
  rows={ordineLavoratori}
  columns={columns}
  pageSize={100}
  rowsPerPageOptions={[100]}
  localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
  getRowId={row=> row.IDLavoratore}
/> 






</div> );

 }


 
 export default OrdinePanoramica


 const ModalCliente = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [lavoratoreSel,setLavoratoreSel] = useState(false);
    const [showMex,setShowMex] = useState(false);
    const handleEdit = (e) => {
        let l = {...lavoratoreSel};
        l[e.target.name] = e.target.value;
        setLavoratoreSel(l);
    }
    const handleSave = async () => {
    
      try{
        if(lavoratoreSel.ID !== null){
           
            let response= await api.put('ordineLavoratoriUtente',{ordine:lavoratoreSel},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
           
        }else{
          let ordine = {IDLavoratore:lavoratoreSel.IDLavoratore,Utente:props.cookies.user.ID,Ordine:lavoratoreSel.Ordine}



          let response= await api.post('ordineLavoratoriUtente',{ordine:ordine},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});

        }
        setShowModal(false)
        props.loadOrdineLavoratori();
      }catch{

        alert("ERRORE NEL SALVATAGGIO")
      }
      
       
    }
    const openModal = async() => {
     setLavoratoreSel(props.elemento)
        setShowModal(true);
        setShowMex(false)
    }


    return (
      <>
        <button
          id={props.id}
          className={props.classNameButton}
          type="button"
          onClick={async () => {openModal()}}
          style={{display:"None"}}
        >
          {props.buttonText}
        </button>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title} 
                    </h3>
               
                    
                    <button
                    
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div> 
                  {/*body*/}
                  <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
             
                   <label className="relative ">
                             <span className="text-gray-700">Nominativo</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={lavoratoreSel.Nominativo}
                                         name="RagSociale"
                                        readOnly
                                                                />
                            </label>
                    </div>
                    <div>
                    <label className="relative ">
                             <span className="text-gray-700">Ordine</span>
                                  <input
                                         type="number"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={lavoratoreSel.Ordine}
                                         name="Ordine"
                                        onChange={handleEdit}
                                                                />
                            </label>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="btn-footer-container">
                    <button
                      className="save-btn"
                      type="button"
                      onClick={handleSave}
                    >
                      Salva
                    </button>
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }  
