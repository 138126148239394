const TOKEN_KEY = 'jwt';


let myToken;
export const login = (utente,jwt,id) => {

    sessionStorage.setItem('utente', JSON.stringify(utente));
   sessionStorage.setItem('jwt', JSON.stringify(jwt));
   sessionStorage.setItem('ID', JSON.stringify(id));
   sessionStorage.setItem('logged', 'ok');
  /*  localStorage.setItem('fotoProfilo',"data:image/png;base64, "+ foto);*/
}
export const loginPersistant = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem('logged', 'ok');
}

export const logout = () => {
    localStorage.removeItem('logged');
    sessionStorage.removeItem('fotoProfilo');
   
}

export const isLogin = () => {
    if (sessionStorage.getItem('logged') || localStorage.getItem('logged')  ) {
        return true;
    }

    return false;
}

export const isAdmin = () => {
    if ( JSON.parse(localStorage.getItem("utente")).auth===1) {
        return true;
    }

    return false;
}






export const isToken = () =>{

    var b=true;
 /*  jwt.verify(localStorage.getItem('jwt'),'8d16ca57ad059f1a53ee300ea80fe94717db4204fe732d8d5eb4467d9604f362c2c7f850a198b4261770fca6155c902d06b4b4477d6a6e816ecc712de236e2d0',async (err, authData)=>{
       
        if(err){
            console.log("EEE");
            b= false;
        }else{
            console.log("UUU");
            b = true;
        }
  
  
    });

    const verify = jwt.verify(localStorage.getItem('jwt'),'8d16ca57ad059f1a53ee300ea80fe94717db4204fe732d8d5eb4467d9604f362c2c7f850a198b4261770fca6155c902d06b4b4477d6a6e816ecc712de236e2d0');
sessionStorage.setItem("username",verify.username);*/
    return b;
}


