import React,{useEffect,useState} from "react";
import DatePicker from '@mui/lab/DatePicker';
import { FormControl,InputLabel,Select,Chip,Box,OutlinedInput,MenuItem,IconButton } from "@mui/material";
import Holidays from 'date-holidays';
import moment from "moment";
import momentTimezone from "moment-timezone";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "moment/locale/it";
import api from "../../api/api";
import { useCookies } from "react-cookie";
import {Add,Info,Delete,Error,ContentCopy,AccessTime} from '@mui/icons-material';
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';







const OdlEsterno = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [cookies, setCookie] = useCookies(["user"]);
    const [openCar,setOpenCar] = useState(false);
    const [commesse,setCommesse] = useState([]);
    const [commesseOdierne,setCommesseOdierne] = useState([]);
    const [commesseCombobox,setCommesseCombobox] = useState([]);
    const [odl,setODL] =useState({IDCommessa:0,Commessa:"",DtaPresenza:props.date,Esterno:props.Dipendente,IDEsterno:props.IDDip,Note:""});
    const [odlLav,setODLLav] =useState({IDCommessa:0,Lavorazione:"",IDLav:0,CDC:"",IDCDC:0,IDMacchina:0,Macchina:"",Note:""});
    const [macchine,setMacchine] = useState([]);
    const [macchineCommessa,setMacchineCommessa] = useState([]);
    const [macchineCombobox,setMacchineCombobox] = useState([]);
    const [cdc,setCDC] = useState([]);
    const [lavorazioni,setLavorazioni] = useState([]);
    const [checkMacchine,setMacchineCheck] = useState(false);
    const [lavorazioniSalvate,setLavorazioniSalvate] = useState([]); //lavorazioni/macchina
    const [idLavorazioneSel,setIdLavorazioneSel] = useState(0);
  
  
  
  
      const columns = [
        { field: 'Macchina', headerName: 'Macchina', flex:1 },
        { field: 'Lavorazione', headerName: 'Lavorazione', flex:1 },
        { field: 'CDC', headerName: 'CDC', flex:1 },
        { field: 'Note', headerName: 'Note', flex:1 },]
  
  
  const handleOpen = async() => {
    setOpenCar(false);
    setLavorazioniSalvate([]);
    setIdLavorazioneSel(0);
    await api.get('commesse', {headers: {'Authorization': `Bearer ${cookies.user.jwt}` //commesse odierne
  },params:{date:props.date}})
  .then( res=> {
    setCommesseCombobox(res.data.data);
    setCommesseOdierne(res.data.data);
  
  })
  
  await api.get('commesse', {headers: {'Authorization': `Bearer ${cookies.user.jwt}` //commesse odierne
  }})
  .then( res=> {
    setCommesse(res.data.data);
  
  })
  
  
  await api.get('macchine', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{operazione:"ALL"}})
  .then( res=> {
    setMacchine(res.data.data); //popolo il dato che mi serve
  
  });
  
  await api.get('cdc', {headers: {'Authorization': `Bearer ${cookies.user.jwt}` //commesse odierne
  }})
  .then( res=> {
    setCDC(res.data.data);
  
  })
  
  
  
  
  
  
  if(props.operation==="new"){
    setODL({IDCommessa:props.commessa,DtaPresenza:props.date,Esterno:props.Dipendente,IDEsterno:props.IDDip,Note:""});
  }else{
  
    await api.get('odlLavEst', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
  },params:{id:props.idODL}})
  .then( res=> {
    setLavorazioniSalvate(res.data.data)
  })
  
  
  
    await api.get('odlEst', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
  },params:{id:props.idODL}})
  .then( res=> {
    setODL(res.data.data[0])
  })
  
  
  
  
  
  }
  
  
  
  
  
    setShowModal(true);
  
  }
  
  const handleCheck = async(e) => {
  if(e.target.checked){
    setCommesseCombobox(commesse);
  }else{
  
    setCommesseCombobox(commesseOdierne);
  
  }
  
  let o = {...odl};
  odl.IDCommessa=0;
  setODL(o);
  
  
  }
  
  const handleSave = async() => {
  
  
  if(odl.IDCommessa ==="0" ||odl.IDCommessa ===0 ){
    alert("ATTENZIONE SELEZIONARE ALMENO UNA COMMESSA");
    return;
  }
  if(props.operation ==="new"){
  try{
  
    let responseODLEst= await api.post('odlEst',{odl:odl},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
    console.log(responseODLEst.data.message.insertId)
    console.log(lavorazioniSalvate);
    let responseODLLavEst= await api.post('odlLavEst',{odlEst:responseODLEst.data.message.insertId,odlLavEst:lavorazioniSalvate},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }})
  
  
  
  
  
  alert("ODL inserito con successo");
  }catch{
    alert("Errore durante la creazione dell'odl per il dipendente selezionato");
  }
  
  
  
  }else{
    try{
  
    let responseODLDIP= await api.put('odlEst',{note:odl.Note,id:props.idODL,deleted:0},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }})
    alert("ODL modificato con successo");
  }catch{
    alert("Errore durante la creazione dell'odl per il dipendente selezionato");
  }
  
  }
  
  props.loadFunction(props.loadFunctionParams.da,props.loadFunctionParams.a,props.loadFunctionParams.c,props.loadFunctionParams.z,props.loadFunctionParams.s); //ricarico la tabellona
  }
  
  const handleOpenMacchine = async() => {
    setMacchineCheck(false);
    if(odl.IDCommessa ==="0" || odl.IDCommessa ===0){
      alert("Seleziona almena una commessa");
    }else{
      setODLLav({IDCommessa:odl.IDCommessa,Lavorazione:"",IDLav:0,CDC:"",IDCDC:0,IDMacchina:0,Macchina:"",Note:""})
    setOpenCar(true);
  
  
    }
  }
  
  const handleChangeCommessa = async(e) => {
  
    let index = e.target.selectedIndex;
  
    let o = {...odl};
    o.IDCommessa=e.target.value;
    o.Commessa = e.target[index].text;
    setODL(o);
  
    
    await api.get('macchine', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{operazione:"DETTAGLIO",IDCommessa:e.target.value,dataSel:props.date}})
    .then( res=> {
      setMacchineCommessa(res.data.data);
      setMacchineCombobox(res.data.data);

    });
  
  
  
  }
  
  const handleCheckMacchine = async(e) => {
    setMacchineCheck(e.target.checked)
    if(e.target.checked){
      setMacchineCombobox(macchine);
    }else{
    
      setMacchineCombobox(macchineCommessa);
    
    }
    
    /*let o = {...odl};
    odl.IDCommessa=0;
    setODL(o);*/
    
    
    }
  
    const handleChangeMacchina= async(e) => {
      let o = {...odlLav};
      var index = e.target.selectedIndex; //index del testo
      o.Macchina=e.target[index].text; //testo effettivo 
      o.IDMacchina=e.target.value;
      setODLLav(o);
    
      await api.get('lavorazioni', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{id:e.target.value}})
      .then( res=> {
        setLavorazioni(res.data.data);
      });
    
    
    
    }  
    
  const saveMacchine = async(e) => {
    if(props.operation==="new"){
  odlLav.ID = lavorazioniSalvate.length +1 ;
      setLavorazioniSalvate(attuali => [...attuali,odlLav]);
      alert("Lavorazione inserita con successo");
  
    }else{
      let responseODLLav= await api.post('odlLavEst',{odlEst:props.idODL,odlLavEst:[odlLav]},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }})
  
  
      await api.get('odlLavEst', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
    },params:{id:props.idODL}})
    .then( res=> {
      setLavorazioniSalvate(res.data.data)
    })
  
  alert("Lavorazione inserita con successo");
  
    }
    
  
  }
  
  const handleDelete = async(e) => {
  
  if(idLavorazioneSel === 0 || idLavorazioneSel ==="0"){
    alert("Attenzione seleziona almeno una lavorazione");
    return;
  }
  if (props.operation==="new"){
    let lav = lavorazioniSalvate.filter(item => item.ID !== idLavorazioneSel.ID);
   setLavorazioniSalvate(lav);
  }else{
  if(window.confirm("Sei sicuro di voler eliminare la lavorazione selezionata?")){
    try{
      let odlS = idLavorazioneSel;
      odlS.Deleted=1;
  await api.put('odlLavEst',{odl:odlS},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  
    alert("Lavorazione eliminata con successo")
  
    await api.get('odlLavEst', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{id:props.idODL}})
  .then( res=> {
    setLavorazioniSalvate(res.data.data)
  })
  
    }catch{
      alert("Errore durante l'eliminazione");
    }
  
  }
  }
  
  
  }
  
  
  
    return (
      <>
      { props.operation==="new"?
      <span style={{float:'right'}}><Add className="text-xl text-emerald-700 hover:text-emerald-600 cursor-pointer" onClick={handleOpen} /></span>:
      <span style={{float:'right'}}><Info className="text-xl text-emerald-700 hover:text-emerald-600 cursor-pointer" onClick={handleOpen} /></span>
  }
        {/*<button
          className="ml-10 w-1/4 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md"
          type="button"
          onClick={async () => {setShowModal(true)}}
        >
         {props.buttonText}
    </button>*/}
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-full my-6 mx-auto max-w-7xl">
                {/*content*/}
                <div className="border-0 rounded-lg p-2 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                    <h5 className="flex w-full justify-center text-xl font-semibold">
                    {props.title}
                    </h5>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex shadow-lg rounded-md border-slate-200/50 border-2" style={{display:openCar?"None":""}}>
               
                  <div className="flex flex-col w-1/3 p-6" >
                      <br/>
                      <label className="flex flex-row items-center mb-1">
                        <span className="text-gray-700 w-2/5">Commessa</span>
                        <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md" disabled={props.operation==="edit"} value={odl.IDCommessa} onChange={handleChangeCommessa}> 
                          <option value="0" >Seleziona...</option>
                          { commesseCombobox?.map(commessa => <option value={commessa.id} key={commessa.id}>{commessa.NomeCommessa}</option>) }
                        </select>
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Da fare</span> 
                       <textarea onChange={(e)=>{let o = {...odl}; o.Note=e.target.value; setODL(o);}} type="text" className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full" value={odl.Note} />
                      </label>
                      {/* <button onClick={handleSave} className="mt-10 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md self-center  w-full disabled:opacity-75 disabled:hover:bg-emerald-700" type="button">Salva</button> */}
                  </div>
                    <div className="flex flex-col w-2/3">
                      <div className="flex flex-row-reverse gap-1">                  
                      
                    <button className="mb-1 bg-red-700 hover:bg-red-600 text-white p-1 rounded-md self-center  disabled:opacity-75 disabled:hover:bg-red-700" onClick={handleDelete} >Elimina Macchina</button>
                    <button className="mb-1 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md self-center  disabled:opacity-75 disabled:hover:bg-emerald-700" onClick={handleOpenMacchine}>Aggiungi Macchina</button>
                    </div>
  
                   <DataGrid
              sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
            
                  rows={lavorazioniSalvate}
                  getRowId={(row =>row.ID )}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[10]}
                  localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = lavorazioniSalvate.filter((row) =>
                    selectedIDs.has(row.ID),
                    );
                  if(selectedRowData.length>0){
                    setIdLavorazioneSel(selectedRowData[0]);
                  }
                }}
                  
                />
                    </div>
                 
                  </div>
                  {/* SPAZIO INSERIMENTO MACCHINE */}
  
  
                  <div className="relative p-6 flex justify-center"  style={{display:openCar?"":"None"}}>
                    <div className="flex flex-col w-full">
                    <div class="flex items-center justify-end gap-x-4">
                    <button className="mb-1 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md   disabled:opacity-75 disabled:hover:bg-emerald-700" onClick={() => setOpenCar(false)}>Indietro</button>
                    </div>
                    <label className="inline-flex items-center mt-3" >
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-emerald-600"  onChange={handleCheckMacchine} checked={checkMacchine} /><span className="ml-2 text-gray-700" >Visualizza tutte le macchine </span>
                      </label>
                  <label className="flex items-center mb-1 w-3/4">
                        <span className="text-gray-700 w-2/5">Macchina</span>
                        <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1"  value={odlLav.IDMacchina} onChange={handleChangeMacchina}> 
                          <option value="0" >Seleziona...</option>
                          { macchineCombobox?.map(mac => <option value={mac.ID} key={mac.ID}>{mac.Nome}</option>) }
                        </select>
                      </label>
  
                      <label className="flex items-center mb-1 w-3/4">
                        <span className="text-gray-700 w-2/5">lavorazione</span>
                        <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1"  value={odlLav.IDLav} onChange={(e)=>{
                           let o = {...odlLav};
                            var index = e.target.selectedIndex; //index del testo
                            o.Lavorazione=e.target[index].text; //testo effettivo 
                            o.IDLav=e.target.value;
                            setODLLav(o);
                        }}> 
                          <option value="0" >Seleziona...</option>
                          { lavorazioni?.map(lav => <option value={lav.ID} key={lav.ID} >{lav.Descrizione}</option>) }
                        </select>
                      </label>
                      <label className="flex items-center mb-1 w-3/4">
                        <span className="text-gray-700 w-2/5">Materiale</span>
                        <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1" value={odlLav.IDCDC} onChange={(e)=>{
                          let o = {...odlLav};
                          var index = e.target.selectedIndex; //index del testo
                          o.CDC=e.target[index].text; //testo effettivo 
                          o.IDCDC=e.target.value;
                          setODLLav(o);
                        }}> 
                          <option value="0" >Seleziona...</option>
                          { cdc?.map(materiale => <option value={materiale.ID} key={materiale.ID}>{materiale.CDC}</option>) }
                        </select>
                      </label>
  
                      <label className="relative ">
                        <span className="text-gray-700">Da fare</span> 
                       <textarea onChange={(e)=>{let o = {...odlLav}; o.Note=e.target.value; setODLLav(o);}} type="text" className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full" value={odlLav.Note} />
                      </label> 
  
                      <button className="mt-5 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md   disabled:opacity-75 disabled:hover:bg-emerald-700" onClick={saveMacchine}>Salva</button>
  
  
                      </div>
  
                  </div>
  
  
                  {/*footer*/}
                  <div 
                    className="flex items-center justify-between p-6 border-solid border-slate-200 rounded-b"
                    
                  >
                    <button 
                      className="px-6 py-2 flex border-emerald-700 border-2 bg-emerald-700 hover:border-emerald-600 hover:bg-emerald-600 font-bold text-white uppercase p-1 rounded-md disabled:opacity-75 disabled:hover:bg-emerald-700" 
                      onClick={handleSave}
                    >
                      Salva
                    </button>
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {setShowModal(false)}}
                    >
                      Chiudi
                    </button>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  } 

  export default OdlEsterno