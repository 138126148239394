import React,{useEffect,useState} from "react";
import DatePicker from '@mui/lab/DatePicker';
import { FormControl,InputLabel,Select,Chip,Box,OutlinedInput,MenuItem,IconButton } from "@mui/material";
import Holidays from 'date-holidays';
import moment from "moment";
import momentTimezone from "moment-timezone";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "moment/locale/it";
import api from "../api/api";
import { useCookies } from "react-cookie";
import {Add,QuestionMark,Delete,Error,ContentCopy,AccessTime, InfoOutlined} from '@mui/icons-material';
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import TrattaTrasportatore from "./PanoramicaAggiuntivi/TrattaTrasportatore";
import Tratta from "./PanoramicaAggiuntivi/Tratta";
import OdlEsterno from "./PanoramicaAggiuntivi/OdlEsterno";
import Ordine from "./PanoramicaAggiuntivi/Ordine";
import Odl from "./PanoramicaAggiuntivi/Odl";

const Panoramica = () => {
     let  header = [];
     const weekday = ["Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"];
     const feste = new Holidays("IT");
    const [dataSel,setDataSel] = useState(new Date());
    //const [dataA, setA] = useState(moment(dataSel).weekday(6));
    //const [dataDa, setDa] = useState(moment(dataSel).weekday(0));
    const [dataA, setA] = useState(moment(dataSel));
    const [dataDa, setDa] = useState(moment(dataSel));
    const [zone,setZone] =  useState([]);
    const [panoramica,setPanoramica] = useState([]);
    const [zonaSel,setZonaSel] = useState(1);
    const [societaSel,setSocietaSel] = useState([]);
    const [categoria,setCategoria] = useState("Tutti");
    const [cookies, setCookie] = useCookies(["user"]);
    const [societa,setSocieta] = useState([]);
    const [societaSelPRova,setSocietaSelProva] = useState([]);
    const [dataCopy,setDataCopy] = useState(new Date());
    const [lavoratoreSel,setLavoratoreSel] = useState(0);

const manageDataSel = async (value) => {
  setPanoramica([]);
let d = dataSel;
value==="skip"?d.setDate(d.getDate() +1):d.setDate(d.getDate() -1);
await setDataSel(d);
//setA(moment(d).weekday(6));
//setDa(moment(d).weekday(0));
setA(d);
setDa(d);
loadPanoramica(moment(d).format("YYYY-MM-DD"),moment(d).format("YYYY-MM-DD"),categoria,zonaSel,societaSel);
}

const manageHeaderODL = () => {
  let th = [];
  for (let i = 0; i <panoramica.totMaxInterventi; i++) {
    th.push(<th className="px-6 py-3 text-left text-xs bg-emerald-700 text-white font-medium uppercase tracking-wider" key={i}>ODL {i+1}</th>);
  }
  return th;
 
}

const manageHeaderTratte = () => {
  let th = [];
  for (let i = 0; i <panoramica.totMaxInterventi; i++) {
    th.push(<th className="px-6 py-3 text-left text-xs bg-emerald-700 text-white font-medium uppercase tracking-wider" key={i}>Tratta {i+1}</th>);
  }
  return th;
 
}

const loadPanoramica = async(da,a,categoria,zona,societa) => {
  setPanoramica([]);
  
  await api.get('panoramica', {headers: {
    'Authorization': `Bearer ${cookies.user.jwt}`
},params:{datainizio:da,dataFine:a,categoria:categoria,zona:zona,societa:societa,utente:cookies.user.ID}})
.then( res=> {
  setPanoramica(res.data.result);
  
})
}

const deleteTratta = async(id) => {
  


  if(window.confirm("Sei sicuro di voler eliminare la tratta selezionata?")){
    try{
      await api.put('odlTratte',{id:id},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
    alert("Tratta eliminata con successo");
    }catch{
      alert("ERRORE DURANTE L'ELIMINAZIONE");
    }
  }
 
  loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),categoria,zonaSel,societaSel)
  


}

const deleteTrattaTrasportatori = async(id) => {
  


  if(window.confirm("Sei sicuro di voler eliminare la tratta selezionata?")){
    try{
      await api.put('odlTratteTrasportatori',{id:id},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
    alert("Tratta eliminata con successo");
    }catch{
      alert("ERRORE DURANTE L'ELIMINAZIONE");
    }
  }
  
  loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),categoria,zonaSel,societaSel)
  


}

const deleteODL = async(id) =>{
if(window.confirm("Sei sicuro di voler eliminare l'ODL selezionato?")){
  try{
  await api.put('odlDip',{id:id,deleted:1},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }})
  alert("ODL eliminato con successo");
  }catch{
    alert("ERRORE DURANTE L'ELIMINAZIONE");
  }
}

loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),categoria,zonaSel,societaSel)

}

const deleteODLEsterno = async(id) =>{
  if(window.confirm("Sei sicuro di voler eliminare l'ODL selezionato?")){
    try{
    await api.put('odlEst',{id:id,deleted:1},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }})
    alert("ODL eliminato con successo");
    }catch{
      alert("ERRORE DURANTE L'ELIMINAZIONE");
    }
  }
  
  loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),categoria,zonaSel,societaSel)
  
  }

useEffect(() => {
  
  var hd = new Holidays("IT");
    const fetchData = async () =>{
         await api.get('zone', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
    }})
    .then( res=> {
        setZone(res.data.data);

    })
    
    await api.get('societa', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
    }})
    .then( res=> {
        setSocieta(res.data.data);

    })
    
    loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),"Tutti",1,[])
  /*  await api.get('panoramicaProva', {headers: {
      'Authorization': `Bearer ${cookies.user.jwt}`
  },params:{datainizio:moment(dataDa).format("YYYY-MM-DD"),dataFine:moment(dataA).format("YYYY-MM-DD"),categoria:"Tutti",zona:1}})
  .then( res=> {
    setPanoramica(res.data.result);
   

  })*/

}
fetchData();
},[]);




const openModal = () =>{
  
  document.getElementById("mainContent").classList.add("opacity-50");
  document.getElementById("modal").classList.remove("hidden");
  console.log(dataDa);
  //devo passare le info necessarie zona e datasel per il copia
}

const openModalSingolo = (idLavoratore) =>{
  
  document.getElementById("mainContent").classList.add("opacity-50");
  document.getElementById("modalSingolo").classList.remove("hidden");
  setLavoratoreSel(idLavoratore);
  //devo passare le info necessarie zona e datasel per il copia
}

const copyOdl = async(dataPrecedente,dataCorrente) =>{



  if(window.confirm("Sei sicuro di voler copiare gli odl del "+moment(dataDa).format("DD/MM/YYYY")+" nella giornata del "+moment(dataCopy).format("DD/MM/YYYY")+"?")){
    try{
    let res = await api.post('copyOdl',{dataPrecedente:moment(dataDa).format("YYYY-MM-DD"),dataSelezionata:moment(dataCopy).format("YYYY-MM-DD"),societa:societaSel,zona:zonaSel},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }})
    if(res.data.data ===""){
      alert("Copia completata con successo")
    }else{
      alert("Attenzione i seguenti ODL non sono stati copiati in quanto la commessa non è attiva nel giorno corrente:\n" + res.data.data)
    }
    }catch{
      alert("ERRORE DURANTE LA COPIA");
    }
    loadPanoramica(moment(dataCopy).format("YYYY-MM-DD"),moment(dataCopy).format("YYYY-MM-DD"),categoria,zonaSel,societaSel)
  } 
  
 setDa(dataCopy);
 setA(dataCopy);
 setDataSel(dataCopy);
  document.getElementById("mainContent").classList.remove("opacity-50");
  document.getElementById("modal").classList.add("hidden");
  
  }

const copyOdlSingolo = async() =>{
  if(window.confirm("Sei sicuro di voler copiare gli odl del lavoratore selezionato in data "+moment(dataDa).format("DD/MM/YYYY")+" nella giornata del "+moment(dataCopy).format("DD/MM/YYYY")+"?")){
    try{
    let res = await api.post('copyOdl',{dataPrecedente:moment(dataDa).format("YYYY-MM-DD"),dataSelezionata:moment(dataCopy).format("YYYY-MM-DD"),societa:societaSel,zona:zonaSel,idLavoratore:lavoratoreSel},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }})
    if(res.data.data ===""){
      alert("Copia completata con successo")
    }else{
      alert("Attenzione i seguenti ODL non sono stati copiati in quanto la commessa non è attiva nel giorno corrente:\n" + res.data.data)
    }
    }catch{
      alert("ERRORE DURANTE LA COPIA");
    }
    setDa(dataCopy);
    setA(dataCopy);
    setDataSel(dataCopy);
    loadPanoramica(moment(dataCopy).format("YYYY-MM-DD"),moment(dataCopy).format("YYYY-MM-DD"),categoria,zonaSel,societaSel)
  } 
  
 
  document.getElementById("mainContent").classList.remove("opacity-50");
  document.getElementById("modalSingolo").classList.add("hidden");
    

    }


const annulla = () => {

  document.getElementById("mainContent").classList.remove("opacity-50")
  document.getElementById("modal").classList.add("hidden")

}



    const handleChangeSelect = (event) => {
      const {
        target: { value },
      } = event;
      setSocietaSel(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),categoria,zonaSel,event.target.value)

    };
return (<>
  <div id="modal" className="flex flex-wrap max-w-[600px] flex-column justify-center bg-white px-16 py-14 rounded-md text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 border border-black-600 hidden" >
   <h1 className="text-xl mb-4 font-bold text-slate-500 w-full">Seleziona una data dove copiare questa giornata</h1>

    <LocalizationProvider dateAdapter={DateAdapter} >
<DatePicker 
value={dataCopy}
onChange={(newValue) => {
   setDataCopy(newValue);

}}
    renderInput={({ inputRef, inputProps, InputProps }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <input className=" mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-emerald-700" ref={inputRef} {...inputProps} />
      {InputProps?.endAdornment}
    </Box>
  )} />
</LocalizationProvider>
<div className="flex items-center justify-between p-6 border-solid border-slate-200 rounded-b w-full">
    <button className="close-btn" onClick={annulla}>Annulla</button>
    <button className="save-btn" onClick={() => copyOdl("singolo")} >Copia  </button>
    </div>   
  </div>

  <div id="modalSingolo" className="flex flex-wrap max-w-[600px] flex-column justify-center bg-white px-16 py-14 rounded-md text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 border border-black-600 hidden" >
   <h1 className="text-xl mb-4 font-bold text-slate-500 w-full">Seleziona una data dove copiare gli odl</h1>

    <LocalizationProvider dateAdapter={DateAdapter} >
<DatePicker 
value={dataCopy}
onChange={(newValue) => {
   setDataCopy(newValue);

}}
    renderInput={({ inputRef, inputProps, InputProps }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <input className=" mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-emerald-700" ref={inputRef} {...inputProps} />
      {InputProps?.endAdornment}
    </Box>
  )} />
</LocalizationProvider>
<div className="flex items-center justify-between p-6 border-solid border-slate-200 rounded-b w-full">
    <button className="close-btn" onClick={annulla}>Annulla</button>
    <button className="save-btn" onClick={copyOdlSingolo} >Copia  </button>
    </div>   
  </div>



<div id="mainContent">
    <div className="flex flex-row space-x-4"> {/*Riga con filtri vari */}
    <button className="bg-emerald-700 hover:bg-emerald-600 text-white font-bold p-1 rounded-lg w-10" onClick={() => manageDataSel("before")}> {"<"} </button>


<LocalizationProvider dateAdapter={DateAdapter} >
<DatePicker 
value={dataDa}
onChange={(newValue) => {
   setDa(newValue);
   setA(newValue);
   setDataSel(new Date(newValue));
   loadPanoramica(moment(newValue).format("YYYY-MM-DD"),moment(newValue).format("YYYY-MM-DD"),categoria,zonaSel,societaSel)
}}
    renderInput={({ inputRef, inputProps, InputProps }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <input className=" mt-0
                    block
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-emerald-700" ref={inputRef} {...inputProps} />
      {InputProps?.endAdornment}
    </Box>
  )} />
</LocalizationProvider>
<LocalizationProvider dateAdapter={DateAdapter}  >
<DatePicker 
value={dataA}
onChange={(newValue) => {
   setA(newValue);

}}
    renderInput={({ inputRef, inputProps, InputProps }) => (
    <Box sx={{ display: 'none', alignItems: 'center' }}>
      <input className="mt-0 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700" ref={inputRef} {...inputProps} />
      {InputProps?.endAdornment}
    </Box>
  )} />
</LocalizationProvider>


<button onClick={() => manageDataSel("skip")} className="bg-emerald-700 hover:bg-emerald-600 text-white font-bold p-1 rounded-lg w-10">{">"}</button> 
<select value={categoria} className="rounded-md" onChange={(e)=> {setCategoria(e.target.value); loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),e.target.value,zonaSel,societaSel)}}>
<option value="Tutti">Tutti</option>
<option value="Autisti">Autisti</option>
<option value="Operatori">Operatori</option>
</select>

<select className="rounded-md" onChange={(e)=> {setZonaSel(e.target.value); loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),categoria,e.target.value,societaSel)}}>
  {zone?.map((zona) => 

    <option key={zona.ID} value={zona.ID}>{zona.Zona}</option>

    )}
</select>
<FormControl sx={{ width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Società</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={societaSel}
          onChange={handleChangeSelect}
          input={<OutlinedInput id="select-multiple-chip" label="Società" />}
        >
          {societa.map((soc) => (
            <MenuItem
              key={soc.ID}
              value={soc.ID}

            >
              {soc.RagioneSociale}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
{/*<select className="rounded-md" onChange={(e)=> {setSocietaSel(e.target.value); loadPanoramica(moment(dataDa).format("YYYY-MM-DD"),moment(dataA).format("YYYY-MM-DD"),categoria,zonaSel,e.target.value)}}>
  {societa?.map((soc) => 

    <option key={soc.ID} value={soc.ID}>{soc.RagioneSociale}</option>

    )}
  </select>*/}



<TrattaTrasportatore operation="new" date={moment(dataDa).format("YYYY-MM-DD")} da={moment(dataDa).format("YYYY-MM-DD")} a={moment(dataA).format("YYYY-MM-DD")} loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}}></TrattaTrasportatore>



</div>
<div className="mt-10">

<table className="table-fixed divide-y w-full  mb-10 divide-gray-200 border-x-2 border-b-2  border-slate-300 myTable">

<tr>
 <th className="px-6 py-3 text-left text-xs bg-emerald-700 text-white font-medium uppercase tracking-wider">Giorno</th> 

{(() => manageHeaderODL())()}
{/*(() => manageHeaderTratte())()*/}
</tr>
<tbody>

{
 panoramica.odl?
 Object.keys(panoramica.odl).map((day,index) =>{
  let blocco = [];  
  const d = new Date(day);
  let dBefore = new Date(day);
  dBefore = dBefore.setDate(dBefore.getDate() -1);
  dBefore = moment(dBefore).format("YYYY-MM-DD")
  let daySplit = day.split("-");
  let max = panoramica.totMaxInterventi +1


let classDate = "bg-emerald-600 text-white";
  if(feste.isHoliday(new Date(day)) || weekday[d.getDay()]==="Domenica" ||  weekday[d.getDay()]==="Sabato"){
    classDate="bg-red-600 text-white"
  } 

blocco.push(<tr  className={classDate}  key={day}><td colSpan={max}><b>{weekday[d.getDay()] +" - " +daySplit[2] +" - " +daySplit[1] +" - "+daySplit[0]}</b> <button className="ml-10 bg-emerald-700 hover:bg-emerald-600 text-white p-2 cursor-pointer rounded-lg" onClick={openModal} style={{visibility: weekday[d.getDay()]==="Domenica" ||  weekday[d.getDay()]==="Sabato" ? "hidden":""}}>Copia questa giornata</button></td></tr>);
let testaOperatori = false;
let testaAutisti = false;
//lavoratori
for(let j=0;j< panoramica.odl[day].Lavoratori.length;j++){
  let lav = panoramica.odl[day].Lavoratori[j];
  if (lav.Autorizzazione ===4 && !testaOperatori){
    blocco.push(<tr key={"testaOP" + index}><td colSpan={max}><b>Operatori</b></td></tr>);
    testaOperatori=true;
  }

  if (lav.Autorizzazione ===5 && !testaAutisti){
    blocco.push(<tr key={"testAut" + index}><td colSpan={max}><b>Autisti</b></td></tr>);
    testaAutisti=true;
  }





  if(lav){ //Riga lavoratore normale
    let style;

    if(lav.Ferie ===1){ //ferie
      style={backgroundColor:"#00b347"}
    }
    if(lav.Ferie ===2){ //malattia
      style={backgroundColor:"orange"}
    }
   let rowContent =[<td style={style}>{lav.Nominativo} {lav.Autorizzazione===4?<><Odl loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} Dipendente={lav.Nominativo} IDDip={lav.ID} operation="new" date={day} title={"Crea ODL : "+daySplit[2]+"-"+daySplit[1]+"-"+daySplit[0]+" - "+lav.Nominativo} /><span style={{float:'right'}}><ContentCopy className="text-emerald-700 hover:text-emerald-600 cursor-pointer" onClick={()=> openModalSingolo(lav.ID)} style={{display:weekday[d.getDay()]==="Domenica"?"None":""}}/></span><Ordine loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} date={day} odl={lav.ODL} title={"Riordino interventi di "+lav.Nominativo+ " Data : "+day} aut={lav.Autorizzazione} Dipendente={lav.Nominativo} IDDip={lav.ID} /></>:<><Tratta operation="new" idLavoratore={lav.ID} Lavoratore={lav.Nominativo} date={day} loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} title={"Crea Tratta : "+daySplit[2]+"-"+daySplit[1]+"-"+daySplit[0]+" - "+lav.Nominativo}/><Ordine loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} date={day} odl={lav.ODL} title={"Riordino interventi di "+lav.Nominativo+ " Data : "+day} aut={lav.Autorizzazione} Dipendente={lav.Nominativo} IDDip={lav.ID} /></>}<span style={{float:'right',display:lav.Ferie ===1 ||lav.Ferie ===2?"":"None"}}><Tooltip title={lav.Ferie ===1?"Ferie":"Malattia"}><InfoOutlined className="text-xl text-emerald-700 hover:text-emerald-600 cursor-pointer"/></Tooltip></span> </td>] ;
   //popolo gli odl

  if(lav.Autorizzazione===4){
    
    for(let k=0;k<lav.ODL.length;k++){
    
      rowContent.push(<td>{lav.ODL[k].NomeCommessa}<span style={{float:'right'}}><Delete className="text-red-700 hover:text-red-600 cursor-pointer" onClick={()=>deleteODL(lav.ODL[k].IdODL)} /></span> <Odl idODL={lav.ODL[k].IdODL} note={lav.ODL[k].Note?lav.ODL[k].Note:""} loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} Dipendente={lav.Nominativo} IDDip={lav.ID} operation="edit" date={day} title={"Modifica ODL : "+lav.ODL[k].NomeCommessa+" / "+daySplit[2]+"-"+daySplit[1]+"-"+daySplit[0]+" - "+lav.Nominativo} /></td>)
    }
    for(let k=lav.ODL.length;k<panoramica.totMaxInterventi;k++){
      rowContent.push(<td></td>)
    }
  }else if(lav.Autorizzazione===5){
    for(let k=0;k<lav.Tratte.length;k++){

      rowContent.push(<td>{lav.Tratte[k].tratta}   <span style={{float:'right'}}><Delete className="text-red-700 hover:text-red-600 cursor-pointer" onClick={()=>deleteTratta(lav.Tratte[k].idTratta)} /></span><Tratta operation="edit" idTratta={lav.Tratte[k].idTratta} anteprimaTratta={lav.Tratte[k]} loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} Lavoratore={lav.Nominativo} idLavoratore={lav.ID} date={day} title={"Crea ODL : "+daySplit[2]+"-"+daySplit[1]+"-"+daySplit[0]+" - "+lav.Nominativo} /></td>)
    }

    for(let k=lav.Tratte.length;k<panoramica.totMaxInterventi;k++){
      rowContent.push(<td></td>)
    }
  }


  

 
//popolo le tratte


  
    






   
    let row = <tr>{rowContent}</tr>





    blocco.push(row);
  }
}


//Trasportatori
if(panoramica.odl[day].Trasportatori.length>0){
  blocco.push(<tr key={"trasp"+index}><td colSpan={max}><b>Trasportatori</b></td></tr>)
}


for(let j=0;j< panoramica.odl[day].Trasportatori.length;j++){
  let trasp = panoramica.odl[day].Trasportatori[j];
  console.log(panoramica.odl[day])
  if(trasp){

    let rowContentTrasp =[<td>{trasp.Nominativo}</td>] ;


//popolo le tratte 


for(let k=0;k<trasp.ODL.length;k++){

  rowContentTrasp.push(<td>{trasp.ODL[k].tratta}   <span style={{float:'right'}}><Delete className="text-red-700 hover:text-red-600 cursor-pointer" onClick={()=>deleteTrattaTrasportatori(trasp.ODL[k].idTratta)} /></span><TrattaTrasportatore operation="edit" idTratta={trasp.ODL[k].idTratta} loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} Lavoratore={trasp.Nominativo} idLavoratore={trasp.ID} date={day} title={"Modifica Tratta : "+daySplit[2]+"-"+daySplit[1]+"-"+daySplit[0]+" - "+trasp.Nominativo} da={moment(dataDa).format("YYYY-MM-DD")} a={moment(dataA).format("YYYY-MM-DD")}  /></td>)
}
for(let k=trasp.ODL.length;k<panoramica.totMaxInterventi;k++){
  rowContentTrasp.push(<td></td>)
}

    let row = <tr>{rowContentTrasp}</tr>


    blocco.push(row);

  }

}





//Esterni
if(panoramica.odl[day].Esterni.length>0){
  blocco.push(<tr key={"EST"+index}><td colSpan={max}><b>Esterni</b></td></tr>)
}

for(let j=0;j< panoramica.odl[day].Esterni.length;j++){
let est = panoramica.odl[day].Esterni[j];
if(est){

  let rowContentEst=[<td>{est.Nominativo} <OdlEsterno  commessa={est.Commessa} loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} Dipendente={est.Nominativo} IDDip={est.ID} operation="new" date={day} title={"Crea ODL Esterno: "+daySplit[2]+"-"+daySplit[1]+"-"+daySplit[0]+" - "+est.Nominativo} /></td>] ;
//popolo gli odl
  for(let k=0;k<est.ODL.length;k++){

    rowContentEst.push(<td>{est.ODL[k].Commessa} <span style={{float:'right'}}><Delete className="text-red-700 hover:text-red-600 cursor-pointer" onClick={()=>deleteODLEsterno(est.ODL[k].ID)} /></span> <OdlEsterno idODL={est.ODL[k].ID} loadFunction={loadPanoramica} loadFunctionParams={{da:moment(dataDa).format("YYYY-MM-DD"),a:moment(dataA).format("YYYY-MM-DD"),c:categoria,z:zonaSel,s:societaSel}} Dipendente={est.Nominativo} IDDip={est.ID} operation="edit" date={day} title={"Modifica ODL Esterno: "+est.ODL[k].NomeCommessa+" / "+daySplit[2]+"-"+daySplit[1]+"-"+daySplit[0]+" - "+est.Nominativo} /></td>)
  }
  

  //popolo le tratte che per loro sono 0

  for(let k=0;k<panoramica.totMaxInterventi;k++){
    rowContentEst.push(<td></td>)
  }

  let row = <tr>{rowContentEst}</tr>
  blocco.push(row);
}
}


return blocco;


 }): <center>  <LinearProgress color="success" /></center>


}
</tbody>
</table>



</div>


</div></>)

}

export default Panoramica;











