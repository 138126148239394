import React, { useState, useEffect } from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";

const Societa = () => {
  const [societa, setSocieta] = useState([]);
  const [selectedSoc, setSelectedSoc] = useState(0);
  const [cookies, setCookie] = useCookies(["user"]);

  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "RagioneSociale",
      headerName: "Ragione Sociale",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 200
    },
    {
      field: "PIVA",
      headerName: "P.IVA",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "CF",
      headerName: "CF",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Citta",
      headerName: "Citta",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Cap",
      headerName: "Cap",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Provincia",
      headerName: "Provincia",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Indirizzo",
      headerName: "Indirizzo",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
    {
      field: "Colore",
      headerName: "Colore",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      width: 150,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{color:"#047857"}}/>
        <GridToolbarFilterButton sx={{color:"#047857"}}/>
        <GridToolbarDensitySelector sx={{color:"#047857"}}/>
        <GridToolbarExport sx={{color:"#047857"}}/>
      </GridToolbarContainer  >
    );
  }



  const handleDeleteButtonClick = async () => {
    if (selectedSoc === 0) {
      alert("Seleziona almeno una Società");
    } else {
      if (
        window.confirm(
          "Sei sicuro di voler eliminare la Società selezionato?"
        )
      ) {
        try {
          let tra = selectedSoc;
          tra.Deleted = 1;
          await api.put(
            "societa",
            { societa: tra },
            { headers: { Authorization: `Bearer ${cookies.user.jwt}` } }
          );

          alert("Società eliminato con successo");
          window.location.reload(); //refresho
        } catch {
          alert("ERRORE DURANTE L'ELIMINAZIONE");
        }
      }
    }
  };
  const loadSocieta = async () => {
    
      await api.get('societa', {headers: {'Authorization': `Bearer ${cookies.user.jwt}` //Società
    }})
    .then( res=> {
        setSocieta(res.data.data);
    })
  };

  useEffect(() => {
    const fetchData = async () => {
      loadSocieta();
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="flex m-0 justify-end items-center w-full space-x-2 mb-4">
        <WatchSocieta
          myFunction={loadSocieta}
          cookies={cookies}
          buttonText="Nuovo"
          id="newButtonSocieta"
          title="Nuova società"
          operation="new"
          classNameButton="new-btn"
        />

        <WatchSocieta
          myFunction={loadSocieta}
          cookies={cookies}
          buttonText="Modifica"
          selectedSoc={selectedSoc}
          id="editSocieta"
          title="Modifica società"
          operation="edit"
          classNameButton="new-btn"
        />

        <button className="delete-btn" onClick={handleDeleteButtonClick}>
          Elimina
        </button>
      </div>
      <div className=" h-4/5">
      {/* Table Container */}
      <DataGrid
      components={{
        Toolbar: CustomToolbar,
      }}
        sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
        rows={societa}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.ID}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = societa.filter((row) =>
            selectedIDs.has(row.ID)
          );

          setSelectedSoc(selectedRowData[0]);
        }}
        onRowDoubleClick={async (params) => {
          await setSelectedSoc(params.row);
          document.getElementById("editSocieta").click();
        }}
      />
    </div>
    </>
  );
};

export default Societa;

const WatchSocieta = (props) => {
  const [showTra, setShowTra] = useState(false);
  const [societa, setSocieta] = useState({
    RagioneSociale: "",
    PIVA: "",
    CF: "",
    Citta: "",
    Cap: "",
    Provincia: "",
    Indirizzo: "",
    Logo: "",
    Colore: "",
  });

  const handleEdit = (e) => {
    let tra = { ...societa };
    tra[e.target.name] = e.target.value;
    setSocieta(tra);
  };

  const handleSave = async () => {
    if (props.operation === "new") {
      try {
        let responseSocieta = await api.post(
          "societa",
          { societa: societa },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Societa inserito con successo");
        props.myFunction();
        setShowTra(false);
      } catch {
        alert("Errore durante la creazione del nuovo societa");
      }
    } else {
      try {
        let responseSocieta = await api.put(
          "societa",
          { societa: societa },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Societa modificato con successo");
        props.myFunction();
        setShowTra(false);
      } catch {
        alert("Errore durante la modifica del nuovo societa");
      }
    }
  };

  const openTra = async () => {
    if (props.operation === "new") {
      setSocieta({
        RagioneSociale: "",
        PIVA: "",
        CF: "",
        Citta: "",
        Cap: "",
        Provincia: "",
        Indirizzo: "",
        Logo: "",
        Colore: "",
      });
    } else {
      setSocieta(props.selectedSoc);
    }
    setShowTra(true);
  };
  const handleImage = (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      document.getElementById("firmaFormatore").src = reader.result;

      let f = { ...societa };
      let foto = reader.result.replace("data:image/jpeg;base64,", "");
      foto = foto.replace("data:image/png;base64,", "");
      f.Logo = foto;
      setSocieta(f);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <>
      <button
        id={props.id}
        className="new-btn"
        onClick={async () => {
          openTra();
        }}
      >
        {props.buttonText}
      </button>
      {showTra ? (
        <>
          <div className="m-0 justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                  </h3>
                  <button
                    className="p-1 bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowTra(false)}
                  >
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                    {/* Images Cotainer */}
                    <div className="grid gap-4">
                        {/* New Image */}
                        <div
                        className="flex-col w-full justify-center mt-2"
                        style={{
                            display: props.operation === "new" ? "flex" : "none",
                        }}
                        >
                        <div
                            id="firmaFormatore"
                            className="flex w-1/2 h-[200px] mx-auto outline-dotted outline-gray-500 rounded-xl"
                        >
                            <img
                            src={
                                societa.Logo !== "" && societa.Logo
                                ? "data:image/png;base64," + societa.Logo
                                : require("../../assets/TecnogardenService.png")
                            }
                            id="firmaFormatore"
                            alt="img"
                            className="flex h-full max-h-[200px] mx-auto"
                            />
                        </div>
                        <input
                            accept="image/*"
                            id="file"
                            name="Firma"
                            multiple
                            type="file"
                            onChange={(e) => handleImage(e)}
                            hidden
                        />
                        <button
                            id="btn-img"
                            className="mx-auto w-1/3 p-2 bg-emerald-600 text-white rounded-md hover:scale-95 duration-150"
                            onClick={() => document.getElementById("file").click()}
                        >
                            Carica Logo
                        </button>
                        </div>
                        {/* Edit Image */}
                        <div
                        className="flex flex-col w-full mt-2"
                        style={{
                            display: props.operation === "edit" ? "flex" : "none",
                        }}
                        >
                        <img
                            height="140"
                            src={
                            societa.Logo !== "" && societa.Logo
                                ? "data:image/png;base64," + societa.Logo
                                : require("../../assets/TecnogardenService.png")
                            }
                            id="Logo"
                            alt="img"
                            className="flex h-full max-h-[200px] mx-auto"
                        />
                        <input
                            accept="image/*"
                            id="file"
                            name="Firma"
                            multiple
                            type="file"
                            onChange={(e) => handleImage(e)}
                            hidden
                        />
                        <button
                            id="btn-img"
                            className="mx-auto w-1/3 p-2 bg-emerald-600 text-white rounded-md hover:scale-95 duration-150"
                            onClick={() => document.getElementById("file").click()}
                        >
                            Carica Logo
                        </button>
                        </div>
                    </div>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {/* Left Coontainer */}
                    <div>
                      <label className="relative ">
                        <span className="text-gray-700">Ragione Sociale</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={societa.RagioneSociale}
                          name="RagioneSociale"
                          onChange={handleEdit}
                        />
                      </label>

                      <label className="relative ">
                        <span className="text-gray-700">P. IVA</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={societa.PIVA}
                          name="PIVA"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Codice Fiscale</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={societa.CF}
                          name="CF"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Citta</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={societa.Citta}
                          name="Citta"
                          onChange={handleEdit}
                        />
                        </label>
                    </div>
                    {/* Left Coontainer */}
                    <div>
                        <label className="relative ">
                            <span className="text-gray-700">Cap</span>
                            <input
                            type="text"
                            className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                            placeholder=""
                            value={societa.Cap}
                            name="Cap"
                            onChange={handleEdit}
                            />
                        </label>
                      <label className="relative ">
                        <span className="text-gray-700">Provincia</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={societa.Provincia}
                          name="Provincia"
                          onChange={handleEdit}
                        />
                      </label>
                      <label className="relative ">
                        <span className="text-gray-700">Indirizzo</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={societa.Indirizzo}
                          name="Indirizzo"
                          onChange={handleEdit}
                        />
                      </label>
                      
                      <label className="relative ">
                        <span className="text-gray-700">Colore</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={societa.Colore}
                          name="Colore"
                          onChange={handleEdit}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* FOOTER */}
                <div className="btn-footer-container">
                  <button
                    className="save-btn"
                    type="button"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Salva
                  </button>
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => {
                      setShowTra(false);
                    }}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
