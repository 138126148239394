import React,{useEffect,useState} from "react";
import { EyeIcon } from '@heroicons/react/solid'
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import { login,loginPersistant } from "../utils";
import { useCookies } from "react-cookie";

const Login = () => {
    const [username,setUsername]=useState("");
    const [password,setPassword]=useState("");
    const [cookies, setCookie] = useCookies(["user"]);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = "#00a94e";
    
      });
const HandleLogin = async(event) =>{
        event.preventDefault();
          const response = await api
          .post('login',{username :username,password:password });
 
      if(response.data==="password errata" || response.data==="utente non trovato" ){
        alert("Username o Password errati");
      }else{
          console.log(response.data.utente.username)
       login(response.data.utente.username,response.data.utente.jwt,response.data.utente.ID);
      
       setCookie("user", response.data.utente, {
        path: "/"
      });
      setCookie("ID", response.data.ID, {
        path: "/"
      });
      
      
      
      
        navigate('/');
       //
      
      
      
      }
      }




    return (
        <div class="flex h-8/12 items-center justify-center">
        <div class="bg-white w-10/12 sm:w-4/12 mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl justify-center ">
        <section className="flex items-center justify-center">
            <img src ={require("../assets/TecnogardenService.png")} className="h-2/4 w-2/4 justify-center"/>
        </section>
        <section class="mt-10">
        <label class="block">
                <span class="text-gray-700">Username</span>
                <input
                  type="text"
                  class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-emerald-700
                  "
                  placeholder=""
                  value={username}
              onChange={e => setUsername(e.target.value)}
                />
              </label>
            </section>
            <section class="mt-10">



            <label class="relative ">
                <span class="text-gray-700">Password</span>
                
                <input
                  type="password"
                  class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-emerald-700
                  "
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder=""
                />
              </label>
        </section>
        <section className="mt-10">
        <button className="bg-emerald-700 hover:bg-emerald-600 text-white p-3 rounded-lg w-full" onClick={HandleLogin}>Log in</button>
        </section>
        </div>
      </div>
  );

}

export default Login;

//prova