import React,{useState,useEffect} from "react";
import api from "../api/api";
import { useCookies } from "react-cookie";
import moment from "moment-timezone";
import { useTable } from 'react-table'
import Table from "./Table";
import { useNavigate } from "react-router-dom";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';


const ODL = () => {
const navigate = useNavigate();
const [selectedC,setSelectedC] = useState(0);
const [commesse,setCommesse] = useState([])
const [cookies, setCookie] = useCookies(["user"]);





function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{color:"#047857"}}/>
      <GridToolbarFilterButton sx={{color:"#047857"}}/>
      <GridToolbarDensitySelector sx={{color:"#047857"}}/>
      <GridToolbarExport sx={{color:"#047857"}}/>
    </GridToolbarContainer  >
  );
}



const handleEditButtonClick = () => {
  if(selectedC === 0){
    alert("Seleziona almeno una commessa");
  }else{
    navigate("/dettaglioODL",{state: {operation: "edit",idCommessa:selectedC[0].id}})
  }
}

const handleDeleteButtonClick = async() => {
  if(selectedC === 0){
    alert("Seleziona almeno una commessa");
  }else{
     
    if (window.confirm('Sei sicuro di voler eliminare la commessa selezionata?')) {
      try{
    await api.delete('commesse',{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` },data: {id:selectedC[0].id}});

    alert("Commessa eliminata con successo");
    window.location.reload();//refresho
      }catch{
        alert("ERRORE DURANTE L'ELIMINAZIONE")
      }  
  }
  }
}

const columns = [
  { field: 'id', headerName: 'ID', width: 90,hide:true },
  {
    field: 'NomeCommessa',
    headerName: 'Cliente',
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    flex: 1,
    
  },
  {
    field: 'Periodo_Da',
    headerName: 'Periodo Da',
    flex: 1,
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    renderCell: (params) => (
      <div>{moment(params.row.Periodo_Da).format("DD/MM/YYYY")}</div>
    ),
  },
  {
    field: 'Periodo_A',
    headerName: 'Periodo A',
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    flex: 1,
    renderCell: (params) => (
      <div>{moment(params.row.Periodo_A).format("DD/MM/YYYY")}</div>
    ),
   
  },
];

useEffect(() => {
   
        const fetchData = async () =>{
             await api.get('commesse', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
            setCommesse(res.data.data);
  
        })
    
    }
    fetchData();
    },[]);
    


return(
    <>
    
    <div class="flex m-0 justify-end items-center w-full space-x-2 mb-4">
        <button className="new-btn"  onClick={() => navigate("/dettaglioODL",{state: {operation: "new",}})}> Nuova Commessa</button> 
        <button className="new-btn" onClick={handleEditButtonClick}> Modifica Commessa</button>
        <button className="delete-btn" onClick={handleDeleteButtonClick}> Elimina Commessa</button>
 
</div>

    <div className=" h-4/5">
    
    
    <DataGrid
      components={{
        Toolbar: CustomToolbar,
      }}
    sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
        rows={commesse}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[10]}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = commesse.filter((row) =>
          selectedIDs.has(row.id),
          );
          setSelectedC(selectedRowData);
      }}
      onRowDoubleClick={(params) => {
        navigate("/dettaglioODL",{state: {operation: "edit",idCommessa:params.id}})
      }

      }


      />
   
      
    </div> 
</>
)

}

export default ODL;