import React,{useState,useEffect} from "react";
import api from "../../api/api";
import { useCookies } from "react-cookie";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';

const Lavoratori = () => {
const [lavoratori,setLavoratori] = useState([])
const [zone,setZone] = useState([]);
const [societa,setSocieta] = useState([]);
const [macchine,setMacchine] = useState([])
const [cookies, setCookie] = useCookies(["user"]);
const [selectedC,setSelectedC] = useState(0);



function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{color:"#047857"}}/>
      <GridToolbarFilterButton sx={{color:"#047857"}}/>
      <GridToolbarDensitySelector sx={{color:"#047857"}}/>
      <GridToolbarExport sx={{color:"#047857"}}/>
    </GridToolbarContainer  >
  );
}



const columns = [
  {
    field: 'Cognome',
    headerName: 'Cognome',
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    width:200,
  },
    {
      field: 'Nome',
      headerName: 'Nome',
      headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
      width:200,
    },

      {
        field: 'RagioneSociale',
        headerName: 'Ragione Sociale',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        width:300,
      },
      {
        field: 'Descrizione',
        headerName: 'Autorizzazione',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        width:200,
      },
      {
        field: 'Zona',
        headerName: 'Zona',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        width:200,
      }
  ];
  
const loadLavoratori = async() => {
  await api.get('lavoratori', {params:{operation:"anagrafiche"},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
          setLavoratori(res.data.data);
        })
    
}

const handleDeleteButtonClick = async() => {
    if(selectedC === 0){
      alert("Seleziona almeno un lavoratore");
    }else{
       
      if (window.confirm('Sei sicuro di voler eliminare il lavoratore selezionato?')) {
        try{
            let can = selectedC;
            can.Deleted = 1;
          await api.put('lavoratori',{lavoratore:can},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  
      alert("Lavoratore eliminato con successo");
      window.location.reload();//refresho
        }catch{
          alert("ERRORE DURANTE L'ELIMINAZIONE")
        }  
    }
    }
  }

useEffect(() => {
   
        const fetchData = async () =>{
          loadLavoratori();

          await api.get('zone', {params:{operation:"anagrafiche"},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
            setZone(res.data.data);
        })

        await api.get('societa', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
      }})
      .then( res=> {
        setSocieta(res.data.data);
      })

         
      await api.get('macchine', {params:{operazione:"ALL"},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
    }})
    .then( res=> {
      setMacchine(res.data.data);
      
    })


    }
    fetchData();
    },[]);


return(
  <>
    <div class="flex m-0 justify-end items-center w-full space-x-2 mb-4">
      
        {/*<ModalLavoratore myFunction={loadLavoratori} cookies={cookies} buttonText="Nuovo" id="newButtonCliente" title="Crea Lavoratore" operation="new" classNameButton="new-btn" zone={zone}/>*/}
      
        <ModalLavoratore myFunction={loadLavoratori} cookies={cookies} buttonText="Modifica" selectedCli={selectedC} id="editButtonCliente" title="Modifica Lavoratore" operation="edit" classNameButton="new-btn" zone={zone} societa={societa} macchine={macchine}/>
         {/* <button className="delete-btn" onClick={handleDeleteButtonClick}> 
            Elimina
          </button>*/}
    </div>
    <div className=" h-4/5">
      <DataGrid
      components={{
        Toolbar: CustomToolbar,
      }}
        sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
        rows={lavoratori}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        getRowId={row=> row.ID}
        onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = lavoratori.filter((row) =>
            selectedIDs.has(row.ID),
            );
            
            setSelectedC(selectedRowData[0]);
        }}
        onRowDoubleClick={async (params) => {
            await setSelectedC(params.row);
            document.getElementById("editButtonCliente").click();
        }}
      /> 
   
      
    </div> 
</>
)

}

export default Lavoratori;




const ModalLavoratore = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [lavoratore, setLavoratore] = useState({IDCliente:0,NomeLavoratore:"",Provincia:"",Citta:"",Indirizzo:"",CAP:"",URL_Maps:"",Fisso:1,Presso:""});
    const [macchinaPrefe,setMacchinaPrefe] = useState([]);
    const [cookies, setCookie] = useCookies(["user"]);
    const handleEdit = (e) => {
        let can = {...lavoratore};
        can[e.target.name] = e.target.value;
        setLavoratore(can);
    }
    const handleSave = async () => {
      
            try{
              let l = {...lavoratore};
              
                let responseLavoratore= await api.put('lavoratori',{lavoratore:l},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
                
                //props.myFunction();
               if(l.IDAutorizzazione === 5 || l.IDAutorizzazione === "5" && ((macchinaPrefe.IDMacchina!== 0 && macchinaPrefe.IDMacchina!== "0") || (macchinaPrefe.IDRimorchio!== 0 && macchinaPrefe.IDRimorchio!== "0"))){
                if(macchinaPrefe.IDMacchinaAut !== 0){
                  let responseAggiornaMacc= await api.put('macchinaAutista',{macchinaAutista:macchinaPrefe},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
                }else{
                  let responseInserisciMacc= await api.post('macchinaAutista',{macchinaAutista:macchinaPrefe,dipendente:l.ID},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
                }


               }
               
               alert("Lavoratore modificato con successo");
               window.location.reload();
            }catch{
                alert("Errore durante la modifica del nuovo lavoratore");
            }

        
    }
    const openModal = async() => {
     if(props.operation ==="new"){
      setLavoratore({IDCliente:0,NomeLavoratore:"",Provincia:"",Citta:"",Indirizzo:"",CAP:"",URL_Maps:"",Fisso:1,Presso:""});
     }else{
      setLavoratore(props.selectedCli);
      if(props.selectedCli.IDAutorizzazione ==="5" || props.selectedCli.IDAutorizzazione ===5 ){


        await api.get('macchinaAutista', {params:{ID:props.selectedCli.ID},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
      }})
      .then( res=> {

        if(res.data.data.length>0){
          setMacchinaPrefe({IDMacchina:res.data.data[0].IDMacchina?res.data.data[0].IDMacchina:0,IDRimorchio:res.data.data[0].IDRimorchio?res.data.data[0].IDRimorchio:0,IDMacchinaAut:res.data.data[0].IDMacchinaAut?res.data.data[0].IDMacchinaAut:0})
        }else{
          setMacchinaPrefe({IDMacchina:0,IDRimorchio:0,IDMacchinaAut:0});
        }
        
      })

      }else{


        setMacchinaPrefe({IDMacchina:0,IDRimorchio:0,IDMacchinaAut:0});

      }



     }
        setShowModal(true);
    }


    return (
      <>
        <button
          id={props.id}
          className={props.classNameButton}
          type="button"
          onClick={async () => {openModal()}}
        >
          {props.buttonText}
        </button>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <label className="relative ">
                             <span className="text-gray-700">Società</span>
                             <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md" value={lavoratore.IDSocieta} onChange={(e) =>{ let l= {...lavoratore}; l.IDSocieta = e.target.value; setLavoratore(l) }} > 
          <option value="0" >Seleziona...</option>
          {props.societa?.map((s) => 

            <option key={s.ID} value={s.ID} >{s.RagioneSociale}</option>

            )}
          </select>
                            </label>
                           
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                    <label className="relative ">
                             <span className="text-gray-700">Zona</span>
                             <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md" value={lavoratore.IDZona} onChange={(e) =>{ let l = {...lavoratore}; l.IDZona = e.target.value; setLavoratore(l) }} > 
          <option value="0" >Seleziona...</option>
          {props.zone?.map((z) => 

            <option key={z.ID} value={z.ID} >{z.Zona}</option>

            )}
          </select>
                            </label>
                          
                        <label className="relative ">
                             <span className="text-gray-700">Nome</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={lavoratore.Nome}
                                         name="Nome"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            
                            <label className="relative " style={{display:lavoratore?.IDAutorizzazione==="5"  || lavoratore?.IDAutorizzazione ===5 ?"":"None"}}>
                             <span className="text-gray-700">Mezzo Preferito</span>
                             <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md" value={macchinaPrefe.IDMacchina} onChange={(e) =>{ let m = {...macchinaPrefe}; m.IDMacchina = e.target.value; setMacchinaPrefe(m) }} > 
                              <option value="0" >Seleziona...</option>
                              {props.macchine?.map((m) => 
                                <option key={m.ID} value={m.ID} >{m.Nome}</option>)}
                            </select>
                            </label>  
                        

                    </div>
                    {/* DESTRA */}
                    <div>
                    <label className="relative ">
                             <span className="text-gray-700">Autorizzazione</span>
                             <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md" value={lavoratore.IDAutorizzazione} onChange={(e) =>{ let l = {...lavoratore}; l.IDAutorizzazione = e.target.value; setLavoratore(l); console.log(l); }} > 
          <option value="0" >Seleziona...</option>
    

          <option value="5" >Autista</option>
          <option value="4" >Operatore</option>
          <option value="3" >Responsabile</option>

          </select>
                            </label>
                    <label className="relative ">
                         <span className="text-gray-700">Cognome</span>
                        <input
                           type="text"
                           className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                           placeholder=""
                           value={lavoratore.Cognome}
                           name="Cognome"
                          onChange={handleEdit}
                         />
                    </label>
                    <label className="relative " style={{display:lavoratore?.IDAutorizzazione==="5"  || lavoratore?.IDAutorizzazione ===5 ?"":"None"}}>
                             <span className="text-gray-700">Rimorchio Preferito</span>
                             <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md" value={macchinaPrefe.IDRimorchio} onChange={(e) =>{ let m = {...macchinaPrefe}; m.IDRimorchio = e.target.value; setMacchinaPrefe(m) }} > 
                              <option value="0" >Seleziona...</option>
                              {props.macchine?.filter(mac => mac.Rimorchiabile === 1).map((m) => 
                                <option key={m.ID} value={m.ID} >{m.Nome}</option>)}
                            </select>
                            </label>  
                    </div>
                </div>
                  </div>
                  {/*footer*/}
                  <div className="btn-footer-container">
                    <button
                      className="save-btn"
                      type="button"
                      onClick={handleSave}
                    >
                      Salva
                    </button>
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }  

