import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Anagrafiche = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);

  console.log(cookies.user);
  return (
    <>
      {/* Desktop */}
      <div class="lg:flex hidden flex-row flex-wrap w-full">
          <button
          className="btn-an-dsk" onClick={() => navigate("/Clienti")}>
            Clienti
          </button>
          <button className="btn-an-dsk" onClick={() => navigate("/Cantieri")}>
            Cantieri
          </button>
          <button
            className="btn-an-dsk"
            onClick={() => navigate("/Trasportatori")}
          >
            Trasportatori
          </button>
          <button
            style={{display:cookies.user.Autorizzazione!==1?"none":""}}
            className="btn-an-dsk"
            onClick={() => navigate("/Macchinari")}
          >
            Macchinari
          </button>
          <button
            className="btn-an-dsk"
            onClick={() => navigate("/Lavoratori")}
          >
            Lavoratori
          </button>
          <button className="btn-an-dsk" onClick={() => navigate("/Societa")}>
            Società
          </button>
          <button
            className="btn-an-dsk"
            onClick={() => navigate("/Lavorazioni")}
          >
            Lavorazioni
          </button>
          <button
            className="btn-an-dsk"
            onClick={() => navigate("/unitaMisure")}
          >
            Unita Misure
          </button>

          <button className="btn-an-dsk" onClick={() => navigate("/CDC")}>
            CDC
          </button>
        </div>

      {/* Mobile */}
      <div class="lg:hidden pl-10 flex flex-row flex-wrap h-[500px] w-full">
        <button className="btn-an-mbl" onClick={() => navigate("/Clienti")}>
          Clienti
        </button>
        <button className="btn-an-mbl" onClick={() => navigate("/Cantieri")}>
          Cantieri
        </button>
        <button
          className="btn-an-mbl"
          onClick={() => navigate("/Trasportatori")}
        >
          Trasportatori
        </button>

        <button 
        style={{display:cookies.user.Autorizzazione!==1?"none":""}}
        className="btn-an-mbl" onClick={() => navigate("/Macchinari")}>
          Macchinari
        </button>

        <button className="btn-an-mbl" onClick={() => navigate("/Lavoratori")}>
          Lavoratori
        </button>
        <button className="btn-an-mbl" onClick={() => navigate("/Societa")}>
          Società
        </button>

        <button className="btn-an-mbl" onClick={() => navigate("/Lavorazioni")}>
          Lavorazioni
        </button>
        <button className="btn-an-mbl" onClick={() => navigate("/unitaMisure")}>
          Unita Misure
        </button>
        <button className="btn-an-mbl" onClick={() => navigate("/CDC")}>
          CDC
        </button>
      </div>
    </>
  );
};

export default Anagrafiche;
