import React, { useState, useEffect } from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../api/api";
import { useCookies } from "react-cookie";
import moment from "moment";
import Autocomplete from "./CustomComponents/Autocomplete";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import DatePicker from '@mui/lab/DatePicker';

const MacchinariGestioneLavoro = () => {
const [data,setData] = useState(new Date())
const [posizioneMacchinari,setPosizioniMacchinari] = useState([]);
const [macchinari,setMacchinari] = useState([]);
const [macchinaSel,setMacchinaSel] = useState(false);
const [cookies, setCookie] = useCookies(["user"]);


const columns = [
  {
    field: 'NomeMacchina',
    headerName: 'Macchina',
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    flex:1,
  },
  {
    field: 'Partenza',
    headerName: 'Partenza',
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    flex:1,
  },
  {
    field: 'Destinazione',
    headerName: 'Destinazione',
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    flex:1,
  },
  {
    field: 'Data',
    headerName: 'Ultimo spostamento',
    headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
    flex:1,
    renderCell: (params) => {
      return params.row.Data?moment(params.row.Data).format("DD/MM/YYYY"):""


    }
  },

  
  ];


const loadPosizioneMacchinari = async(dataSel) => {
    await api.get('posizioneMacchinari', {params:{data:moment(dataSel).format("YYYY-MM-DD")},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
         
          setPosizioniMacchinari(res.data.data);
        
        })
      
}
const loadMacchinari = async () => {

     
  await api
  .get("anagraficaMacchine", {
    headers: { Authorization: `Bearer ${cookies.user.jwt}` },
  })
  .then(async (res) => {
    setMacchinari(res.data.data);       });

  
}


const loadData = async(m) => {
console.log(m)
  await api.get('posizioneMacchinari', {params:{data:moment(data).format("YYYY-MM-DD"),macchina:m},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
}})
.then( res=> {
 
  setPosizioniMacchinari(res.data.data);
})
}



function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{color:"#047857"}}/>
      <GridToolbarFilterButton sx={{color:"#047857"}}/>
      <GridToolbarDensitySelector sx={{color:"#047857"}}/>
      <GridToolbarExport sx={{color:"#047857"}}/>
    </GridToolbarContainer  >
  );
}

useEffect(() => {
    const fetchData = async () => {
      loadMacchinari()
          loadPosizioneMacchinari(new Date());

    };
    fetchData();
  }, []);

    return <div className="h-full">

    <div className="grid grid-cols-4 gap-4">
          <div><LocalizationProvider dateAdapter={DateAdapter} >
              <DatePicker
                views={['year', 'month', 'day']}
                value={data}
                onChange={async (newValue) => {
                 await setData(newValue);
                 setPosizioniMacchinari([])
                 loadPosizioneMacchinari(newValue);
                
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="inline-flex items-center justify-between w-full pr-3 mb-3">
                    <label className="flex items-center w-full">
                      <span className="text-gray-700 w-2/5">Data</span>
                      <input className="mt-0 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 text-start pb-1 ml-8" style={{}} ref={inputRef} {...inputProps} />
                    </label>
                    {InputProps?.endAdornment}
                  </div>
                )} />
            </LocalizationProvider>
            </div>
<div>

<Autocomplete suggestions={macchinari} fields={["Marca","Modello","NomeMacchina","Targa"]}  keyField="ID" label="Macchinari" value={macchinaSel} id="macchinari" onChange={setMacchinaSel} loadData={loadData}/>

</div>

<div>
<button style={{display:macchinaSel?"":"None"}} onClick={() => {setMacchinaSel(false);loadPosizioneMacchinari(data)}}> Azzera </button>
</div>
</div>
<div className=" h-4/5">

<DataGrid
components={{
  Toolbar: CustomToolbar,
}}
  sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
  rows={posizioneMacchinari}
  columns={columns}
  pageSize={100}
  rowsPerPageOptions={[100]}
  localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
  getRowId={row=> row.IDMacchina + row.Destinazione + row.Partenza}
/> 


</div>



    </div>

}



export default MacchinariGestioneLavoro


/*










*/