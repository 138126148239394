import React,{useState,useEffect} from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";

const Clienti = () => {
const [clienti,setClienti] = useState([])
const [cookies, setCookie] = useCookies(["user"]);
const [selectedC,setSelectedC] = useState(0);

const columns = [
    { field: 'id', headerName: 'ID', width: 90,hide:true },
    {
      field: 'RagSociale',
      headerName: 'Ragione Sociale',
      headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
      flex: 1,
    },
    {
        field: 'SedeCitta',
        headerName: 'Sede legale',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
    {
        field: 'P_IVA',
        headerName: 'Partita IVA',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
      
   
  ];
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{color:"#047857"}}/>
        <GridToolbarFilterButton sx={{color:"#047857"}}/>
        <GridToolbarDensitySelector sx={{color:"#047857"}}/>
        <GridToolbarExport sx={{color:"#047857"}}/>
      </GridToolbarContainer  >
    );
  }



const loadClienti = async() => {
    await api.get('clienti', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
            setClienti(res.data.data);
        })
    
}

const handleDeleteButtonClick = async() => {
    if(selectedC === 0){
      alert("Seleziona almeno un cliente");
    }else{
       
      if (window.confirm('Sei sicuro di voler eliminare il cliente selezionato?')) {
        try{
            let cli = selectedC;
            cli.Deleted = 1;
          await api.put('clienti',{cliente:cli},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  
      alert("Cliente eliminato con successo");
      window.location.reload();//refresho
        }catch{
          alert("ERRORE DURANTE L'ELIMINAZIONE")
        }  
    }
    }
  }

useEffect(() => {
   
        const fetchData = async () =>{
            loadClienti();
    }
    fetchData();
    },[]);


return(
  <>
    <div class="flex m-0 justify-end items-center w-full space-x-2 mb-4">
      
        <ModalCliente myFunction={loadClienti} cookies={cookies} buttonText="Nuovo Cliente" id="newButtonCliente" title="Crea Cliente" operation="new" classNameButton="new-btn" />
      
        <ModalCliente myFunction={loadClienti} cookies={cookies} buttonText="Modifica Cliente" selectedCli={selectedC} id="editButtonCliente" title="Modifica Cliente" operation="edit" classNameButton="new-btn" />
          <button className="delete-btn" onClick={handleDeleteButtonClick}> 
            Elimina Cliente
          </button>
      
    </div>
    <div className=" h-4/5">
      <DataGrid
      components={{
        Toolbar: CustomToolbar,
      }}
        sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
        rows={clienti}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        getRowId={row=> row.ID}
        onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = clienti.filter((row) =>
            selectedIDs.has(row.ID),
            );
            
            setSelectedC(selectedRowData[0]);
        }}
        onRowDoubleClick={async (params) => {
            await setSelectedC(params.row);
            document.getElementById("editButtonCliente").click();
        }}
      /> 
   
      
    </div> 
</>
)
}

export default Clienti;




const ModalCliente = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [cliente, setClienti] = useState({RagSociale:"",P_IVA:"",SedeLegale:"",eMail:"",CodiceFiscale:"",SedeProvincia:"",SedeCitta:"",SedeCAP:""});

    const handleEdit = (e) => {
        let cli = {...cliente};
        cli[e.target.name] = e.target.value;
        setClienti(cli);
    }
    const handleSave = async () => {
        if(props.operation ==="new"){
                try{
                    let responseCliente= await api.post('clienti',{cliente:cliente},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
                    alert("Cliente inserito con successo");
                    props.myFunction();
                    setShowModal(false)
                }catch{
                    alert("Errore durante la creazione del nuovo cliente");
                }


        }else{
            try{
                let responseCliente= await api.put('clienti',{cliente:cliente},{ headers: {'Authorization': `Bearer ${props.cookies.user.jwt}` }});
          
                props.myFunction();
                alert("Cliente modificato con successo");
            }catch{
                alert("Errore durante la modifica del nuovo cliente");
            }

        }   
    }
    const openModal = async() => {
     if(props.operation ==="new"){
        setClienti({RagSociale:"",P_IVA:"",SedeLegale:"",eMail:"",CodiceFiscale:"",SedeProvincia:"",SedeCitta:"",SedeCAP:""});
     }else{
        setClienti(props.selectedCli);
     }
        setShowModal(true);
    }


    return (
      <>
        <button
          id={props.id}
          className={props.classNameButton}
          type="button"
          onClick={async () => {openModal()}}
        >
          {props.buttonText}
        </button>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="relative ">
                             <span className="text-gray-700">Ragione Sociale</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cliente.RagSociale}
                                         name="RagSociale"
                                        onChange={handleEdit}
                                                                />
                            </label>

                            <label className="relative ">
                             <span className="text-gray-700">Via</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cliente.SedeLegale}
                                         name="SedeLegale"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            <label className="relative ">
                             <span className="text-gray-700">Provincia</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cliente.SedeProvincia}
                                         name="SedeProvincia"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            <label className="relative ">
                             <span className="text-gray-700">CAP</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cliente.SedeCAP}
                                         name="SedeCAP"
                                        onChange={handleEdit}
                                                                />
                            </label>

                    </div>
                    <div>
                    <label className="relative ">
                         <span className="text-gray-700">Partita IVA</span>
                        <input
                           type="text"
                           className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                           placeholder=""
                           value={cliente.P_IVA}
                           name="P_IVA"
                          onChange={handleEdit}
                         />
                    </label>

                    <label className="relative ">
                             <span className="text-gray-700">Codice Fiscale</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cliente.CodiceFiscale}
                                         name="CodiceFiscale"
                                        onChange={handleEdit}
                                                                />
                            </label>
                            <label className="relative ">
                             <span className="text-gray-700">Città</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cliente.SedeCitta}
                                         name="SedeCitta"
                                        onChange={handleEdit}
                                                                />
                            </label>
                        
                            <label className="relative ">
                             <span className="text-gray-700">Email</span>
                                  <input
                                         type="text"
                                         className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                                         placeholder=""
                                         value={cliente.eMail}
                                         name="eMail"
                                        onChange={handleEdit}
                                                                />
                            </label>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="btn-footer-container">
                    <button
                      className="save-btn"
                      type="button"
                      onClick={handleSave}
                    >
                      Salva
                    </button>
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }  

