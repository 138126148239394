import axios from 'axios';

export default axios.create({
baseURL:'https://tecnogardenweb.millergroup.it/api/'
  //baseURL:'http://localhost:3000/api/'
    //baseURL:"https://tecnogarden-be-wyh4dbquta-oc.a.run.app/api/"
});


//https://tecnogarden.millergroup.it/api/


/*
SELECT
	T.Destinazione,
	T.Partenza,
	T.IDMerceMezzo
FROM
	(
	SELECT
		Destinazione,
		Partenza,
		IDMerceMezzo,
		Nome,
		DataTratta,
		IDCOM 
	FROM
		(
		SELECT
			ID AS IDCOM,
			Destinazione,
			Partenza,
			IDMerceRimorchio as IDMerceMezzo,
			MerceMezzo AS Nome,
			DataTratta,
			IDCommessa 
		FROM
			ODL_Tratte 
		WHERE
			TipologiaDiTrasporto = "Trasferimento" 
			AND Deleted = 0 
		) AS tutteLeMacchine 
	WHERE
		DataTratta <= "2022-12-14" 
	) AS T
	JOIN (
	SELECT
		Destinazione,
		Partenza,
		IDMerceMezzo,
		Nome,
		MAX( DataTratta ) AS DataTratta,
		IDCOM 
	FROM
		(
		SELECT
			ID AS IDCOM,
			Destinazione,
			Partenza,
			IDMerceRimorchio as IDMerceMezzo,
			MerceMezzo AS Nome,
			DataTratta,
			IDCommessa 
		FROM
			ODL_Tratte 
		WHERE
			TipologiaDiTrasporto = "Trasferimento" 
			AND Deleted = 0 
		) AS tutteLeMacchine 
	WHERE
		DataTratta <= "2022-12-14" 
		GROUP BY IDMerceMezzo
	) AS T2 ON T.IDMerceMezzo = T2.IDMerceMezzo 
	AND T.DataTratta = T2.DataTratta
	WHERE T.IDMerceMezzo <> 0

*/