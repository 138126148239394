import React from "react";
import { BrowserRouter, Routes ,Route} from 'react-router-dom';
import Login from "./components/Login";
import MainLayout from "./components/MainLayout";
import Panoramica from "./components/Panoramica";
import ODL from "./components/ODL";
import Personale from "./components/Personale";
import ControlloDiGestione from "./components/ControlloDiGestione";
import Tecnico from "./components/Tecnico";
import Anagrafiche from "./components/Anagrafiche";
import DettaglioODL from "./components/DettaglioODL";
import Commerciale from "./components/Commerciale";
import Clienti from "./components/Anagrafiche/Clienti";
import PrivateRoute from "./components/PrivateRoute";
import Trasportatori from "./components/Anagrafiche/Trasportatori";
import Cantieri from "./components/Anagrafiche/Cantieri";
import Macchinari from "./components/Anagrafiche/Macchinari";
import Lavoratori from "./components/Anagrafiche/Lavoratori";
import Macchinario from "./components/Anagrafiche/Macchinario";
import Societa from "./components/Anagrafiche/Societa";
import Lavorazioni from "./components/Anagrafiche/Lavorazioni";
import UnitaMisure from "./components/Anagrafiche/UnitaMisure";
import CDC from "./components/Anagrafiche/CDC";
import Permessi from "./components/Personale/Permessi";
import OrdinePanoramica from "./components/Personale/OrdinePanoramica";
import MacchinariGestioneLavoro from "./components/MacchinariGestioneLavoro";





const App =() =>  {
  return (
    <div> 
        
    <BrowserRouter>
    <Routes>
   
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<PrivateRoute><MainLayout /></PrivateRoute>} >
    <Route path="/panoramica" element={<PrivateRoute><Panoramica /></PrivateRoute>} />
    <Route path="/personale" element={<PrivateRoute><Personale /></PrivateRoute>} />
    <Route path="/commerciale" element={<PrivateRoute><Commerciale /></PrivateRoute>} />
    <Route path="/controlloDiGestione" element={<PrivateRoute><ControlloDiGestione /></PrivateRoute>} />
    <Route path="/tecnico" element={<PrivateRoute><Tecnico /></PrivateRoute>} />
    <Route path="/anagrafiche" element={<PrivateRoute><Anagrafiche /></PrivateRoute>} />
    <Route path="/Commessa" element={<PrivateRoute><ODL /></PrivateRoute>} />
    <Route path="/dettaglioODL" element={<PrivateRoute><DettaglioODL /></PrivateRoute>} />
    <Route path="/clienti" element={<PrivateRoute><Clienti /></PrivateRoute>} />
    <Route path="/trasportatori" element={<PrivateRoute><Trasportatori /></PrivateRoute>} />
    <Route path="/cantieri" element={<PrivateRoute><Cantieri /></PrivateRoute>} />
    <Route path="/macchinari" element={<PrivateRoute><Macchinari /></PrivateRoute>} />
    <Route path="/lavoratori" element={<PrivateRoute><Lavoratori /></PrivateRoute>} />
    <Route path="/macchinario" element={<PrivateRoute><Macchinario /></PrivateRoute>} />
    <Route path="/societa" element={<PrivateRoute><Societa /></PrivateRoute>} />
    <Route path="/lavorazioni" element={<PrivateRoute><Lavorazioni /></PrivateRoute>} />
    <Route path="/unitaMisure" element={<PrivateRoute><UnitaMisure /></PrivateRoute>} />
    <Route path="/cdc" element={<PrivateRoute><CDC /></PrivateRoute>} />
    <Route path="/permessi" element={<PrivateRoute><Permessi /></PrivateRoute>} />
    <Route path="/OrdinePanoramica" element={<PrivateRoute><OrdinePanoramica /></PrivateRoute>} />
    <Route path="/MacchinariGL" element={<PrivateRoute><MacchinariGestioneLavoro /></PrivateRoute>} />
    </Route>
    </Routes>
  </BrowserRouter>
  </div>
  );
}

export default App;
