import React,{useEffect,useState} from "react";
import DatePicker from '@mui/lab/DatePicker';
import { FormControl,InputLabel,Select,Chip,Box,OutlinedInput,MenuItem,IconButton } from "@mui/material";
import Holidays from 'date-holidays';
import moment from "moment";
import momentTimezone from "moment-timezone";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "moment/locale/it";
import api from "../../api/api";
import { useCookies } from "react-cookie";
import {Add,Info,Delete,Error,ContentCopy,AccessTime} from '@mui/icons-material';
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const Tratta = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [commesse,setCommesse] = useState([]);
    const [commesseOdierne,setCommesseOdierne] = useState([]);
    const [commesseCombobox,setCommesseCombobox] = useState([]);
    const [macchine,setMacchine] = useState([]);
    const [cookies, setCookie] = useCookies(["user"]);
    const [localita,setLocalita] = useState();
    const [tipologia,setTipologia] = useState("Vendita");
    const [localitaPartenza,setLocalitaPartenza] = useState({Indirizzo:"",Citta:"",Provincia:"",CAP:"",Presso:""});
    const [localitaDestino,setLocalitaDestino] = useState({Indirizzo:"",Citta:"",Provincia:"",CAP:"",Presso:""});
    const [cdc,setCDC] = useState([]);
    const [cboxRimorchio,setCboxRimorchio] = useState(true);
    const [insertType,setInsertType] = useState("");
    const [clienti,setClienti] = useState([]);
    const [selezioneCliComm,setselezioneCliComm] = useState({commessaDestino:"",clienteDestino:"",commessaPartenza:"",clientePartenza:"",ID:0})
    const [commessaSel,setCommessaSel] = useState(0)
    const [note,setNote] =useState("");
    const [lavorazione,setLavorazione] = useState(false);
    const [allCommesse,setAllCommesse] = useState(false);
    const [showAlert,setShowAlert] = useState(false);
    const [mezzo,setMezzo] = useState(0);
    const [trattaPrecedente,setTrattaPrecedente] = useState(0);
    const [rimorchio,setRimorchio] = useState(0);
    const [merceMezzo,setMerceMezzo] = useState({IDMerceMezzo:0,MerceMezzo:""});
    const [merceMezzoRimorchio,setMerceMezzoRimorchio] = useState({IDMerceMezzoRimorchio:0,MerceMezzoRimorchio:""});
    const [checkTrattaPrecedente,setCheckTrattaPrecedente] =useState({Indirizzo:false,Citta:false,Provincia:false,CAP:false,IndirizzoText:"",CittaText:"",ProvinciaText:"",CAPText:""});
    const [tooltipOpen,setTooltipOpen] = useState(false);
    const [macchineTooltip,setMacchineTooltip] = useState([]);
    const [macchinaSel,setMacchinaSel] = useState();
  
  
    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton sx={{color:"#047857"}}/>
          <GridToolbarFilterButton sx={{color:"#047857"}}/>
          <GridToolbarDensitySelector sx={{color:"#047857"}}/>
          <GridToolbarExport sx={{color:"#047857"}}/>
        </GridToolbarContainer  >
      );
    }
  
  
  
    const columnsCommesseP = [
      { field: 'ID', headerName: 'ID', width: 0, hide:true },
      {
        field: 'NomeCommessa',
        headerName: 'Cliente',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        width:400,
        
      },
      {
        field: 'Periodo_Da',
        headerName: 'Periodo Da',
        flex:1,
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        renderCell: (params) => (
          <div>{moment(params.row.Periodo_Da).format("DD/MM/YYYY")}</div>
        ),
      },
      {
        field: 'Periodo_A',
        headerName: 'Periodo A',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex:1,
        renderCell: (params) => (
          <div>{moment(params.row.Periodo_A).format("DD/MM/YYYY")}</div>
        ),
      },
        {
          field: 'Provincia',
          headerName: 'Provincia',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex:1,
          
        },
        {
          field: 'Indirizzo',
          headerName: 'Indirizzo',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex: 1,
       
        },
        {
          field: 'Citta',
          headerName: 'Citta',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex:1,
          
        },
        {
          field: 'CAP',
          headerName: 'CAP',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex:1,
          
        },
        {
          field: 'Presso',
          headerName: 'Presso',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex:1,
          
        },
       
  
    ];
  
    const columnsClienti = [
      { field: 'ID', headerName: 'ID',hide:true },
      {
        field: 'RagSociale',
        headerName: 'Ragione Sociale',
        headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
        flex: 1,
      },
      {
          field: 'Provincia',
          headerName: 'Provincia',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex: 1,
        },
      {
          field: 'Indirizzo',
          headerName: 'Indirizzo',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex: 1,
        },
        {
          field: 'Citta',
          headerName: 'Citta',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex: 1,
        },
        {
          field: 'CAP',
          headerName: 'CAP',
          headerClassName: 'bg-emerald-700 text-white font-medium uppercase tracking-wider',
          flex: 1,
        },
        
     
    ];
  
  const handleOpen = async() => {
  let macchineCheck = [];
    let commesseGlobal=[];
    await api.get('commesse', {headers: {'Authorization': `Bearer ${cookies.user.jwt}` //commesse odierne
  },params:{date:props.date,idLavoratore:props.idLavoratore}})
  .then( res=> {
   
    setCommesseCombobox(res.data.data);
    setCommesseOdierne(res.data.data);
  
  })
  
  await api.get('commesse', {headers: {'Authorization': `Bearer ${cookies.user.jwt}` //commesse totali
  }})
  .then( res=> {
    setCommesse(res.data.data);
    commesseGlobal=res.data.data;
  })
  
  let responseMacchine = await api.get('macchine', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{operazione:"ALL"}})

    setMacchine(responseMacchine.data.data); //popolo il dato che mi serve
    macchineCheck = responseMacchine.data.data;
  
  
  
  
  
  await api.get('cdc', {headers: {'Authorization': `Bearer ${cookies.user.jwt}` //CDC 
  }})
  .then( res=> {
    setCDC(res.data.data);
  
  })
  
  
  await api.get('clienti', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
  },params:{type:"clientiTratta"}})
  .then( res=> {
      setClienti(res.data.data);
  })
  
  if(props.operation==="new"){
  
    await api.get('macchinaAutista', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{ID:props.idLavoratore}})
  .then( res=> {
  if(res.data.data.length>0){
    setMezzo(res.data.data[0].IDMacchina)
    setRimorchio(res.data.data[0].IDRimorchio);

    let macc = macchineCheck.filter(m => m.ID === parseInt(res.data.data[0].IDMacchina));

    setMacchinaSel(macc.length>0?macc[0]:null)

    setCboxRimorchio(res.data.data[0].IDRimorchio?true:false);
  }else{
    setMezzo(0);
    setRimorchio(0);
    setCboxRimorchio(false);
    setMacchinaSel(null)
  }
    //popolo il dato che mi serve
  
  });
  setCheckTrattaPrecedente({Indirizzo:false,Citta:false,Provincia:false,CAP:false,IndirizzoText:"",CittaText:"",ProvinciaText:"",CAPText:""});
    setShowAlert(false);
    setAllCommesse(false);

    setselezioneCliComm({commessaDestino:"",clienteDestino:"",commessaPartenza:"",clientePartenza:"",ID:0});
    setLocalitaPartenza({Indirizzo:"",Citta:"",Provincia:"",CAP:"",Presso:""});
    setLocalitaDestino({Indirizzo:"",Citta:"",Provincia:"",CAP:"",Presso:""});
    setMerceMezzoRimorchio({IDMerceMezzoRimorchio:0,MerceMezzoRimorchio:""});
    setMerceMezzo({IDMerceMezzo:0,MerceMezzo:""});
   
    setTrattaPrecedente(0);
    setLavorazione(false);
    setNote("");
    setCommessaSel(0);
    setInsertType("");
    setTipologia("0");
  
  
  
  
  }else{
  
    let res = await api.get('odlTratte', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
  },params:{id:props.idTratta}});
      //setClienti(res.data.data);
      let t = res.data.data[0];
      
      
      let macc = macchineCheck.filter(m => m.ID === parseInt(t.IDMacchina));
     
      setMacchinaSel(macc.length>0?macc[0]:null)
      setInsertType("");
      setCboxRimorchio(t.Rimorchio===1?true:false);
      setTipologia(t.TipologiaDiTrasporto);
      setNote(t.Note);
      setLocalitaPartenza({Indirizzo:t.Partenza,Citta:t.PartenzaCitta,Provincia:t.PartenzaProvincia,CAP:t.PartenzaCap,Presso:t.PartenzaPresso?t.PartenzaPresso:""});
      setLocalitaDestino({Indirizzo:t.Destinazione,Citta:t.DestinazioneCitta,Provincia:t.DestinazioneProvincia,CAP:t.DestinazioneCap,Presso:t.DestinazionePresso?t.DestinazionePresso:""});
      setMerceMezzoRimorchio({IDMerceMezzoRimorchio:t.IDMerceRimorchio,MerceMezzoRimorchio:t.MerceRimorchio});
      setMerceMezzo({IDMerceMezzo:t.IDMerceMezzo,MerceMezzo:t.MerceMezzo});
      setMezzo(t.IDMacchina);
      setCommessaSel(t.IDCommessa);
      setRimorchio(t.IDRimorchio);
      setTrattaPrecedente(t.IDTrattaPrecedente);
      if(t.ClienteCommessaID===0){
        setselezioneCliComm({commessaDestino:"",clienteDestino:"",commessaPartenza:"",clientePartenza:"",ID:t.ClienteCommessaID})
      }else{
        switch (t.TipoClienteCommessa){
  
          case "ClienteDestino":
            setselezioneCliComm({commessaDestino:"",clienteDestino:t.ClienteCommessa,commessaPartenza:"",clientePartenza:"",ID:t.ClienteCommessaID})
          
          break;
          case "ClientePartenza": 
          setselezioneCliComm({commessaDestino:"",clienteDestino:"",commessaPartenza:"",clientePartenza:t.ClienteCommessa,ID:t.ClienteCommessaID})
          break;
          case "CommessaDestino": 
          setselezioneCliComm({commessaDestino:t.ClienteCommessa,clienteDestino:"",commessaPartenza:"",clientePartenza:"",ID:t.ClienteCommessaID})
          break;
          case "CommessaPartenza": 
          setselezioneCliComm({commessaDestino:"",clienteDestino:"",commessaPartenza:t.ClienteCommessa,clientePartenza:"",ID:t.ClienteCommessaID})
          break;
                }
      }
  
      let cOd = commesseOdierne.filter(comm => comm.id === t.IDCommessa);
      if(cOd.length === 0){
  
        setCommesseCombobox(commesseGlobal);
        setAllCommesse(true);
      }
  
      if(t.IDTrattaPrecedente !==0){
      let trattaPrecedente = await api.get('odlTratte', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{id:t.IDTrattaPrecedente}});
  
        let check = {Indirizzo:false,Citta:false,Provincia:false,CAP:false,IndirizzoText:"",CittaText:"",ProvinciaText:"",CAPText:""};
        
        if(trattaPrecedente.data.data[0].Destinazione !== t.Partenza){
          check.Indirizzo = true
          check.IndirizzoText =trattaPrecedente.data.data[0].Destinazione;
        }
        if(trattaPrecedente.data.data[0].DestinazioneCitta !== t.PartenzaCitta){
          check.Citta = true
          check.CittaText =trattaPrecedente.data.data[0].DestinazioneCitta;
        }
        if(trattaPrecedente.data.data[0].DestinazioneProvincia !== t.PartenzaProvincia){
          check.Provincia = true
          check.ProvinciaText =trattaPrecedente.data.data[0].DestinazioneProvincia;
        }
        if(trattaPrecedente.data.data[0].DestinazioneCAP !== t.PartenzaCAP){
          check.CAP = true
          check.CAPText =trattaPrecedente.data.data[0].DestinazioneCAP;
        }
      setCheckTrattaPrecedente(check)
      }
  }
  
    setShowModal(true);
  
  }
  
  const handleCheck = async(e) => {
    setAllCommesse(e.target.checked);
    if(e.target.checked){
      setCommesseCombobox(commesse);
  
    }else{
    
      setCommesseCombobox(commesseOdierne);
    
    }
    
    }
  
  const handleOdlChange = async (e) => {
            
      let commessa = commesseCombobox.filter(item => item.id.toString()=== e.target.value);
  
      setCommessaSel(e.target.value);
      let responseCantieri = await api.get('cantieri', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{idCantiere:commessa[0].IDCantiere}})
  
  
      setLocalita(responseCantieri.data.data[0].IndirizzoCompleto);
      populateIndirizzo(responseCantieri.data.data[0].IndirizzoCompleto,tipologia)
      if(e.target.value !==0 ){
      let res = await api.get('lavoratoriAbilitatiCommessa', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{id:e.target.value,lavoratore:props.idLavoratore}});
  
      if(res.data.data.length === 0){
  setShowAlert(true);
      }else{
        setShowAlert(false)
      }
    }
  }
  
  const populateIndirizzo = (indirizzo,tipo) => {
    setselezioneCliComm({commessaDestino:"",clienteDestino:"",commessaPartenza:"",clientePartenza:"",ID:0});
   if(indirizzo)
  { 
    let ind =indirizzo.split("-")
    console.log(ind)
  
  if(tipo === "Trasferimento" || tipo  ==="Servizio"){
  
  
    setLocalitaPartenza({Indirizzo:"",Citta:"",Provincia:"",CAP:"",Presso:""});
    setLocalitaDestino({Citta:ind[0],Indirizzo:ind[1],Provincia:ind[2],CAP:ind[3],Presso:ind[4]});
  
  
  
  
  }else{
  
  
    setLocalitaPartenza({Citta:ind[0],Indirizzo:ind[1],Provincia:ind[2],CAP:ind[3],Presso:ind[4]});
    setLocalitaDestino({Indirizzo:"",Citta:"",Provincia:"",CAP:"",Presso:""});
  
  
  
  }
  }
  
  
  }
  
  const handleEditPartenza = (e) => {
  
  let lp = {...localitaPartenza};
  
  lp[e.target.name] = e.target.value;
  
  
    setLocalitaPartenza(lp);
  }
  
  const handleEditDestino = (e) => {
  
    let ld = {...localitaDestino};
    
    ld[e.target.name] = e.target.value;
    
    
      setLocalitaDestino(ld);
    }
  
  const handleSave = async() => {
  
  if(props.operation==="new"){
  
  
  let tratta = {
    IDCommessa:commessaSel,
    IDMacchina:mezzo,
    Partenza:localitaPartenza.Indirizzo?localitaPartenza.Indirizzo:"",
    Destinazione:localitaDestino.Indirizzo?localitaDestino.Indirizzo:"",
    TipologiaDiTrasporto:tipologia,
    Rimorchio:cboxRimorchio?1:0,
    IDAutista:props.idLavoratore,
    Autista:props.Lavoratore,
    Note:note,
    IDTrattaPrecedente:trattaPrecedente,
    DestinazioneCap:localitaDestino.CAP?localitaDestino.CAP:"",
    DestinazioneCitta:localitaDestino.Citta?localitaDestino.Citta:"",
    DestinazioneProvincia:localitaDestino.Provincia?localitaDestino.Provincia:"",
    DestinazionePresso:localitaDestino.Presso?localitaDestino.Presso:"",
    PartenzaPresso:localitaPartenza.Presso?localitaPartenza.Presso:"",
    PartenzaCap:localitaPartenza.CAP?localitaPartenza.CAP:"",
    PartenzaCitta:localitaPartenza.Citta?localitaPartenza.Citta:"",
    PartenzaProvincia:localitaPartenza.Provincia?localitaPartenza.Provincia:"",
    IDMerceMezzo:merceMezzo.IDMerceMezzo,
    MerceMezzo:merceMezzo.MerceMezzo,
    MerceRimorchio:merceMezzoRimorchio.MerceMezzoRimorchio,
    IDMerceRimorchio:merceMezzoRimorchio.IDMerceMezzoRimorchio,
    IDRimorchio:rimorchio,
    DataTratta:props.date,
    Deleted:0,
    Lavorazione:lavorazione?1:0,
    ClienteCommessa:"",
    TipoClienteCommessa:""
  
  }
  
  tratta.ClienteCommessaID = selezioneCliComm.ID?selezioneCliComm.ID:0
  
  //popolo con l'eventuale cliente/commessa
  if(selezioneCliComm.clienteDestino!==""){
    tratta.ClienteCommessa=selezioneCliComm.clienteDestino;
    tratta.TipoClienteCommessa="ClienteDestino";
  }
  
  if(selezioneCliComm.clientePartenza!==""){
    tratta.ClienteCommessa=selezioneCliComm.clientePartenza;
    tratta.TipoClienteCommessa="ClientePartenza";
  }
  
  if(selezioneCliComm.commessaDestino!==""){
    tratta.ClienteCommessa=selezioneCliComm.commessaDestino;
    tratta.TipoClienteCommessa="CommessaDestino";
  }
  
  if(selezioneCliComm.commessaPartenza!==""){
    tratta.ClienteCommessa=selezioneCliComm.commessaPartenza;
    tratta.TipoClienteCommessa="CommessaPartenza";
  }
  
  
  
  try{
    let responseTratta= await api.post('odlTratte',{tratta:tratta},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  
  
  alert("Tratta creata con successo")
  }catch{
    alert("ERRORE DURANTE LA CREAZIONE DELLA TRATTA");
  }
  
  
  
  }else{
  
    let tratta = {
      IDCommessa:commessaSel,
      IDMacchina:mezzo,
      Partenza:localitaPartenza.Indirizzo?localitaPartenza.Indirizzo:"",
      Destinazione:localitaDestino.Indirizzo?localitaDestino.Indirizzo:"",
      TipologiaDiTrasporto:tipologia,
      Rimorchio:cboxRimorchio?1:0,
      IDAutista:props.idLavoratore,
      Autista:props.Lavoratore,
      Note:note,
      IDTrattaPrecedente:trattaPrecedente,
      DestinazioneCap:localitaDestino.CAP?localitaDestino.CAP:"",
      DestinazioneCitta:localitaDestino.Citta?localitaDestino.Citta:"",
      DestinazioneProvincia:localitaDestino.Provincia?localitaDestino.Provincia:"",
      DestinazionePresso:localitaDestino.Presso?localitaDestino.Presso:"",
      PartenzaPresso:localitaPartenza.Presso?localitaPartenza.Presso:"",
      PartenzaCap:localitaPartenza.CAP?localitaPartenza.CAP:"",
      PartenzaCitta:localitaPartenza.Citta?localitaPartenza.Citta:"",
      PartenzaProvincia:localitaPartenza.Provincia?localitaPartenza.Provincia:"",
      IDMerceMezzo:merceMezzo.IDMerceMezzo,
      MerceMezzo:merceMezzo.MerceMezzo,
      MerceRimorchio:merceMezzoRimorchio.MerceMezzoRimorchio,
      IDMerceRimorchio:merceMezzoRimorchio.IDMerceMezzoRimorchio,
      IDRimorchio:rimorchio,
      DataTratta:props.date,
      Deleted:0,
      Lavorazione:lavorazione?1:0,
      ClienteCommessa:"",
      TipoClienteCommessa:""
    
    }
    tratta.ClienteCommessaID = selezioneCliComm.ID?selezioneCliComm.ID:0
  
  //popolo con l'eventuale cliente/commessa
  if(selezioneCliComm.clienteDestino!==""){
    tratta.ClienteCommessa=selezioneCliComm.clienteDestino;
    tratta.TipoClienteCommessa="ClienteDestino";
  }
  
  if(selezioneCliComm.clientePartenza!==""){
    tratta.ClienteCommessa=selezioneCliComm.clientePartenza;
    tratta.TipoClienteCommessa="ClientePartenza";
  }
  
  if(selezioneCliComm.commessaDestino!==""){
    tratta.ClienteCommessa=selezioneCliComm.commessaDestino;
    tratta.TipoClienteCommessa="CommessaDestino";
  }
  
  if(selezioneCliComm.commessaPartenza!==""){
    tratta.ClienteCommessa=selezioneCliComm.commessaPartenza;
    tratta.TipoClienteCommessa="CommessaPartenza";
  }
  
  
  
  
  try{
    let responseTratta= await api.put('odlTratte',{odlTratta:tratta,id:props.idTratta},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  
  
  alert("Tratta modificata con successo")
  }catch{
    alert("ERRORE DURANTE LA CREAZIONE DELLA TRATTA");
  }
  
  
  
  }
  
  
  
  props.loadFunction(props.loadFunctionParams.da,props.loadFunctionParams.a,props.loadFunctionParams.c,props.loadFunctionParams.z,props.loadFunctionParams.s); //ricarico la tabellona
  setShowModal(false);
  }
  
  const handleChangeMerce = async(e) => {
 
    
    let m = {...merceMezzo};
    
    var index = e.target.selectedIndex; //index del testo
    m.IDMerceMezzo = e.target.value;
    m.MerceMezzo=e.target[index].text;
    //
  
    if(tipologia ==="Trasferimento" && e.target.value !== 0){
   
     
      let res = await api.get('odlTratte', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
    },params:{IDMerceMezzo:e.target.value}})


    if(res.data.data.length >0){
            let dataPrecedente = moment(new Date(res.data.data[0].DataTratta)).format("YYYY-MM-DD");
            if(props.date > dataPrecedente){
           
              if(merceMezzoRimorchio.IDMerceMezzoRimorchio!==0){

                
                if(localitaPartenza.Indirizzo === res.data.data[0].Destinazione && localitaPartenza.DestinazionePresso === res.data.data[0].Presso && localitaPartenza.Citta === res.data.data[0].DestinazioneCitta && localitaPartenza.Provincia === res.data.data[0].DestinazioneProvincia && localitaPartenza.CAP === res.data.data[0].DestinazioneCap ){
                  setLocalitaPartenza({Indirizzo:res.data.data[0].Destinazione,Presso:res.data.data[0].Presso,Citta:res.data.data[0].DestinazioneCitta,Provincia:res.data.data[0].DestinazioneProvincia,CAP:res.data.data[0].DestinazioneCap});
                  setTrattaPrecedente(res.data.data[0].ID);
                }else{

                  alert("Attenzione, la macchina che stai provando ad inserire è in una posizione diversa rispetto a quella già selezionata")
                  return
                }


              }else{
                setLocalitaPartenza({Indirizzo:res.data.data[0].Destinazione,Presso:res.data.data[0].DestinazionePresso,Citta:res.data.data[0].DestinazioneCitta,Provincia:res.data.data[0].DestinazioneProvincia,CAP:res.data.data[0].DestinazioneCap});
                setTrattaPrecedente(res.data.data[0].ID);
              }



    
            }else{

              if(window.confirm("Attenzione, esistono date future per questa auto (esempio : "+dataPrecedente+"). Vuoi eliminare le tratte future e proseguire con la creazione?")){

                let responseTratta= await api.put('odlTratte',{data:props.date,operazione:"Massiva",IDMerceMezzo:e.target.value},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
                

                let res = await api.get('odlTratte', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
              },params:{IDMerceMezzo:e.target.value}})
              if(res.data.data.length >0){ //ricarico e prendo l'ultimo se esiste
                setLocalitaPartenza({Indirizzo:res.data.data[0].Destinazione,Citta:res.data.data[0].DestinazioneCitta,Provincia:res.data.data[0].DestinazioneProvincia,CAP:res.data.data[0].DestinazioneCap});
                setTrattaPrecedente(res.data.data[0].ID);
              }



              }else{
                
                m.IDMerceMezzo = 0;
                m.MerceMezzo="";
                setMerceMezzo(m);
                setTrattaPrecedente(0);
                setLocalitaPartenza({Indirizzo:"",Citta:"",Presso:"",Provincia:"",CAP:""});
                return
              }


            }
    }else{


      
      if(merceMezzoRimorchio.IDMerceMezzoRimorchio!==0){

       
        if(localitaPartenza.Indirizzo === "" && localitaPartenza.Presso === "" && localitaPartenza.Citta === "" && localitaPartenza.Provincia === "" && localitaPartenza.CAP === "" ){
          setLocalitaPartenza({Indirizzo:"",Presso:"",Citta:"",Provincia:"",CAP:""});
          setTrattaPrecedente(0);
        }else{

          alert("Attenzione, la macchina che stai provando ad inserire è in una posizione diversa rispetto a quella già selezionata")
          return
        }
      }else{
        setTrattaPrecedente(0);
        setLocalitaPartenza({Indirizzo:"",Citta:"",Presso:"",Provincia:"",CAP:""});
      }
    }

   

    }else{

      setTrattaPrecedente(0);
      setLocalitaPartenza({Indirizzo:"",Citta:"",Presso:"",Provincia:"",CAP:""});

    }
    setMerceMezzo(m);
    
  }
  
  const handleChangeMerceRimorchio = async(e) => {
    
  
    
    let m = {...merceMezzoRimorchio};
    
    var index = e.target.selectedIndex; //index del testo
    m.IDMerceMezzoRimorchio = e.target.value;
    m.MerceMezzoRimorchio=e.target[index].text;
    //
  
    if(tipologia ==="Trasferimento" && e.target.value !== 0){
   
     
      let res = await api.get('odlTratte', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
    },params:{IDMerceMezzo:e.target.value}})

 

    if(res.data.data.length >0){
            let dataPrecedente = moment(new Date(res.data.data[0].DataTratta)).format("YYYY-MM-DD");
            
              

            if(props.date > dataPrecedente){
           
              if(merceMezzo.IDMerceMezzo!==0){

                if(localitaPartenza.Indirizzo === res.data.data[0].Destinazione && localitaPartenza.DestinazionePresso === res.data.data[0].Presso && localitaPartenza.Citta === res.data.data[0].DestinazioneCitta && localitaPartenza.Provincia === res.data.data[0].DestinazioneProvincia && localitaPartenza.CAP === res.data.data[0].DestinazioneCap ){
                  setLocalitaPartenza({Indirizzo:res.data.data[0].Destinazione,Presso:res.data.data[0].Presso,Citta:res.data.data[0].DestinazioneCitta,Provincia:res.data.data[0].DestinazioneProvincia,CAP:res.data.data[0].DestinazioneCap});
                  setTrattaPrecedente(res.data.data[0].ID);
                }else{

                  alert("Attenzione, la macchina che stai provando ad inserire è in una posizione diversa rispetto a quella già selezionata")
                  return
                }


              }else{
                setLocalitaPartenza({Indirizzo:res.data.data[0].Destinazione,Presso:res.data.data[0].Presso,Citta:res.data.data[0].DestinazioneCitta,Provincia:res.data.data[0].DestinazioneProvincia,CAP:res.data.data[0].DestinazioneCap});
                setTrattaPrecedente(res.data.data[0].ID);
              }
    
            }else{

              if(window.confirm("Attenzione, esistono date future per questa auto (esempio : "+dataPrecedente+"). Vuoi eliminare le tratte future e proseguire con la creazione?")){

                let responseTratta= await api.put('odlTratte',{data:props.date,operazione:"Massiva",IDMerceMezzo:e.target.value},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
                

                let res = await api.get('odlTratte', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`
              },params:{IDMerceMezzo:e.target.value}})
              if(res.data.data.length >0){ //ricarico e prendo l'ultimo se esiste
                setLocalitaPartenza({Indirizzo:res.data.data[0].Destinazione,Citta:res.data.data[0].DestinazioneCitta,Provincia:res.data.data[0].DestinazioneProvincia,CAP:res.data.data[0].DestinazioneCap});
                setTrattaPrecedente(res.data.data[0].ID);
              }



              }else{
                
                m.IDMerceMezzoRimorchio = 0;
                m.MerceMezzoRimorchio="";
                setMerceMezzoRimorchio(m);
                setTrattaPrecedente(0);
                setLocalitaPartenza({Indirizzo:"",Citta:"",Presso:"",Provincia:"",CAP:""});
                return
              }


            }
    }else{

      if(merceMezzo.IDMerceMezzo!==0){

    
        if(localitaPartenza.Indirizzo === "" && localitaPartenza.Presso === "" && localitaPartenza.Citta === "" && localitaPartenza.Provincia === "" && localitaPartenza.CAP === "" ){
          setLocalitaPartenza({Indirizzo:"",Presso:"",Citta:"",Provincia:"",CAP:""});
          setTrattaPrecedente(0);
        }else{

          alert("Attenzione, la macchina che stai provando ad inserire è in una posizione diversa rispetto a quella già selezionata")
          return
        }
      }else{
        setTrattaPrecedente(0);
        setLocalitaPartenza({Indirizzo:"",Citta:"",Presso:"",Provincia:"",CAP:""});
      }
      
  
    }

   

    }else{

      setTrattaPrecedente(0);
      setLocalitaPartenza({Indirizzo:"",Citta:"",Presso:"",Provincia:"",CAP:""});

    }
    setMerceMezzoRimorchio(m);
    
  }
  
  
  
  
  
  

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
  
    return (
      <>
         { props.operation==="new"?
      <span style={{float:'right'}}><Add className="text-xl text-emerald-700 hover:text-emerald-600 cursor-pointer" onClick={handleOpen} /></span>:
      <span style={{float:'right'}}><HtmlTooltip title={<div> <b>{"Ditta (Presso) partenza"} </b><br />{props.anteprimaTratta.PartenzaPresso}<br /> <b>{"Ditta (Presso) destino"} </b><br />{props.anteprimaTratta.DestinazionePresso}<br /><b>Merce Motrice</b><br />{props.anteprimaTratta.MerceMezzo}<br /><b>Merce Rimorchio</b><br />{props.anteprimaTratta.MerceRimorchio} <br/><b>Lavorazione : </b> {props.anteprimaTratta.Lavorazione === 0 ? "NO":"SI"}</div>}><Info className="text-xl text-emerald-700 hover:text-emerald-600 cursor-pointer" onClick={handleOpen} /></HtmlTooltip></span>
  }
        {showModal ? (
          <>
  
          
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-full my-6 mx-auto max-w-7xl">
                {/*content*/}
  
                
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-4 border-solid border-slate-200 rounded-t">
                    <h3 className="text-xl font-semibold flex justify-center w-full">{/* Titolo centrato */}
                    {props.title}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
  
                  <div className="flex flex-col w-full p-10" style={{display:insertType===""?"None":""}}>
                  <div class="flex items-center justify-end gap-x-4">
                  <button 
                      className="px-6 py-2 w-32 flex border-emerald-700 border-2 bg-emerald-700 hover:border-emerald-600 hover:bg-emerald-600 font-bold text-white uppercase p-1 rounded-md disabled:opacity-75 disabled:hover:bg-emerald-700 h-10" 
                      onClick={() => setInsertType("")}>
                      Indietro
                    </button>
                      </div>
                   
                    {insertType.includes("Commessa")?(
                    <div className="" style={{height:"400px"}}>
                      
                    <DataGrid
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                             sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
                             columnBuffer={8}
                                 rows={commesse}
                                 getRowId={row => row.id}
                                 columns={columnsCommesseP}
                                 pageSize={10}
                                 rowsPerPageOptions={[10]}
                                 localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                                 onRowDoubleClick={async (params) => {
                                  console.log(params.row)
                                  let obj = {commessaDestino:"",clienteDestino:"",commessaPartenza:"",clientePartenza:"",ID:params.row.id};
  
                                  if(insertType.includes("destino")){
                                    obj.commessaDestino=params.row.NomeCommessa;
                                    setLocalitaDestino({Indirizzo:params.row.Indirizzo,Citta:params.row.Citta,Provincia:params.row.Provincia,CAP:params.row.CAP,Presso:params.row.Presso?params.row.Presso:""});
                                  }else{
                                    obj.commessaPartenza=params.row.NomeCommessa;
                                    setLocalitaPartenza({Indirizzo:params.row.Indirizzo,Citta:params.row.Citta,Provincia:params.row.Provincia,CAP:params.row.CAP,Presso:params.row.Presso?params.row.Presso:""});
                                  }
  
                                 
                                  
                                 
                                  setselezioneCliComm(obj);
                                  setInsertType("")
                               
                                }}
  
                               />
                     </div>):
                      <div className="flex w-full mt-10" style={{height:"400px"}}>
                      <DataGrid
                        components={{
                          Toolbar: CustomToolbar,
                        }}
                               sx={{'.MuiDataGrid-columnSeparator': {display: 'none',}}}
                               columnBuffer={8}
                                   rows={clienti}
                                  getRowId={row => row.ID +  row.RagSociale+row.Provincia+row.Indirizzo+row.Indirizzo+row.CAP}
                                   columns={columnsClienti}
                                   pageSize={10}
                                   rowsPerPageOptions={[10]}
                                   localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                                   onRowDoubleClick={async (params) => {
                                    console.log(params.row.ID);
                                    let obj = {commessaDestino:"",clienteDestino:"",commessaPartenza:"",clientePartenza:"",ID:params.row.ID};
  
                                    if(insertType.includes("destino")){
                                      obj.clienteDestino=params.row.RagSociale;
                                      setLocalitaDestino({Indirizzo:params.row.Indirizzo,Citta:params.row.Citta,Provincia:params.row.Provincia,CAP:params.row.CAP,Presso:params.row.Presso?params.row.Presso:""});
                                    }else{
                                      obj.clientePartenza=params.row.RagSociale;
                                      setLocalitaPartenza({Indirizzo:params.row.Indirizzo,Citta:params.row.Citta,Provincia:params.row.Provincia,CAP:params.row.CAP,Presso:params.row.Presso?params.row.Presso:""});
                                    }
  
                                    setselezioneCliComm(obj);
                                    setInsertType("")
                                 
                                  }}
                                  /* onSelectionModelChange={(ids) => {
                                    const selectedIDs = new Set(ids);
                                    const selectedRowData = dataMacchine.table.filter((row) =>
                                    selectedIDs.has(row.ID),
                                    );
                                    setTableMacchinaSel(selectedRowData);
                                }}*/
    
                                 />
                       </div>
                     
                     
                     
                     }
  
  
                      </div>
  
  
                  <div className="grid overflow-hidden grid-cols-2 grid-rows-1 gap-3 p-2 " style={{display:insertType!==""?"None":""}}>
                      <div className=" col-span-2 rounded-md p-3 shadow-lg border-slate-200/50 border-2 mb-2"> {/* */}
                      <label className="inline-flex items-center justify-center mb-2 w-full">
                        <input type="checkbox" className="form-checkbox h-3 w-3 text-emerald-600 text-center" onChange={handleCheck} checked={allCommesse}/><span className="ml-2 text-gray-700" >Visualizza tutte le Commesse </span>
                      </label>
                      <Tooltip title="Attenzione l'autista non è abilitato all'ingresso in questo cantiere"><Error sx={{marginLeft:"10px",color:"red",display:showAlert?"":"None"}}></Error></Tooltip>
                      <div className="flex justify-center gap-4 ">
                          <label className="flex items-center mb-1 w-full">
                            <span className="text-gray-700 w-2/5">ODL</span>
                            <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md" onChange={handleOdlChange} value={commessaSel}> 
                              <option value="0" >Seleziona...</option>
                            
                              { 
                              commesseCombobox?.map(commessa => <option value={commessa.id} key={commessa.id}>{commessa.NomeCommessa}</option>) 
                              
                              }
                            </select>
                          </label>
                          <label className="flex items-center mb-1 w-full">
                            <span className="text-gray-700 w-2/5">Tipologia</span>
                            <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md" value={tipologia} onChange={ (e) =>{
                              setTipologia(e.target.value);
                              populateIndirizzo(localita,e.target.value);
                            }}>
                              <option value="0" >Seleziona...</option>
                              <option value="Vendita" >Vendita</option>
                              <option value="Servizio" >Servizio</option>
                              <option value="Trasferimento" >Trasferimento</option>
                            </select>
                          </label>
                      </div>
                   <div className="flex justify-center gap-4 w-full">
  
                   <label className="flex items-center mb-1 w-full">
                    <span className="text-gray-700 w-2/5">Motrice</span>
                    <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md"  value={mezzo} onChange={(e)=>{
                      setMezzo(e.target.value)
                     let macc = macchine.filter(m => m.ID === parseInt(e.target.value));
                    if(macchinaSel){
                        
                    if(macc[0].Autotreno?macc[0].Autotreno:0 != macchinaSel.Autotreno?macchinaSel.Autotreno:0){
                        let m = {...merceMezzo};
                        m.IDMerceMezzo =0;
                        m.MerceMezzo="";
                        setMerceMezzo(m);
                    }
                }

                    setMacchinaSel(macc.length>0?macc[0]:null)
                
                    }
                    }> 
                      <option value="0" >Seleziona...</option>
                     {macchine?.map(mac => <option value={mac.ID} key={mac.ID}>{mac.Nome}</option> )}
                       </select>
                    </label>
                   <label className="flex items-center mb-1 w-full">
                    
                    <span className="text-gray-700 w-2/5"><input type="checkbox" className="form-checkbox h-4 w-4 text-emerald-600" checked={cboxRimorchio} onChange={()=>setCboxRimorchio(!cboxRimorchio)}/> Rimorchio</span>
                   <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md" value={rimorchio} disabled={!cboxRimorchio} onChange={(e)=>setRimorchio(e.target.value)}> 
                      {/*{<option value="0" >Seleziona...</option>*/}
                      {macchine?.map(mac => mac.Rimorchiabile===1?<option value={mac.ID} key={mac.ID}>{mac.Nome}</option>:"" )}
                  
                    </select>
                   </label>
                   
                   </div>
                   <div className="flex justify-center gap-4">
                          <label className="flex items-center mb-1 w-full">
                            <span className="text-gray-700 w-2/5">Merce Motrice</span>
                            <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md" value={merceMezzo.IDMerceMezzo} disabled={macchinaSel?.Autotreno ===0 || macchinaSel?.Autotreno === null}
                              onChange={handleChangeMerce}> 
                                <option value="0" >Seleziona...</option>
                                {tipologia!=="Trasferimento"? (cdc?.map(c => <option value={c.ID} key={c.ID}>{c.CDC}</option>)) :(macchine?.map(mac => <option value={mac.ID} key={mac.ID}>{mac.Nome}</option>) )}
                            </select>
                          </label>
                          <label className="flex items-center mb-1 w-full">
                            <span className="text-gray-700 w-2/5">Merce Rimorchio</span>
                            <select className="mt-0 block focus:ring-0 focus:border-emerald-700 w-3/5 p-1 rounded-md" disabled={!cboxRimorchio} value={merceMezzoRimorchio.IDMerceMezzoRimorchio} onChange={handleChangeMerceRimorchio}> 
                              <option value="0" >Seleziona...</option>
                              {tipologia!=="Trasferimento"? (cdc?.map(c => <option value={c.ID} key={c.ID}>{c.CDC}</option>)) :(macchine?.map(mac => <option value={mac.ID} key={mac.ID}>{mac.Nome}</option>) )}
                            </select>
                          </label>
                        </div>
                      {/* <hr className="mt-3" /> */}
                    </div>
                    
                   <div className="flex flex-col shadow-lg rounded-md p-2 border-slate-200/50 border-2">{/* BLOCCO PARTENZA*/}
                    <center><p className="font-bold text-lg">Partenza</p></center>
                    <div className="flex flex-row justify-center gap-4" style={{visibility:tipologia!=="Trasferimento" && tipologia!=="Servizio"?"hidden":""}}>{/*BLocco nascosto per scegliere commessa / cliente*/}
                      <label className="flex flex-row items-end w-full" >
                        <span className="text-gray-700 w-2/5">Commessa</span>           
                        <input
                          type="text"
                          className="inputClickable w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 hover:cursor-pointer"
                          name="Citta"
                          readOnly={true}
                          defaultValue={selezioneCliComm.commessaPartenza}
                          onClick={()=> {if(tipologia!=="Trasferimento" || trattaPrecedente===0){ setInsertType("partenzaCommessa")}}}
                        />
                      </label>
                      <label className="flex flex-row items-end w-full" style={{visibility:tipologia==="Trasferimento"?"hidden":""}}>
                        <span className="text-gray-700 w-2/5">Cliente</span>           
                        <input
                          type="text"
                          className="inputClickable w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 hover:cursor-pointer"
                          name="Provincia"
                          readOnly={true}
                          onClick={()=> setInsertType("partenzaCliente")}
                          defaultValue={selezioneCliComm.clientePartenza}
                        />
                      </label>
                    </div>
                    <div className="flex flex-row justify-center gap-4">
                      <label className="flex flex-row items-end w-full">
                        <span className="text-gray-700 w-1/4">{"Ditta (Presso)"}</span>           
                        <input
                          type="text"
                          className="w-full mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                          name="Presso"
                          value={localitaPartenza.Presso}
                          onChange={handleEditPartenza}
                          readOnly={tipologia==="Trasferimento"} 
                          style={{backgroundColor:checkTrattaPrecedente.Presso?"red":"white",color:checkTrattaPrecedente.Presso?"white":"black"}}
                        />
                      </label>
                      <Tooltip title={checkTrattaPrecedente.PressoText}><Error sx={{marginLeft:"10px",color:"red",display:checkTrattaPrecedente.Presso?"":"None"}}></Error></Tooltip>
                    </div>
                    <div className="flex flex-row justify-center gap-4">
                      <label className="flex flex-row items-end w-full">
                        <span className="text-gray-700 w-1/4">Indirizzo</span>           
                        <input
                          type="text"
                          className="w-full mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                          name="Indirizzo"
                          value={localitaPartenza.Indirizzo}
                          onChange={handleEditPartenza}
                          readOnly={tipologia==="Trasferimento"} 
                          style={{backgroundColor:checkTrattaPrecedente.Indirizzo?"red":"white",color:checkTrattaPrecedente.Indirizzo?"white":"black"}}
                          
                        />
                      </label>
                      <Tooltip title={checkTrattaPrecedente.IndirizzoText}><Error sx={{marginLeft:"10px",color:"red",display:checkTrattaPrecedente.Indirizzo?"":"None"}}></Error></Tooltip>
                    </div>
                    <div className="flex flex-row justify-center gap-4">
                      <label className="flex flex-row items-end w-full">
                        <span className="text-gray-700 w-1/4">Città</span>           
                        <input
                          type="text"
                          className="w-full mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                          name="Citta"
                          value={localitaPartenza.Citta}
                          onChange={handleEditPartenza}
                          readOnly={tipologia==="Trasferimento"} 
                          style={{backgroundColor:checkTrattaPrecedente.Citta?"red":"white",color:checkTrattaPrecedente.Citta?"white":"black"}}
                        />
                      </label>
                      <Tooltip title={checkTrattaPrecedente.IndirizzoText}><Error sx={{marginLeft:"10px",color:"red",display:checkTrattaPrecedente.Indirizzo?"":"None"}}></Error></Tooltip>
                    </div>
  
                    
  
                    <div className="flex flex-row justify-center gap-4">
                      <label className="flex flex-row items-end w-full">
                        <span className="text-gray-700 w-2/5">Provincia</span>           
                        <input
                          type="text"
                          className="w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                          name="Provincia"
                          value={localitaPartenza.Provincia}
                          onChange={handleEditPartenza}
                          readOnly={tipologia==="Trasferimento"} 
                          style={{backgroundColor:checkTrattaPrecedente.Provincia?"red":"white",color:checkTrattaPrecedente.Provincia?"white":"black"}}
                        />
                      </label>
                      <Tooltip title={checkTrattaPrecedente.ProvinciaText}><Error sx={{marginLeft:"10px",color:"red",display:checkTrattaPrecedente.Provincia?"":"None"}}></Error></Tooltip>
                      <label className="flex flex-row items-end w-full">
                        <span className="text-gray-700 w-2/5">CAP</span>           
                        <input
                          type="text"
                          className="w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                          name="CAP"
                          value={localitaPartenza.CAP}
                          onChange={handleEditPartenza}
                          readOnly={tipologia==="Trasferimento"} 
                          style={{backgroundColor:checkTrattaPrecedente.CAP?"red":"white",color:checkTrattaPrecedente.CAP?"white":"black"}}
                        />
                      </label>
                      <Tooltip title={checkTrattaPrecedente.CAPText}><Error sx={{marginLeft:"10px",color:"red",display:checkTrattaPrecedente.CAP?"":"None"}}></Error></Tooltip>
                    </div>
                    </div>
                    <div className="flex flex-col shadow-lg rounded-md p-2 border-slate-200/50 border-2"> {/* BLOCCO Destino*/}
                      <center><p className="font-bold text-lg">Destino</p></center>
                      <div className="flex flex-row justify-center gap-4" style={{visibility:tipologia==="Trasferimento" || tipologia==="Servizio"?"hidden":""}}>{/*BLocco nascosto per scegliere commessa / cliente*/}
                        <label className="flex flex-row items-end w-full" >
                          <span className="text-gray-700 w-2/5">Commessa</span>           
                          <input
                            type="text"
                            className="inputClickable w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 hover:cursor-pointer"
                            name="Citta"
                            readOnly={true}
                            defaultValue={selezioneCliComm.commessaDestino}
                            onClick={()=> setInsertType("destinoCommessa")}
                          />
                        </label>
                    
                        <label className="flex flex-row items-end w-full">
                          <span className="text-gray-700 w-2/5">Cliente</span>           
                          <input
                            type="text"
                            className="inputClickable w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 hover:cursor-pointer"
                            name="Provincia"
                            readOnly={true}
                            onClick={()=> setInsertType("destinoCliente")}
                            defaultValue={selezioneCliComm.clienteDestino}
                          />
                        </label>
                      </div>
                      <label className="flex flex-row items-end w-full">
                        <span className="text-gray-700 w-1/4">{"Ditta (Presso)"}</span>           
                        <input
                          type="text"
                          className="w-full mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                          name="Presso"
                          value={localitaDestino.Presso}
                          onChange={handleEditDestino}
        
                        />
                      </label>
                      <div className="flex flex-row justify-center gap-4">
                        <label className="flex flex-row items-end w-full">
                          <span className="text-gray-700 w-1/4">Indirizzo</span>           
                          <input
                            type="text"
                            className="w-full mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                            name="Indirizzo"
                            value={localitaDestino.Indirizzo}
                            onChange={handleEditDestino}
                          />
                        </label>
                      </div>
                      <div className="flex flex-row justify-center gap-4">
                        <label className="flex flex-row items-end w-full">
                          <span className="text-gray-700 w-1/4">Città</span>           
                          <input
                            type="text"
                            className="w-full mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                            name="Citta"
                            value={localitaDestino.Citta}
                            onChange={handleEditDestino}
                          />
                        </label>
                      </div>
                      <div className="flex flex-row justify-center gap-4">
                     
                    </div>
                        <div className="flex flex-row justify-center gap-4">
                        <label className="flex flex-row items-end w-full">
                          <span className="text-gray-700 w-2/5">Provincia</span>           
                          <input
                            type="text"
                            className="w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                            name="Provincia"
                            value={localitaDestino.Provincia}
                            onChange={handleEditDestino}
                          />
                        </label>
                        <label className="flex flex-row items-end w-full">
                          <span className="text-gray-700 w-2/5">CAP</span>           
                          <input
                            type="text"
                            className="w-3/5 mt-0 p-1 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700"
                            name="CAP"
                            value={localitaDestino.CAP}
                            onChange={handleEditDestino}
                          />
                        </label>
                      </div>
                    </div>
                        <div className="col-span-2 shadow-xl rounded-md mt-2 border-slate-200/50 border-2 p-2">{/*BLOCCO MERCE*/ }
                        {/* <hr /> */}
                        <label className="inline-flex items-center justify-center mb-2 w-full" >
                          <input type="checkbox" className="form-checkbox h-3 w-3 text-emerald-600" checked={lavorazione} onChange={() => setLavorazione(!lavorazione)}/><span className="ml-2 text-gray-700" >Ore Lavorazione</span>
                        </label>
                     
                        <div className="flex justify-center gap-4 flex">
                          <label className="flex flex-row w-full">
                            <span className="text-gray-700 w-1/4">Note</span>
                            <textarea
                              type="text"
                              className="mt-0 p-1 block border-0 w-full border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                              value={note}
                              onChange={(e)=>setNote(e.target.value)}
                              placeholder=""
                            />
                          </label>
                        </div>
  
                      {/* <button className="mt-5 bg-emerald-700 hover:bg-emerald-600 text-white p-1 rounded-md   disabled:opacity-75 disabled:hover:bg-emerald-700" onClick={handleSave}>Salva</button> */}
                      </div>
                    </div>
                    
                  {/*footer*/}
                  <div 
                    className="flex items-center justify-between p-6 border-solid border-slate-200 rounded-b"
                    style={{display:insertType===""?"":"None"}}
                  >
                    <button 
                      className="px-6 py-2 flex border-emerald-700 border-2 bg-emerald-700 hover:border-emerald-600 hover:bg-emerald-600 font-bold text-white uppercase p-1 rounded-md disabled:opacity-75 disabled:hover:bg-emerald-700" 
                      onClick={handleSave}
                      style={{display:insertType===""?"":"None"}}
                    >
                      Salva
                    </button>
                    <button
                      className="text-red-500 hover:text-red-400 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Chiudi
                    </button>
                  </div>
                  
                </div>
              </div>
              
            </div>
            
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }  
  

  export default Tratta;