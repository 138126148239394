import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Personale = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);


  return (
    <>
    {/* Desktop */}
      <div class="lg:flex hidden space-y-4 flex-row flex-wrap h-[100px] w-full">
        <div className="flex space-x-4 items-center w-full flex-nowrap">
          <button
            className="btn-an-dsk"
            onClick={() => navigate("/Permessi")}
          >
            Permessi
          </button>
          <button
            className="btn-an-dsk"
            onClick={() => navigate("/OrdinePanoramica")}
          >
            Ordine Panoramica
          </button>
         </div>
      </div>

      {/* Mobile */}
      <div class="lg:hidden pl-10 flex flex-row flex-wrap h-[100px] w-full">
      <button
            className="btn-an-dsk"
            onClick={() => navigate("/Permessi")}
          >
            Permessi
          </button>
          <button
            className="btn-an-dsk"
            onClick={() => navigate("/OrdinePanoramica")}
          >
            Ordine Panoramica
          </button>
        </div>
    </>
  );
};

export default Personale;
