import React, { useState, useEffect } from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";
import moment from "moment";
import Autocomplete from "../CustomComponents/Autocomplete";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import DatePicker from '@mui/lab/DatePicker';

const Permessi = () => {
  const [ferie, setFerie] = useState([]);
  const [lavoratori, setLavoratori] = useState([]);
  const [selectedFerie, setSelectedFerie] = useState(0);
  const [cookies, setCookie] = useCookies(["user"]);
  const [motivazioni, setMotivazioni] = useState([]);




  const columns = [
    {
      field: "NomeLavoratore",
      headerName: "Lavoratore",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
    {
      field: "DataInizio",
      headerName: "Data Inizio",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
      renderCell: (params) => (
        <div>{moment(params.row.DataInizio).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      field: "DataFine",
      headerName: "Data Fine",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
      renderCell: (params) => (
        <div>{moment(params.row.DataFine).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{color:"#047857"}}/>
        <GridToolbarFilterButton sx={{color:"#047857"}}/>
        <GridToolbarDensitySelector sx={{color:"#047857"}}/>
        <GridToolbarExport sx={{color:"#047857"}}/>
      </GridToolbarContainer  >
    );
  }

  const handleDeleteButtonClick = async () => {
    if (selectedFerie === 0) {
      alert("Seleziona almeno un permesso");
    } else {
      if (
        window.confirm(
          "Sei sicuro di voler eliminare il permesso selezionato?"
        )
      ) {
        try {
          let f = selectedFerie;
          console.log(selectedFerie)
          f.Deleted = 1;
          await api.put(
            "ferieLavoratori",
            { ferie: f },
            { headers: { Authorization: `Bearer ${cookies.user.jwt}` } }
          );

          alert("Permesso eliminato con successo");
          loadFerie(); //refresho
        } catch {
          alert("ERRORE DURANTE L'ELIMINAZIONE");
        }
      }
    }
  };

  const loadFerie = async () => {
    await api
      .get("ferieLavoratori", {
        headers: {
          Authorization: `Bearer ${cookies.user.jwt}`,
        },
      })
      .then((res) => {
        setFerie(res.data.data);
      });
    /* Unità di Misura */
    await api
      .get("motivazioniFerie", {
        headers: {
          Authorization: `Bearer ${cookies.user.jwt}`,
        },
      })
      .then((res) => {
        setMotivazioni(res.data.data);
      });
  };

  const loadLavoratori = async () => {
    await api.get('lavoratori', {params:{operation:"anagrafiche"},headers: {'Authorization': `Bearer ${cookies.user.jwt}`
        }})
        .then( res=> {
          setLavoratori(res.data.data);
        })
    

  };



  useEffect(() => {
    const fetchData = async () => {
      loadFerie();
      loadLavoratori();
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="flex m-0 justify-end items-center w-full space-x-2 mb-4">
        <WatchFerie
          myFunction={loadFerie}
          cookies={cookies}
          motivazioni={motivazioni}
          buttonText="Nuovo"
          id="newButtonLavorazioni"
          title="Nuovo Permesso"
          operation="new"
          classNameButton="new-btn"
          lavoratori={lavoratori}
        />

        <WatchFerie
          myFunction={loadFerie}
          cookies={cookies}
          buttonText="Modifica"
          selectedFerie={selectedFerie}
          allLav={ferie}
          motivazioni={motivazioni}
          id="editLavorazioni"
          title="Modifica lavorazione"
          operation="edit"
          classNameButton="new-btn"
          lavoratori={lavoratori}
        />

        <button className="delete-btn" onClick={handleDeleteButtonClick}>
          Elimina
        </button>
      </div>
      <div className=" h-4/5">
        {/* Table Container */}
        <DataGrid
        components={{
          Toolbar: CustomToolbar,
        }}
          sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
          rows={ferie}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row) => row.ID}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = ferie.filter((row) =>
              selectedIDs.has(row.ID)
            );

            setSelectedFerie(selectedRowData[0]);
          }}
          onRowDoubleClick={async (params) => {
            await setSelectedFerie(params.row);
            document.getElementById("editLavorazioni").click();
          }}
        />
      </div>
    </>
  );
};

export default Permessi;

const WatchFerie = (props) => {
  const [showTra, setShowTra] = useState(false);
  const [lavoratori, setLavoratori] = useState([]);
  const [lavoratoreSel, setLavoratoreSel] = useState(false);
  const [motivazioneSel, setMotivazioneSel] = useState(false);
  const [lavoratoriFiltrati, setLavoratoriFiltrati] = useState([]);
  const [motivazioni, setMotivazioni] = useState([]);
  const [dataInizio,setDataInizio] = useState(false);
  const [dataFine,setDataFine] = useState(false);


 

  const handleSave = async () => {
    //Associo il numero a Unità di Misura e Elimino Unita

    let messaggio = "Attenzione : \n";

    if(!lavoratoreSel){
      messaggio+="Selezionare un lavoratore \n"
    }
    if(!dataInizio){
      messaggio+="Selezionare una data di inizio \n"
    }
    if(!dataFine){
      messaggio+="Selezionare una data di fine \n"
    }
    if(!motivazioneSel){
      messaggio+="Selezionare una motivazione \n"
    }

    if(messaggio!="Attenzione : \n" ){

      alert(messaggio);
      return;
    }



    if (props.operation === "new") {
      var ferie = {Lavoratore:lavoratoreSel,Motivazione:motivazioneSel,DataInizio:dataInizio,DataFine:dataFine};
      let responseFerie= await api.post(
        "ferieLavoratori",
        { ferie: ferie },
        { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
      );
      alert("Permesso inserito con successo");
        props.myFunction();
    }else{

      var ferie = {ID:props.selectedFerie.ID,Lavoratore:lavoratoreSel,Deleted:0,Motivazione:motivazioneSel,DataInizio:dataInizio,DataFine:dataFine};
      let responseFerie= await api.put(
        "ferieLavoratori",
        { ferie: ferie },
        { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
      );
      alert("Permesso inserito con successo");
        props.myFunction();
    }
  
  };

  const openTra = async () => {


    if (props.operation === "edit" && props.selectedFerie ===0){

      alert("Seleziona un permesso");
      return
    }



    setLavoratori(props.lavoratori);
    setLavoratoriFiltrati(props.lavoratori);
    setMotivazioni(props.motivazioni);
    if (props.operation === "new") {
      setDataInizio(null);
      setDataFine(null);
      setLavoratoreSel(null);
      setMotivazioneSel(null);
      

    } else {
      let f = props.selectedFerie;
      setDataInizio(moment(f.DataInizio));
      setDataFine(moment(f.DataFine));
      setLavoratoreSel(f.Lavoratore);
      setMotivazioneSel(f.Motivazione);

    }
    setShowTra(true);
  };

  return (
    <>
      <button
        id={props.id}
        className="new-btn"
        onClick={async () => {
          openTra();
        }}
      >
        {props.buttonText}
      </button>
      {showTra ? (
        <>
          <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowTra(false)}
                  ></button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <div className="grid grid-cols-2 gap-4">
       
                  
                    <Autocomplete suggestions={lavoratori} fields={["Cognome","Nome"]}  keyField="ID" value={lavoratoreSel} label="Lavoratore" id="lavoratore" onChange={setLavoratoreSel}/>
        
                    <Autocomplete suggestions={motivazioni} fields={["Descrizione"]} keyField="ID" value={motivazioneSel} label="Motivo Ferie" id="motivazioni"  onChange={setMotivazioneSel} />
                 
                    <LocalizationProvider dateAdapter={DateAdapter} >
              <DatePicker
                views={['year', 'month', 'day']}
                value={dataInizio}
                onChange={(newValue) => {
                  setDataInizio(moment(newValue).format("YYYY-MM-DD"));
                  setDataFine(null)
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="inline-flex items-center justify-between w-full pr-3 mb-3">
                    <label className="flex items-center w-full">
                      <span className="text-gray-700 w-2/5">Data Inizio</span>
                      <input className="mt-0 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 text-start pb-1 ml-8" style={{}} ref={inputRef} {...inputProps} />
                    </label>
                    {InputProps?.endAdornment}
                  </div>
                )} />
            </LocalizationProvider>


            <LocalizationProvider dateAdapter={DateAdapter} >
              <DatePicker
              minDate={moment(dataInizio)}
                views={['year', 'month', 'day']}
                value={dataFine}
                onChange={(newValue) => {
                  setDataFine(moment(newValue).format("YYYY-MM-DD"));
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="inline-flex items-center justify-between w-full pr-3 mb-3">
                    <label className="flex items-center w-full">
                      <span className="text-gray-700 w-2/5">Data Fine</span>
                      <input className="mt-0 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5 text-start pb-1 ml-8" style={{}} ref={inputRef} {...inputProps} />
                    </label>
                    {InputProps?.endAdornment}
                  </div>
                )} />
            </LocalizationProvider>
                 
                 
                 
                 
                  </div>
                </div>

                {/* FOOTER */}
                <div className="btn-footer-container">
                  <button
                    className="save-btn"
                    type="button"
                    onClick={handleSave}
                  >
                    Salva
                  </button>
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => {
                      setShowTra(false);
                    }}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
