import React,{useEffect,useState} from "react";
import DatePicker from '@mui/lab/DatePicker';
import { FormControl,InputLabel,Select,Chip,Box,OutlinedInput,MenuItem,IconButton } from "@mui/material";
import Holidays from 'date-holidays';
import moment from "moment";
import momentTimezone from "moment-timezone";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "moment/locale/it";
import api from "../../api/api";
import { useCookies } from "react-cookie";
import {Add,Info,Delete,Error,ContentCopy,AccessTime} from '@mui/icons-material';
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';


{/* POPUP PER L'ORDINAMENTO DEI RAPPORTINI */}
const Ordine = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [odlLavoratore,setOdlLavoratore] = useState([]);
    const [cookies, setCookie] = useCookies(["user"]);
  const handleOpen = async() => {
    setShowModal(true)
  
    
    if(props.aut === 4){
    setOdlLavoratore(props.odl);
    }else{
      let responseTratte = await api.get('odlTratte', {headers: {'Authorization': `Bearer ${cookies.user.jwt}`},params:{date:props.date,idAutista:props.IDDip}})
    
        setOdlLavoratore(responseTratte.data.data); //popolo il dato che mi serve
      
  
    }
  
  }
  
  const changeOrdine = (e,index) =>{
  
  let odl = [...odlLavoratore];
  
  odl[index].Ordine = e.target.value;
  
  setOdlLavoratore(odl);
  
  
  
  } 
  
  
  const handleSave = async () => {
  
  try{
  if(props.aut === 4){
    let responseODLDIP= await api.put('odlDip',{odl:odlLavoratore},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  }else{
    let responseODLDIP= await api.put('odlTratte',{odl:odlLavoratore},{ headers: {'Authorization': `Bearer ${cookies.user.jwt}` }});
  }
    alert("ordine aggiornato");
  }catch{
  alert("errore durante il salvataggio")
  
  
  }
  
  props.loadFunction(props.loadFunctionParams.da,props.loadFunctionParams.a,props.loadFunctionParams.c,props.loadFunctionParams.z,props.loadFunctionParams.s); //ricarico la tabellona
  
  }
  
  
  
  
  
    return (
      <> <span style={{float:'right',paddingRight:"5px"}}>
        <AccessTime className="text-xl text-emerald-700 hover:text-emerald-600 cursor-pointer" onClick={handleOpen} /></span>
          
        
      
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-full my-6 mx-6 max-w-2xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none py-4">
                  {/*header*/}
                  <div className="flex items-start justify-between py-5 border-solid border-slate-200 rounded-t">
                    <h5 className="flex w-full justify-center text-xl font-semibold">
                    {props.title}
                    </h5>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="flex flex-col w-full justify-center">
                  {
                    props.aut ===4?
                    odlLavoratore.map((odl,index)=>
                     <div className="flex flex-row justify-center w-full space-y-2"> 
                    
                    <label class="flex items-baseline gap-3">
                  <span class="w-max-96 text-gray-700">Posizione: {odl.NomeCommessa} </span>
                  <input
                    type="text"
                    class="
                      mt-0
                      mb-2
                      px-0.5 py-0.5
                      border-0 border-b-2 border-gray-200
                      focus:ring-0 focus:border-emerald-700
                    "
                    value={odl.Ordine}
                    onChange={(e)=>changeOrdine(e,index)}
                    placeholder=""
                  
                  />
                </label>
                    
                    </div>)
                    :
                   odlLavoratore.map((odl,index)=>
                    <div className="mb-10 flex justify-center w-62"> 
                    
                    <label class="block">
                  <span class="text-gray-700">Posizione {odl.PartenzaCitta + " - " + odl.DestinazioneCitta + " /  Mezzo : " + odl.NomeMacchinaMezzo +" /  Rimorchio : " + (odl.NomeRimorchio?odl.NomeRimorchio:"") + " / Merce Mezzo : " +(odl.MerceMezzo?odl.MerceMezzo:"") + " / Merce Rimorchio : " +(odl.MerceRimorchio?odl.MerceRimorchio:"")}  </span>
                  <input
                    type="text"
                    class="
                      mt-0
                      block
                    w-6
                      px-0.5
                      border-0 border-b-2 border-gray-200
                      focus:ring-0 focus:border-emerald-700
                    "
                    value={odl.Ordine}
                    onChange={(e)=>changeOrdine(e,index)}
                    placeholder=""
                  
                  />
                </label>
                    
                    </div>)
  
                   
  
                  }
                </div>
  
                  {/*footer*/}
                  <div className="flex items-center justify-between pt-5 px-4 border-solid border-slate-200 rounded-b">
                  <button 
                    onClick={handleSave}
                    className="save-btn" 
                    type="button"
                    >
                      Salva
                    </button>
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => {setShowModal(false)}}
                    >
                      Chiudi
                    </button>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }



  export default Ordine;