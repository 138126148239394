import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DataGrid, itIT } from "@mui/x-data-grid";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import { Add, Delete } from "@mui/icons-material";

const Macchinario = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [macchinario, setMacchinario] = useState({
    ID: 0,
    Societa: 0,
    TipoVeicolo: 0,
    Marca: "",
    Modello: "",
    NomeMacchina: "",
    Targa: "",
    Foto: "",
    Note: "",
    Autotreno: 0,
    OreDiLav: "",
    OreDisp: "",
    Contagiri: "",
    OreDiLavUltimoTagliando: "",
    KmUltimoTagliando: "",
    Rimorchiabile: "",
    Autotreno: "",
  });
  const [societa, setSocieta] = useState([]);
  const [tipoMacchine, setTipoMacchine] = useState([]);
  const [checkAutotreno, setCheckAutotreno] = useState(false);
  const [lavorazioni, setLavorazioni] = useState([]);
  const [um, setUm] = useState({ UnitaMisura2: "" });
  const [selectedL, setSelectedL] = useState(0);
  const [UnitaMisura2, setUnitaMisura2] = useState([]);
  const [checkRimorchiabile, setCheckRimorchiabile] = useState(false);
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
    {
      field: "Unita",
      headerName: "Unita Misura",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
  ];

  /*   const loadLav = async (uM) => {
    const tt = uM.map((a) => {
      return a.UnitaMisura;
    });
    setUnitaMisura2(tt);
  }; */

  const loadMacchinario = async () => {
    if (location.state.operation === "edit") {
      /* Lavorazioni */
      await api
        .get("lavorazioni", {
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
          params: { id: location.state.macchinario.ID },
        })
        .then((res) => {
          setLavorazioni(res.data.data);
          
        });
      /* Unità di Misura */
      await api
        .get("unitaMisura", {
          params: { anagrafiche: true },
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
        })
        .then((res) => {
          setUm(res.data.data);
          /*loadLav(um); */
        });

      await api
        .get("societa", {
          params: { anagrafiche: true },
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
        })
        .then((res) => {
          setSocieta(res.data.data);
        });

      await api
        .get("tipoMacchine", {
          params: { anagrafiche: true },
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
        })
        .then((res) => {
          setTipoMacchine(res.data.data);
        });
      await setMacchinario(location.state.macchinario);
      if (location.state.macchinario.Autotreno === 1) {
        //per fleggare di default autotreno
        setCheckAutotreno(true);
      }
      if(location.state.macchinario.Rimorchiabile === 1){ //per fleggare di default autotreno
        setCheckRimorchiabile(true);
      }

    } else {
      await api
        .get("societa", {
          params: { anagrafiche: true },
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
        })
        .then((res) => {
          setSocieta(res.data.data);
        });

      await api
        .get("tipoMacchine", {
          params: { anagrafiche: true },
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
        })
        .then((res) => {
          setTipoMacchine(res.data.data);
        });
    }
    /*     if (
      location.state.macchinario.Autotreno !== 0 ||
      location.state.macchinario.Autotreno !== "0" ||
      location.state.macchinario.Autotreno !== null
    ) {
      setCheckAutotreno(true);
    } else {
      setCheckAutotreno(false);
    } */
  };

  useEffect(() => {
    const fetchData = async () => {
      loadMacchinario();
    };
    fetchData();
  }, []);

  const handleEdit = (e) => {
    let can = { ...macchinario };
    can[e.target.name] = e.target.value;
    setMacchinario(can);
  };
  /*   const handleCheckbox = (e) => {
    let can = { ...macchinario };
    if (e.target.checked) {
      can[e.target.name] = 1;
    } else {
      can[e.target.name] = 0;
    }
    setMacchinario(can);
    setCheckAutotreno(e.target.checked);
  }; */

  const handleSave = async () => {
    if (location.state.operation === "new") {
      try {
        let responseMacchinario = await api.post(
          "anagraficaMacchine",
          { macchina: macchinario },
          {
            headers: {
              Authorization: `Bearer ${location.state.cookies.user.jwt}`,
            },
          }
        );
        alert("Macchinario inserito con successo");

        setShowModal(false);
      } catch {
        alert("Errore durante la creazione del nuovo macchinario");
      }
    } else {
      try {
        let c = { ...macchinario };
        c.Fisso = 1;
        let responseMacchinario = await api.put(
          "anagraficaMacchine",
          { macchina: c },
          {
            headers: {
              Authorization: `Bearer ${location.state.cookies.user.jwt}`,
            },
          }
        );
        alert("Macchinario modificato con successo");
        //props.myFunction();
      } catch {
        alert("Errore durante la modifica del nuovo macchinario");
      }
    }
  };
  const handleImage = (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      document.getElementById("firmaFormatore").src = reader.result;
      let f = { ...macchinario };
      let foto = reader.result.replace("data:image/jpeg;base64,", "");
      foto = foto.replace("data:image/png;base64,", "");
      f.Foto = foto;
      setMacchinario(f);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <>
      {/*content*/}
      <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        {/*header*/}
        <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
          <h3 className="text-3xl font-semibold flex justify-center w-full">
            {location.state.title}
          </h3>
        </div>
        {/*body*/}
        <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
          {/* Images Cotainer */}
          <div className="grid gap-4">
            {/* New Image */}
            <div
              className="flex-col w-full justify-center mt-2"
              style={{
                display: location.state.operation === "new" ? "flex" : "none",
              }}
            >
              <div className="flex w-1/2 h-[200px] mx-auto outline-dotted outline-gray-500 rounded-xl">
                <img
                  src={
                    macchinario.Foto !== "" && macchinario.Foto
                      ? "data:image/png;base64," + macchinario.Foto
                      : require("../../assets/TecnogardenService.png")
                  }
                  id="firmaFormatore"
                  alt="img"
                  className="flex h-full max-h-[200px] mx-auto"
                />
              </div>
              <input
                accept="image/*"
                id="file"
                name="Firma"
                multiple
                type="file"
                onChange={(e) => handleImage(e)}
                hidden
              />
              <button
                id="btn-img"
                className="mx-auto w-1/3 p-2 bg-emerald-600 text-white rounded-md hover:scale-95 duration-150"
                onClick={() => document.getElementById("file").click()}
              >
                Carica Foto
              </button>
            </div>
            {/* Edit Image */}
            <div
              className="flex flex-col w-full mt-2"
              style={{
                display: location.state.operation === "edit" ? "flex" : "none",
              }}
            >
              <img
                height="140"
                src={
                  macchinario.Foto !== "" && macchinario.Foto
                    ? "data:image/png;base64," + macchinario.Foto
                    : require("../../assets/TecnogardenService.png")
                }
                id="Logo"
                alt="img"
                className="flex h-full max-h-[200px] mx-auto"
              />
              <input
                accept="image/*"
                id="file"
                name="Firma"
                multiple
                type="file"
                onChange={(e) => handleImage(e)}
                hidden
              />
              <button
                id="btn-img"
                className="mx-auto w-1/3 p-2 bg-emerald-600 text-white rounded-md hover:scale-95 duration-150"
                onClick={() => document.getElementById("file").click()}
              >
                Carica Foto
              </button>
            </div>
          </div>
          {/* Select Container */}
          <div className="grid grid-cols-2 gap-4">
            {/* Row 1 */}
            <label className="relative">
              <span className="text-gray-700">Società</span>
              <select
                className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md"
                name="Societa"
                value={macchinario.Societa}
                onChange={(e) => {
                  let c = { ...macchinario };
                  c.Societa = e.target.value;
                  setMacchinario(c);
                }}
              >
                <option value="0">Seleziona...</option>
                {societa?.map((s) => (
                  <option key={s.ID} value={s.ID}>
                    {s.RagioneSociale}
                  </option>
                ))}
              </select>
            </label>
            <label className="relative">
              <span className="text-gray-700">Tipo Veicolo</span>
              <select
                className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md"
                value={macchinario.TipoVeicolo}
                onChange={(e) => {
                  let c = { ...macchinario };
                  c.TipoVeicolo = e.target.value;
                  setMacchinario(c);
                }}
              >
                <option value="0">Seleziona...</option>
                {tipoMacchine?.map((cli) => (
                  <option key={cli.ID} value={cli.ID}>
                    {cli.Tipologia}
                  </option>
                ))}
              </select>
            </label>
          </div>
          {/* Input Container */}
          <div className="grid grid-cols-2 gap-4">
            <label className="relative">
              <span className="text-gray-700">Marca</span>
              <input
                type="text"
                className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                placeholder=""
                value={macchinario.Marca}
                name="Marca"
                onChange={handleEdit}
              />
            </label>
            <label className="relative">
              <span className="text-gray-700">Modello</span>
              <input
                type="text"
                className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                placeholder=""
                value={macchinario.Modello}
                name="Modello"
                onChange={handleEdit}
              />
            </label>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <label className="relative ">
              <span className="text-gray-700">Nome Macchina</span>
              <input
                type="text"
                className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                placeholder=""
                value={macchinario.NomeMacchina}
                name="NomeMacchina"
                onChange={handleEdit}
              />
            </label>

            <label className="relative ">
              <span className="text-gray-700">Targa</span>
              <input
                type="text"
                className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                placeholder=""
                value={macchinario.Targa}
                name="Targa"
                onChange={handleEdit}
              />
            </label>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <label className="relative ">
              <span className="text-gray-700">Note</span>
              <input
                type="text"
                className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                placeholder=""
                value={macchinario.Note}
                name="Note"
                onChange={handleEdit}
              />
            </label>
            
            <div className="grid grid-cols-2 gap-4">
            <label
              className=" items-center"
              style={{
                display:"flex"
                 
              }}
            >
              <input
                class="form-check-input appearance-none h-4 w-4 border  form-checkbox text-emerald-600 ring-emerald-600 border-gray-300 rounded-sm bg-white checked:bg-emerald-700 checked:border-emerald-600 hover:bg-emerald-700 focus:outline-none active:bg-emerald-700 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                name="Rimorchiabile"
                /* checked={
                  macchinario.Autotreno !== 0 ||
                  macchinario.Autotreno !== "0" ||
                  macchinario.Autotreno !== null
                } */
                checked={checkRimorchiabile}
                onChange={(e) => {
                  setCheckRimorchiabile(e.target.checked);
                  let a = { ...macchinario };
                  if (e.target.checked) {
                    a[e.target.name] = 1;
                  } else {
                    a[e.target.name] = 0;
                  }
                  setMacchinario(a);
                }}
              />
              <span class="form-check-label inline-block text-gray-800">
                Trainabile
              </span>
            </label>




            <label
              className=" items-center"
              style={{
                display:
                  macchinario?.TipoVeicolo === "8" ||
                  macchinario?.TipoVeicolo === 8
                    ? "flex"
                    : "none",
              }}
            >
              <input
                class="form-check-input appearance-none h-4 w-4 border  form-checkbox text-emerald-600 ring-emerald-600 border-gray-300 rounded-sm bg-white checked:bg-emerald-700 checked:border-emerald-600 hover:bg-emerald-700 focus:outline-none active:bg-emerald-700 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                name="Autotreno"
                /* checked={
                  macchinario.Autotreno !== 0 ||
                  macchinario.Autotreno !== "0" ||
                  macchinario.Autotreno !== null
                } */
                checked={checkAutotreno}
                onChange={(e) => {
                  setCheckAutotreno(e.target.checked);
                  let a = { ...macchinario };
                  if (e.target.checked) {
                    a[e.target.name] = 1;
                  } else {
                    a[e.target.name] = 0;
                  }
                  setMacchinario(a);
                }}
              />
              <span class="form-check-label inline-block text-gray-800">
                Autotreno
              </span>
            </label>

            </div>
          </div>
        </div>
        <div className="flex-row flex w-full flex-wrap mt-2">
          <h3 className="text-2xl font-bold text-center w-full pt-2">
            Lavorazioni
          </h3>
          <div className="flex w-full m-2 mt-0 justify-start items-center">
            <ModalLavorazione
              lavorazione={selectedL}
              id="editLavoratore"
              title="Visualizza Lavorazione"
              operation="edit"
              classNameButton="new-btn"
              view={false}
            />
            <ModalLavorazione
              lavorazione={selectedL}
              id="newLavoratore"
              title="Aggiungi Lavorazione"
              operation="new"
              classNameButton="new-btn"
              view={true}
            />
          </div>

          <div
            className="h-72 w-full"
            style={{
              display: location.state.operation === "edit" ? "flex" : "none",
            }}
          >
            <DataGrid
              sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
              rows={lavorazioni}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              getRowId={(row) => row.ID}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = lavorazioni.filter((row) =>
                  selectedIDs.has(row.ID)
                );

                setSelectedL(selectedRowData[0]);
              }}
              onRowClick={async (params) => {
                await setSelectedL(params.row);
              }}
            />
          </div>
        </div>
        {/*footer*/}
        <div className="btn-footer-container">
          <button className="save-btn" type="button" onClick={handleSave}>
            Salva
          </button>
          <button
            className="close-btn"
            type="button"
            onClick={() => navigate("/Macchinari")}
          >
            Chiudi
          </button>
        </div>
      </div>
    </>
  );
};

export default Macchinario;

/* Pop Up Lavorazione */
const ModalLavorazione = (props) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [lavorazione, setLavorazione] = useState({
    Descrizione: "",
    UnitaMisura: 0,
  });
  const [lavorazioni, setLavorazioni] = useState([]);
  const [lavorazioneNon, setLavorazioneNon] = useState({
    Descrizione: "",
  });
  const [um, setUM] = useState({
    UnitaMisura: "",
  });

  const handleEdit = (e) => {
    let tra = { ...lavorazione };
    tra[e.target.name] = e.target.value;
    setLavorazione(tra);
  };
  const handleDeleteButtonClick = async () => {
    if (!props.lavorazione.ID) {
      alert("Seleziona almeno una Lavorazione");
    } else {
      if (
        window.confirm(
          "Sei sicuro di voler eliminare la Lavorazione selezionata?"
        )
      ) {
        try {
          let maccAssLav = {
            IDMacchina: location.state.macchinario.ID,
            IDLavorazione: props.lavorazione.ID,
            IDUM: props.lavorazione.UnitaMisura,
            Deleted: 1,
            ID: props.lavorazione.IDLavorazione,
          };

          console.log(maccAssLav);
          await api.put(
            "lavorazioni",
            { lavorazione: maccAssLav },
            {
              headers: {
                Authorization: `Bearer ${location.state.cookies.user.jwt}`,
              },
            }
          );

          alert("Lavorazione eliminata con successo");
          window.location.reload(); //refresho
        } catch {
          alert("ERRORE DURANTE L'ELIMINAZIONE");
        }
      }
    }
  };
  const handleSave = async () => {
    let maccAssLav = {
      IDMacchina: location.state.macchinario.ID,
      IDLavorazione: lavorazione.ID,
      IDUM: lavorazione.UnitaMisura,
    };
    try {
      console.log(maccAssLav);
      let responseMacchinario = await api.post(
        "lavorazioni",
        { lavorazione: maccAssLav },
        {
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
          params: {
            id: location.state.macchinario.ID,
            operation: "anagrafica",
          },
        }
      );
      alert("Lavorazione inserita con successo");
      setShowModal(false);
      window.location.reload();
    } catch {
      alert("Errore durante la creazione della lavorazione");
    }
  };
  const openModal = async () => {
    if (props.operation === "new") {
      await api
        .get("lavorazioni", {
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
          params: {
            id: location.state.macchinario.ID,
            operation: "anagrafica",
          },
        })
        .then((res) => {
          setLavorazioneNon(res.data.data);
        });
      /* Unità di Misura */
      await api
        .get("unitaMisura", {
          params: { anagrafiche: true },
          headers: {
            Authorization: `Bearer ${location.state.cookies.user.jwt}`,
          },
        })
        .then((res) => {
          setUM(res.data.data);
        });
      setLavorazione({
        Descrizione: "",
        UnitaMisura: 0,
      });

      setLavorazioni(props.lavorazione);
    } else {
      setLavorazione(props.lavorazione);
    }
    setShowModal(true);
  };

  return (
    <>
      <div
        className="w-10 h-10 rounded-full items-center justify-center p-2 bg-emerald-600 hover:scale-95 duration-150"
        style={{ display: props.view ? "flex" : "none" }}
      >
        <button
          id={props.id}
          className="text-center flex justify-center items-center  text-white "
          onClick={async () => {
            openModal();
          }}
        >
          <Add />
        </button>
      </div>
      <div
        className="w-10 h-10 rounded-full items-center justify-center ml-2 p-2 bg-red-600 hover:scale-95 duration-150"
        style={{ display: props.view ? "flex" : "none" }}
      >
        <button
          className="text-center flex justify-center items-center text-white "
          onClick={async () => {
            handleDeleteButtonClick();
          }}
        >
          <Delete />
        </button>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <div className="grid grid-cols-2 gap-4">
                    {/* Descrizione */}
                    <label className="relative ">
                      <span className="text-gray-700">Descrizione</span>
                      {props.operation === "new" ? (
                        <select
                          className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md"
                          value={lavorazioneNon.ID}
                          name="Descrizione"
                          onChange={(e) => {
                            let c = { ...lavorazione };
                            c.ID = e.target.value;
                            setLavorazione(c);
                          }}
                        >
                          <option value="0">Seleziona...</option>
                          {lavorazioneNon?.map((cli) => (
                            <option key={cli.ID} value={cli.ID}>
                              {cli.Descrizione}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          value={lavorazione.Descrizione}
                          name="Descrizione"
                          onChange={handleEdit}
                        />
                      )}
                    </label>
                    {/* Unità di Misura */}
                    <label className="relative ">
                      <span className="text-gray-700">Unita di Misura</span>
                      {props.operation === "new" ? (
                        <select
                          className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md"
                          value={um.ID}
                          name="UnitaMisura"
                          onChange={(e) => {
                            let c = { ...lavorazione };
                            c.UnitaMisura = e.target.value;
                            setLavorazione(c);
                          }}
                        >
                          <option value="0">Seleziona...</option>
                          {um?.map((cli) => (
                            <option key={cli.ID} value={cli.ID}>
                              {cli.UnitaMisura}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={lavorazione.Unita}
                          name="UnitaMisura"
                          onChange={handleEdit}
                        />
                      )}
                    </label>
                  </div>
                  {/* FOOTER */}
                  <div className="btn-footer-container">
                    <button
                      className="save-btn"
                      type="button"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      Salva
                    </button>
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
