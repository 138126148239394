import React, { useState, useEffect } from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";

const Lavorazioni = () => {
  const [lavorazioni, setLavorazioni] = useState([]);
  const [selectedLav, setSelectedLav] = useState(0);
  const [cookies, setCookie] = useCookies(["user"]);
  const [um, setUM] = useState([]);
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
    {
      field: "Unita",
      headerName: "Unita Misura",
      headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{color:"#047857"}}/>
        <GridToolbarFilterButton sx={{color:"#047857"}}/>
        <GridToolbarDensitySelector sx={{color:"#047857"}}/>
        <GridToolbarExport sx={{color:"#047857"}}/>
      </GridToolbarContainer  >
    );
  }



  const handleDeleteButtonClick = async () => {
    if (selectedLav === 0) {
      alert("Seleziona almeno una Lavorazione");
    } else {
      if (
        window.confirm(
          "Sei sicuro di voler eliminare la Lavorazione selezionato?"
        )
      ) {
        try {
          let tra = selectedLav;
          tra.Deleted = 1;
          await api.put(
            "lavorazioniAll",
            { lavorazioni: tra },
            { headers: { Authorization: `Bearer ${cookies.user.jwt}` } }
          );

          alert("Lavorazione eliminato con successo");
          window.location.reload(); //refresho
        } catch {
          alert("ERRORE DURANTE L'ELIMINAZIONE");
        }
      }
    }
  };
  const loadLavorazioni = async () => {
    await api
      .get("lavorazioniAll", {
        headers: {
          Authorization: `Bearer ${cookies.user.jwt}`,
        },
      })
      .then((res) => {
        setLavorazioni(res.data.data);
      });
    /* Unità di Misura */
    await api
      .get("unitaMisura", {
        params: { anagrafiche: true },
        headers: {
          Authorization: `Bearer ${cookies.user.jwt}`,
        },
      })
      .then((res) => {
        setUM(res.data.data);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      loadLavorazioni();
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="flex m-0 justify-end items-center w-full space-x-2 mb-4">
        <WatchLavorazioni
          myFunction={loadLavorazioni}
          cookies={cookies}
          allUm={um}
          buttonText="Nuovo"
          id="newButtonLavorazioni"
          title="Nuovo lavorazione"
          operation="new"
          classNameButton="new-btn"
        />

        <WatchLavorazioni
          myFunction={loadLavorazioni}
          cookies={cookies}
          buttonText="Modifica"
          selectedLav={selectedLav}
          allLav={lavorazioni}
          allUm={um}
          id="editLavorazioni"
          title="Modifica lavorazione"
          operation="edit"
          classNameButton="new-btn"
        />

        <button className="delete-btn" onClick={handleDeleteButtonClick}>
          Elimina
        </button>
      </div>
      <div className=" h-4/5">
        {/* Table Container */}
        <DataGrid
        components={{
          Toolbar: CustomToolbar,
        }}
          sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
          rows={lavorazioni}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row) => row.ID}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = lavorazioni.filter((row) =>
              selectedIDs.has(row.ID)
            );

            setSelectedLav(selectedRowData[0]);
          }}
          onRowDoubleClick={async (params) => {
            await setSelectedLav(params.row);
            document.getElementById("editLavorazioni").click();
          }}
        />
      </div>
    </>
  );
};

export default Lavorazioni;

const WatchLavorazioni = (props) => {
  const [showTra, setShowTra] = useState(false);
  const [lavorazioni, setLavorazioni] = useState([]);
  const [um, setUm] = useState([]);
  const [selectedLav, setSelectedLav] = useState({
    ID: 0,
    Descrizione: "",
    UnitaMisura: 0,
    Unita: "",
  });

  const handleEdit = (e) => {
    let tra = { ...selectedLav };
    tra[e.target.name] = e.target.value;
    setSelectedLav(tra);
  };

  const handleSave = async () => {
    //Associo il numero a Unità di Misura e Elimino Unita
    if (props.operation === "new") {
      let newLav = {...selectedLav}
      um.map((a) => {
        if (a.UnitaMisura === selectedLav.Unita) {
          newLav.UnitaMisura = a.ID;
        }})
      delete newLav.Unita
      try {
        let responseLavorazioni = await api.post(
          "lavorazioniAll",
          { lavorazioni: newLav },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Lavorazione inserita con successo");
        props.myFunction();
        setShowTra(false);
      } catch {
        alert("Errore durante la creazione della nuova lavorazione");
      }
    } else {
      //Associo il numero a Unità di Misura e Elimino Unita
      let editLav = {...selectedLav}
      um.map((a) => {
        if (a.UnitaMisura === selectedLav.Unita) {
          editLav.UnitaMisura = a.ID;
        }})
      delete editLav.Unita
      try {
        let responseLavorazioni = await api.put(
          "lavorazioniAll",
          { lavorazioni: editLav },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Lavorazioni modificato con successo");
        props.myFunction();
        setShowTra(false);
      } catch {
        alert("Errore durante la modifica del nuovo lavorazioni");
      }
    }
  };

  const openTra = async () => {
    if (props.operation === "new") {
      setSelectedLav({
        Descrizione: "",
        UnitaMisura: 0,
        Unita: "",
      });
      setUm(props.allUm);
    } else {
      setLavorazioni(props.allLav);
      setSelectedLav(props.selectedLav);
      setUm(props.allUm);
    }
    setShowTra(true);
  };

  return (
    <>
      <button
        id={props.id}
        className="new-btn"
        onClick={async () => {
          openTra();
        }}
      >
        {props.buttonText}
      </button>
      {showTra ? (
        <>
          <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowTra(false)}
                  ></button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                  <div className="grid grid-cols-2 gap-4">
                    {/* Descrizione */}
                    <label className="relative">
                      <span className="text-gray-700">Descrizione</span>
                      {props.operation === "edit" ? (
                        <select
                          className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md"
                          name="Descrizione"
                          value={selectedLav.Descrizione}
                          onChange={(e) => {
                            let c = { ...selectedLav };
                            c.Descrizione = e.target.value;
                            setSelectedLav(c);
                          }}
                        >
                          <option value="0">Seleziona...</option>
                          {lavorazioni?.map((cli) => (
                            <option key={cli.ID} value={cli.Descrizione}>
                              {cli.Descrizione}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={selectedLav.Descrizione}
                          name="Descrizione"
                          onChange={handleEdit}
                        />
                      )}
                    </label>
                    {/* Unità di Misura */}
                    <label className="relative ">
                      <span className="text-gray-700">Unita di Misura</span>

                      <select
                        className="mt-0 block focus:ring-0 focus:border-emerald-700 w-full p-1 rounded-md"
                        value={selectedLav.Unita}
                        name="Unita"
                        onChange={(e) => {
                          let c = { ...selectedLav };
                          c.Unita = e.target.value;
                          setSelectedLav(c);
                        }}
                      >
                        <option value="0">Seleziona...</option>
                        {um?.map((cli) => (
                          <option key={cli.ID} value={cli.UnitaMisura}>
                            {cli.UnitaMisura}
                          </option>
                        ))}
                      </select>

                      {/* <input
                        type="text"
                        className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                        placeholder=""
                        value={selectedLav.Unita}
                        name="Unita"
                        onChange={handleEdit}
                      /> */}
                    </label>
                  </div>
                </div>

                {/* FOOTER */}
                <div className="btn-footer-container">
                  <button
                    className="save-btn"
                    type="button"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Salva
                  </button>
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => {
                      setShowTra(false);
                    }}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
