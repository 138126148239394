import React, { useState, useEffect } from "react";
import { DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector, } from '@mui/x-data-grid';
import api from "../../api/api";
import { useCookies } from "react-cookie";

const Cdc = () => {
  const [selectedCdc, setSelectedCdc] = useState(0);
  const [cookies, setCookie] = useCookies(["user"]);
  const [cdc, setCdc] = useState([]);
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
        field: "CDC",
        headerName: "CDC",
        headerClassName:
        "bg-emerald-700 text-white font-medium uppercase tracking-wider",
        flex: 1
    }
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{color:"#047857"}}/>
        <GridToolbarFilterButton sx={{color:"#047857"}}/>
        <GridToolbarDensitySelector sx={{color:"#047857"}}/>
        <GridToolbarExport sx={{color:"#047857"}}/>
      </GridToolbarContainer  >
    );
  }

  const handleDeleteButtonClick = async () => {
    if (selectedCdc === 0) {
      alert("Seleziona almeno un CDC");
    } else {
      if (
        window.confirm(
          "Sei sicuro di voler eliminare CDC selezionato?"
        )
      ) {
        try {
          let tra = selectedCdc;
          tra.Deleted = 1;
          await api.put(
            "cdc",
            { cdc: tra },
            { headers: { Authorization: `Bearer ${cookies.user.jwt}` } }
          );
          alert("CDC eliminato con successo");
          window.location.reload(); //refresho
        } catch {
          alert("ERRORE DURANTE L'ELIMINAZIONE");
        }
      }
    }
  };
  const loadCdc = async () => {
    
    /* Unità di Misura */
    await api
    .get("cdc", {
      headers: {
        Authorization: `Bearer ${cookies.user.jwt}`,
      },
    })
    .then((res) => {
      setCdc(res.data.data);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      loadCdc();
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="flex m-0 justify-end items-center w-full space-x-2 mb-4">
        <WatchCdc
          myFunction={loadCdc}
          cookies={cookies}
          buttonText="Nuovo"
          id="newButtonCdc"
          title="Nuovo CDC"
          operation="new"
          classNameButton="new-btn"
        />

        <WatchCdc
          myFunction={loadCdc}
          cookies={cookies}
          buttonText="Modifica"
          selectedCdc={selectedCdc}
          id="editCdc"
          title="Modifica CDC"
          operation="edit"
          classNameButton="new-btn"
        />

        <button className="delete-btn" onClick={handleDeleteButtonClick}>
          Elimina
        </button>
        </div>
        <div className=" h-4/5">
            {/* Table Container */}
            <DataGrid
            components={{
              Toolbar: CustomToolbar,
            }}
                sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
                rows={cdc}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                getRowId={(row) => row.ID}
                onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = cdc.filter((row) =>
                    selectedIDs.has(row.ID)
                );

                setSelectedCdc(selectedRowData[0]);
                }}
                onRowDoubleClick={async (params) => {
                await setSelectedCdc(params.row);
                document.getElementById("editCdc").click();
                }}
            />
        </div>
    </>
  );
};

export default Cdc;

const WatchCdc = (props) => {
  const [showTra, setShowTra] = useState(false);
  const [cdc, setCdc] = useState({
    CDC: "",
  });

  const handleEdit = (e) => {
    let tra = { ...cdc };
    tra[e.target.name] = e.target.value;
    setCdc(tra);
  };

  const handleSave = async () => {
    if (props.operation === "new") {
      try {
        let responseCdc = await api.post(
          "cdc",
          { cdc: cdc },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Unita di Misura inserita con successo");
        props.myFunction();
        setShowTra(false);
      } catch {
        alert("Errore durante la creazione della nuovo CDC");
      }
    } else {
      try {
        let responseCdc = await api.put(
        "cdc",
        { cdc: cdc },
          { headers: { Authorization: `Bearer ${props.cookies.user.jwt}` } }
        );
        alert("Unita di Misura modificata con successo");
        props.myFunction();
        setShowTra(false);
      } catch {
        alert("Errore durante la modifica della nuovo CDC");
      }
    }
  };

  const openTra = async () => {
    if (props.operation === "new") {
      setCdc({
        CDC: "",
      });
    } else {
      setCdc(props.selectedCdc);
    }
    setShowTra(true);
  };

  return (
    <>
      <button
        id={props.id}
        className="new-btn"
        onClick={async () => {
          openTra();
        }}
      >
        {props.buttonText}
      </button>
      {showTra ? (
        <>
          <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 p-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold flex justify-center w-full">
                    {props.title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowTra(false)}
                  >
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto shadow-lg rounded-md border-slate-200/50 border-2">
                <div className="grid gap-4">
                    {/* Unità di Misura */}
                    <label className="flex items-baseline mx-auto">
                      <span className="text-gray-700 w-full">Unita di Misura</span>
                        <input
                          type="text"
                          className="mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-full"
                          placeholder=""
                          value={cdc.CDC}
                          name="CDC"
                          onChange={handleEdit}
                        />
                      {/* )} */}
                    </label>
                  </div>
                </div>

                {/* FOOTER */}
                <div className="btn-footer-container">
                  <button
                    className="save-btn"
                    type="button"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Salva
                  </button>
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => {
                      setShowTra(false);
                    }}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
